import { Colors, FontSizes, LpNavbar, SHOW_BANNER, TrufoLink } from "@/components"
import { Box } from "@mui/material"
import { useSectionProps, getPageTopOffsetString } from "../types-and-constants"
import { useEffect } from "react"
import { useGetUser } from "@/hooks"
import { LpFooter } from "."
import { BLOG_C2PA_LINK } from "@/app-routes"

export type LpWrapperProps = {
    hideNavbar?: boolean,
    hideBanner?: boolean,
    hideFooter?: boolean,
    variant?: 'light' | 'dark'
}

export const LpWrapper = ({ hideNavbar, hideBanner, hideFooter, variant = 'dark', children }: React.PropsWithChildren<LpWrapperProps>) => {
    const sectionProps = useSectionProps();
    const { ready: pageSizeReady, compact, marginLeftRight, pageWidthPx } = sectionProps;

    const { getUser: getTrufoUser, data: trufoUserData, error: getTrufoUserError, loading: getTrufoUserLoading } = useGetUser();
    useEffect(() => { getTrufoUser() }, [getTrufoUser])
    const loggedIn = trufoUserData && !getTrufoUserError;

    const showBanner = SHOW_BANNER && !hideBanner;
    const showNavbar = !hideNavbar;

    return (
        <Box
            width='100%'
            height='100vh'
            display='flex' flexDirection='column'
            sx={{ backgroundColor: variant === 'dark' ? Colors.dark : Colors.offWhite }}
            position='relative'
        >
            {pageSizeReady && showNavbar ? (
                <LpNavbar
                    loggedIn={loggedIn} loggedInLoading={getTrufoUserLoading}
                    compact={compact} marginLeftRight={marginLeftRight}
                    banner={showBanner ?
                        (<Box
                            fontSize={pageWidthPx > 1100 ? FontSizes.body1 : pageWidthPx > 900 ? FontSizes.body2 : pageWidthPx > 440 ? FontSizes.body3 : FontSizes.body4}
                            lineHeight={1}
                        >
                            Looking to implement C2PA? Trufo provides a free one-click integration that takes care of everything from certificate issuance to watermarking.
                            {'  '}<TrufoLink href={BLOG_C2PA_LINK} newtab trufoVariant="white">Learn More</TrufoLink>
                        </Box>) : null
                    }
                />
            ) : null}
            <Box
                display='flex' flexDirection='column'
                marginTop={getPageTopOffsetString(showNavbar, showBanner)}
                width='100%'
            >
                {children}
            </Box>
            {!hideFooter ? <LpFooter {...sectionProps} /> : null}
        </Box>
    )
}
import './styles.css';
import { Box, BoxProps } from '@mui/material';
import { motion, useTransform, easeInOut, MotionValue } from 'framer-motion';
import image_square_2 from '@assets/image_square_2.jpg';
import { useMemo } from 'react';
import { AnimStage, SCROLL_POSITIONS, getPointOnLine, getResponsiveComponentPositions } from '../types-and-constants';
import DetailsContent from './details-content';
import { Colors, ZIndexes } from '@/components';

type WatermarkAnimProps = {
    scrollYProgress: MotionValue<number>,
    initialAnimationComplete: boolean,
    setInitialAnimationComplete: () => void,
    animSizePx: number,
    noAnim?: boolean,
    imageUrl?: string,
} & BoxProps;

export default function WatermarkAnim({ scrollYProgress, initialAnimationComplete, setInitialAnimationComplete, animSizePx, noAnim, imageUrl, ...muiBoxProps }: WatermarkAnimProps) {

    const c = getResponsiveComponentPositions(animSizePx);
    const s = SCROLL_POSITIONS;

    const borderRadiusPx = animSizePx / 8;

    const APPEAR_AND_ROTATE_DURATION = 0.8;
    const SEPARATION_DURATION_LONG = 1;
    const SEPARATION_DURATION_SHORT = 1;

    // INITIAL ANIMATIONS ---------------------------------------------------------------------

    const INITIAL_ANIM_SHOW_DETAILS = false;
    const ROTATE_X = 51;
    const ROTATE_Y = 0;
    const ROTATE_Z = 43;

    const initialAnimations = useMemo(() => ({
        parent: {
            animate: { rotateX: [0, 0, ROTATE_X], rotateY: [0, 0, ROTATE_Y], rotateZ: [0, 0, ROTATE_Z], translateZ: 0, opacity: [0, 1, 1] },
            transition: {
                duration: APPEAR_AND_ROTATE_DURATION,
                times: [0, 0.2, 1], // times range from 0 to 1 (then scaled to duration)
                delay: 0,
                ease: easeInOut,
            },
            initial: { opacity: 0 },
            noAnimInitial: { rotateX: ROTATE_X, rotateY: ROTATE_Y, rotateZ: ROTATE_Z, translateZ: 0 }
        },
        top: {
            animate: { opacity: 0.5, translateX: c.top.t.initial, translateY: c.top.t.initial },
            transition: {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            },
            initial: { opacity: 0 },
        },
        topOverlayDetails: {
            animate: INITIAL_ANIM_SHOW_DETAILS ? { opacity: [0, 0, 1, 0], translateX: c.top.t.initial, translateY: c.top.t.initial } : {},
            transition: INITIAL_ANIM_SHOW_DETAILS ? {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                times: [0.2, 0.5, 0.7, 1],
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            } : {},
            initial: { opacity: 0, zIndex: ZIndexes.landingPageAnim },
        },
        topOverlayTexture: {
            animate: INITIAL_ANIM_SHOW_DETAILS ? { opacity: [0, 1, 0.2, 0], translateX: c.top.t.initial, translateY: c.top.t.initial } : {},
            transition: INITIAL_ANIM_SHOW_DETAILS ? {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                times: [0.2, 0.5, 0.7, 1],
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            } : {},
            initial: { opacity: 0, zIndex: ZIndexes.landingPageAnim },
        },
        bottom: {
            animate: { translateX: c.bottom.t.initial, translateY: c.bottom.t.initial },
            transition: {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            },
            initial: {},
        },
        bottomOverlay: {
            initial: { opacity: 0 },
        }
    }), []);


    // ANIMATIONS ON SCROLL ---------------------------------------------------------------------

    // watermark & image parent div
    const styleParent = {
        rotateX: c.parent.rotate.X,
        rotateY: 0,
        rotateZ: c.parent.rotate.Z,
        translateZ: 0,
        translateX: 0,
    }

    // top layer (watermark)
    const styleTop = {
        translateX: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGING], s[AnimStage.WMMERGED]],
            [c.top.t.initial, c.top.t.hover, c.top.t.hover, c.top.t.merged],
            { ease: easeInOut }
        ),
        translateY: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGING], s[AnimStage.WMMERGED]],
            [c.top.t.initial, c.top.t.hover, c.top.t.hover, c.top.t.merged],
            { ease: easeInOut }
        ),
        translateZ: 0,
        opacity: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGING], s[AnimStage.WMMERGED]],
            [0.5, 0.8, 0.8, 0],
            { ease: easeInOut }
        ),
        ...(noAnim ? { opacity: 0.7, translateX: c.top.t.noAnim, translateY: c.top.t.noAnim, backgroundColor: Colors.offWhite } : {}),
        borderRadius: `${borderRadiusPx}px`,
    }
    const styleTopDetails = {
        ...styleTop,
        backgroundColor: 'none',
        opacity: useTransform(scrollYProgress,
            [
                s[AnimStage.INITIAL],
                getPointOnLine(s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], 1 / 2),
                s[AnimStage.WMDETAILS],
                s[AnimStage.WMMERGING],
                getPointOnLine(s[AnimStage.WMMERGING], s[AnimStage.WMMERGED], 1 / 2),
                s[AnimStage.WMMERGED]
            ],
            [0, 0.2, 1, 1, 0.2, 0],
            { ease: easeInOut }
        ),
        ...(noAnim ? { opacity: 1, zIndex: ZIndexes.landingPageAnim } : {})
    }
    const styleTopTexture = {
        ...styleTop,
        backgroundColor: 'none',
        opacity: useTransform(scrollYProgress,
            [
                s[AnimStage.INITIAL],
                getPointOnLine(s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], 1 / 2),
                s[AnimStage.WMDETAILS],
                s[AnimStage.WMMERGING],
                getPointOnLine(s[AnimStage.WMMERGING], s[AnimStage.WMMERGED], 1 / 2),
                s[AnimStage.WMMERGED]
            ],
            [0, 1, 0, 0, 1, 0],
            { ease: easeInOut }
        ),
    }

    // bottom layer (image)
    const styleBottom = {
        translateX: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGED]],
            [c.bottom.t.initial, c.bottom.t.initial, c.bottom.t.merged],
            { ease: easeInOut }
        ),
        translateY: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGED]],
            [c.bottom.t.initial, c.bottom.t.initial, c.bottom.t.merged],
            { ease: easeInOut }
        ),
        translateZ: 0,
    }

    // RETURN ---------------------------------------------------------------------

    return (
        <Box
            zIndex={ZIndexes.landingPageClickable}
            {...muiBoxProps}
        >

            {/* WATERMARK & IMAGE -------------- */}
            <motion.div
                style={{
                    ...(initialAnimationComplete ? styleParent : {}),
                    width: `${animSizePx}px`,
                    height: `${animSizePx}px`,
                }}
                animate={noAnim ? {} : initialAnimations.parent.animate}
                transition={noAnim ? {} : initialAnimations.parent.transition}
                initial={noAnim ? initialAnimations.parent.noAnimInitial : initialAnimations.parent.initial}
            >

                {/* TOP LAYER -------------- */}
                <motion.div
                    className='top-layer top-layer-overlay top-layer-details'
                    style={initialAnimationComplete ? styleTopDetails : {}}
                    animate={noAnim ? {} : initialAnimations.topOverlayDetails.animate}
                    transition={noAnim ? {} : initialAnimations.topOverlayDetails.transition}
                    initial={noAnim ? {} : initialAnimations.topOverlayDetails.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                    <DetailsContent variant={noAnim ? 'detailsStMoritz' : 'details'} animSizePx={animSizePx} borderRadiusPx={borderRadiusPx} />
                </motion.div>
                <motion.div
                    className='top-layer top-layer-overlay top-layer-texture'
                    style={initialAnimationComplete ? styleTopTexture : {}}
                    animate={noAnim ? {} : initialAnimations.topOverlayTexture.animate}
                    transition={noAnim ? {} : initialAnimations.topOverlayTexture.transition}
                    initial={initialAnimations.topOverlayTexture.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                    <DetailsContent variant='texture' animSizePx={animSizePx} borderRadiusPx={borderRadiusPx} />
                </motion.div>
                <motion.div
                    className='top-layer'
                    style={initialAnimationComplete ? styleTop : { borderRadius: `${borderRadiusPx}px` }}
                    animate={noAnim ? {} : initialAnimations.top.animate}
                    transition={noAnim ? {} : initialAnimations.top.transition}
                    initial={noAnim ? {} : initialAnimations.top.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                </motion.div>

                {/* BOTTOM LAYER ------------- */}
                <motion.div
                    className='bottom-layer'
                    style={initialAnimationComplete ? styleBottom : {}}
                    animate={noAnim ? {} : initialAnimations.bottom.animate}
                    transition={noAnim ? {} : initialAnimations.bottom.transition}
                    initial={noAnim ? {} : initialAnimations.bottom.initial}
                >
                    <Box component='img' src={imageUrl || image_square_2} width='100%' alt='image' sx={{ borderRadius: `${borderRadiusPx}px` }} />
                </motion.div>

            </motion.div>
        </Box>
    );
}

import { Box, Modal, ModalProps, SxProps } from "@mui/material";
import { Colors } from "./variables";

type TrufoModalColorVariant = 'light' | 'dark' | 'accentLight' | 'accentDark' | 'white';

type TrufoModalProps = ModalProps & {
    colorVariant?: TrufoModalColorVariant,
    innerBoxSx?: SxProps,
}



export default function TrufoModal({ colorVariant, innerBoxSx, children, ...muiProps }: React.PropsWithChildren<TrufoModalProps>) {

    if (!colorVariant) { colorVariant = 'dark' };

    const variantStyles = {
        dark: {
            text: Colors.white,
            background: Colors.dark,
            overlay: Colors.dark20,
        },
        light: {
            text: Colors.dark,
            background: Colors.light,
            overlay: Colors.light20,
        },
        accentDark: {
            text: Colors.white,
            background: Colors.accentDark,
            overlay: Colors.accentDark20,
        },
        accentLight: {
            text: Colors.dark,
            background: Colors.accentLight,
            overlay: Colors.accentLight20,
        },
        white: {
            text: Colors.dark,
            background: Colors.offWhite,
            overlay: Colors.offWhite20,
        }
    }

    return (
        <Modal
            {...muiProps}
            sx={{
                backgroundColor: variantStyles[colorVariant].overlay,
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                ...muiProps?.sx,
            }}
        >
            <Box
                width='80%' height='80%'
                sx={{
                    backgroundColor: variantStyles[colorVariant].background,
                    color: variantStyles[colorVariant].text,
                    borderRadius: '5px',
                    ...innerBoxSx,
                }}
            >
                {children}
            </Box>
        </Modal>
    );
}
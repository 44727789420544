import { Link, LinkProps } from "@mui/material";
import { Colors } from ".";

type TrufoLinkVariants = 'black' | 'accentDark' | 'white';
type TrufoLinkProps = LinkProps & {
    trufoVariant?: TrufoLinkVariants;
    newtab?: boolean;
    colorOnHover?: string;
    noUnderline?: boolean;
};

export default function TrufoLink({ newtab, trufoVariant = 'black', href, colorOnHover, noUnderline, children, ...muiProps }: React.PropsWithChildren<TrufoLinkProps>) {

    const variantStyles = {
        black: {
            color: Colors.black, textDecorationColor: Colors.black,
            '&:hover': { color: colorOnHover || Colors.accentDark, textDecorationColor: colorOnHover || Colors.accentDark }
        },
        accentDark: {
            color: Colors.accentDark, textDecorationColor: Colors.accentDark,
            '&:hover': { color: colorOnHover || Colors.accentLight, textDecorationColor: colorOnHover || Colors.accentLight }
        },
        white: {
            color: Colors.white, textDecorationColor: Colors.white,
            '&:hover': { color: colorOnHover || Colors.accentLight, textDecorationColor: colorOnHover || Colors.accentLight }
        }
    }

    return (
        <Link
            href={href}
            {...(!href && { component: 'button' })}
            {...(newtab && { target: '_blank' })}
            {...muiProps}
            sx={{
                cursor: 'pointer', textAlign: 'center',
                ...(noUnderline ? { textDecoration: 'none' } : {}),
                ...variantStyles[trufoVariant],
                ...muiProps.sx
            }}
        >
            {children}
        </Link>
    )
}

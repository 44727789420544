import { ApiAvReturnMimeType, ApiWmContentLabel, ApiContentLicense, UiWmSettingsInvisible, ApiDecodingRequest, ApiEncodingRequest, UiEncodingSettings, FileType, ImageInfo, ApiUserLogin, ApiAvFormat, ApiWmType, ApiWmSettings } from '@types';

export const createEncodingRequest = (encodingSettings: UiEncodingSettings, imageBase64: string, imageInfo: ImageInfo, login: ApiUserLogin, isPreview: boolean): ApiEncodingRequest => {
    // TODO: enable toggling 'include user details'
    const es = encodingSettings;

    const contentLicenseCC = es.contentLicense?.selected ? es.contentLicense?.val : undefined;
    let contentLicense = contentLicenseCC || ApiContentLicense.NONE;
    if (es.doNotTrain) {
        if (contentLicenseCC) {
            contentLicense = `XA.${contentLicenseCC}` as ApiContentLicense;
        } else {
            contentLicense = ApiContentLicense.XA;
        }
    }
    // const VISIBLE_WATERMARK_RATIO = 1 / 40;

    const wmSettings: ApiWmSettings = {};
    if (es.invisible?.selected) {
        const key = es.invisible?.val === UiWmSettingsInvisible.LIGHT ? ApiWmType.G1_IMAGE_LITE : ApiWmType.G1_IMAGE;
        wmSettings[key] = {};
    }
    if (es.visible?.selected) {
        wmSettings[ApiWmType.G0_IMAGE_VIS] = { location: es.visible?.val };
    }
    if (es.metadata?.selected) {
        wmSettings[ApiWmType.G0_META] = { preserve: es.visible?.selected };
        wmSettings[ApiWmType.G1_META_C2PA] = {};
    }

    const ret: ApiEncodingRequest = {
        authData: login,
        avData: imageBase64?.split(',')[1] || '',
        avReturnMimeType: imageInfo.type === FileType.PNG ? ApiAvReturnMimeType.PNG : ApiAvReturnMimeType.JPG,
        avFormat: ApiAvFormat.IMAGE,
        wmSettings: wmSettings,
        contentInfo: {
            displayName: es.name?.selected ? es.name?.val : undefined,
            description: es.description?.selected ? es.description?.val : undefined,
            contentLinks: undefined,
            contentLabel: es.contentLabel?.selected ? es.contentLabel?.val : ApiWmContentLabel.NONE,
            contentLicense: contentLicense,
        },
        isPreview,
    }

    return ret;
}

export const createDecodingRequest = (imageBase64: string): ApiDecodingRequest => {

    const ret: ApiDecodingRequest = {
        avData: imageBase64?.split(',')[1] || '',
        avFormat: ApiAvFormat.IMAGE,
    };

    return ret;
}

export const getContentLicenseTags = (contentLicense: string): string[] => {

    const substringTagMappings: Record<string, string> = {
        CC: 'creative commons',
        XA: 'no AI training',
        BY: 'attribution required',
        SA: 'share-alike (copyleft)',
        NC: 'no commercial usage',
        ND: 'no derivative works',
        0: 'no rights reserved',
    }
    const substrings = Object.keys(substringTagMappings);
    const tags: string[] = [];
    substrings.forEach(substring => {
        if (contentLicense.includes(substring)) tags.push(substringTagMappings[substring]);
    })
    return tags;
}

export const noEncodingMethodSelected = (encodingSettings: UiEncodingSettings) => {
    const es = encodingSettings;
    return !es.invisible?.selected && !es.visible?.selected && !es.metadata?.selected;
}
import { Box } from "@mui/material"
import { Colors, FontWeights, IconLinkExternal, ResponsiveSpacing } from "@/components";
import { DescriptionTextBlock } from "./description-text-block";

export type LinksSectionLinkRow = {
    title: string,
    date: Date,
    updatedDate?: Date,
    source: string,
    url: string,
}
export type LinksSectionProps = {
    links: LinksSectionLinkRow[];
    sectionHeaderTop?: string,
    sectionHeaderBottom?: string,
}

const SECTION_BACKGROUND_COLOR = Colors.lightestGray;
const TRANSITION_STYLE = 'opacity 0.3s ease-in-out';

export const LinksSection = ({ links, sectionHeaderTop, sectionHeaderBottom }: LinksSectionProps) => {
    const getDateDisplayString = (date: Date) => date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }).toUpperCase();
    return (
        <Box
            borderRadius='8px'
            padding='40px'
            width='100%'
            boxSizing='border-box'
            display='flex' flexDirection='column'
            sx={{ backgroundColor: SECTION_BACKGROUND_COLOR }}
        >
            <DescriptionTextBlock
                sizeVariant='small' headerTop={sectionHeaderTop} headerBottom={sectionHeaderBottom}
                marginBottom={ResponsiveSpacing.space5}
            />

            {links.map(link => (
                <Box
                    position='relative' width='100%'
                    display='flex' alignItems='center'
                    borderTop={`1px solid ${Colors.gray}`} borderBottom={`1px solid ${Colors.gray}`}
                    gap={ResponsiveSpacing.space1}
                    paddingTop={ResponsiveSpacing.space3} paddingBottom={ResponsiveSpacing.space3}
                    marginTop='-1px'
                    sx={{
                        cursor: 'pointer',
                        ':hover': {
                            '.tflp-external-link-icon': {
                                opacity: 1
                            }
                        }
                    }}
                    onClick={() => { window?.open(link?.url, '_blank')?.focus() }}
                >
                    {/* hacky div for the purposes of adding transition animation to on hover style  */}
                    <Box
                        position='absolute' width='100%' height='100%' top='0' left='0'
                        sx={{
                            transition: TRANSITION_STYLE,
                            background: `linear-gradient(to right, transparent, ${Colors.accentLightLp20} 5%, ${Colors.accentLightLp20} 95%, transparent)`,
                            opacity: 0,
                            '&:hover': { opacity: 1, }
                        }}
                    />
                    <Box display='flex' flexDirection='column' alignItems='start' flex={1}>
                        {/* actual content below: */}
                        <Box fontFamily='monospace'>{getDateDisplayString(link?.date)}{link?.updatedDate ? ` (UPDATED ${getDateDisplayString(link?.updatedDate)})` : ''}</Box>
                        <Box
                            display='flex' gap={ResponsiveSpacing.space3} alignItems='center'
                        >
                            <Box fontWeight={FontWeights.semibold}>{link?.source}</Box>
                            <Box height='90%' borderRight={`1px solid ${Colors.dark}`}></Box>
                            <Box textAlign='start'>{link?.title}</Box>
                        </Box>
                    </Box>

                    <IconLinkExternal
                        colorVariant="accentDark" // TODO: figure out how to make this color variant work
                        className='tflp-external-link-icon'
                        sx={{
                            opacity: 0,
                            transition: TRANSITION_STYLE,
                        }}
                    />
                </Box>
            ))}
        </Box>
    )

}
import { LoadingPage } from '@pages';
import { ReactNode, useEffect, useState } from 'react';
import { useEncodeImage } from '@hooks';
import { UiEncodingSettings, ImageInfo, MAX_LENGTH_CONTENT_NAME, defaultEncodingSettings, ApiUserLogin } from '@types';
import EncodePage from './encode-page';
import DownloadPage from './download-page';

type EncodeProps = {
    imageFile: File;
    imageInfo: ImageInfo;
    userLogin: ApiUserLogin;
    settingsModal: ReactNode;
    openSettingsModal: () => void;
}

export default function Encode({ imageFile, imageInfo, userLogin, settingsModal, openSettingsModal }: EncodeProps) {

    const getPreviousEncodingSettings = () => {
        const prevEncodingSettingsJson = localStorage.getItem('encodingSettings');
        return prevEncodingSettingsJson ? JSON.parse(prevEncodingSettingsJson) : defaultEncodingSettings;
    }
    const defaultName = imageInfo.name.substring(0, imageInfo.name.lastIndexOf('.'));
    const [encodingSettings, setEncodingSettings] = useState<UiEncodingSettings>({ ...getPreviousEncodingSettings(), name: { selected: true, val: defaultName.slice(0, MAX_LENGTH_CONTENT_NAME) } });
    useEffect(() => { localStorage.setItem('encodingSettings', JSON.stringify(encodingSettings)) }, [encodingSettings])

    const { encodeImage, data: watermarkedImage, loading: encodeImageLoading, error: encodeImageError } = useEncodeImage();

    return (
        !encodeImageLoading ? (!watermarkedImage ?
            <EncodePage
                imageFile={imageFile}
                imageInfo={imageInfo}
                encodingSettings={encodingSettings}
                onEncodingSettingsChange={(newState: UiEncodingSettings) => { setEncodingSettings(prevState => { return { ...prevState, ...newState } }) }}
                encodeImage={encodeImage}
                userLogin={userLogin}
                settingsModal={settingsModal}
                openSettingsModal={openSettingsModal}
                error={encodeImageError}
            />
            :
            <DownloadPage
                watermarkedImage={watermarkedImage}
            />
        ) : (<LoadingPage variant='dark' />)
    )
}
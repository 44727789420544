import { Colors, Fonts, ResponsiveFontSizes, ResponsiveSpacing, ZIndexes } from '@components';
import { Box, BoxProps } from '@mui/material';
import image_square_1 from '@assets/image_square_1.jpg';
import { ApiDecodingResponse, UiEncodingSettings, ImageAspectRatio } from '@types';
import { ImageAlignment, ImageAndContentDisplayVariant, MAX_LENGTHS } from '.';
import ContentDisplayEncode from './content-display-encode';
import ContentDisplayDecode from './content-display-decode';
import { getImageAspectRatioStr } from '@/utils';

type ContentDisplayProps = {
    variant: ImageAndContentDisplayVariant,
    showDiff: boolean;
    onShowDiffChange: (showDiff: boolean) => void;
    aspectRatio?: ImageAspectRatio,
    imageAlignment: ImageAlignment,
    encodingSettings?: UiEncodingSettings,
    decodingResponse?: ApiDecodingResponse,
}

export function ContentDetailBox({ children, ...muiProps }: React.PropsWithChildren<BoxProps>) {
    return (
        <Box
            width='94%' borderRadius='5px' padding={ResponsiveSpacing.space1}
            display='flex' flexDirection='column' alignItems='center'
            {...muiProps}
            sx={{ backgroundColor: Colors.dark10, ...muiProps?.sx }}
        >
            {children}
        </Box>
    )
}

export const contentDetailHeaderStyle = {
    marginBottom: ResponsiveSpacing.space1,
}

export const contentDetailValueStyle = {
    fontFamily: Fonts.monospace,
    width: '100%',
    sx: { overflowWrap: 'break-word' },
}

export default function ContentDisplay({ variant, showDiff, onShowDiffChange, aspectRatio, imageAlignment, encodingSettings, decodingResponse }: ContentDisplayProps) {

    return (
        <Box
            position='relative'
            maxHeight={imageAlignment === 'vertical' ? MAX_LENGTHS.shorter : MAX_LENGTHS.longer
            }
            maxWidth={imageAlignment === 'vertical' ? MAX_LENGTHS.longer : MAX_LENGTHS.shorter}
            sx={{ aspectRatio: getImageAspectRatioStr(aspectRatio) }}
        >
            <Box
                width='100%' height='100%'
                position='absolute' left='0px' top='0px'
                display='flex' flexDirection='column' alignItems='center'
                gap={ResponsiveSpacing.space2} paddingTop={ResponsiveSpacing.space2} paddingBottom={ResponsiveSpacing.space2}
                sx={{
                    border: 1, borderStyle: 'dashed', borderColor: Colors.dark50, boxSizing: 'border-box',
                    backgroundColor: Colors.light50, color: Colors.dark50,
                    fontSize: ResponsiveFontSizes.body0, // imageAlignment === 'vertical' ? '2vw' : '2vh'\
                    overflowY: 'auto', overflowX: 'hidden', zIndex: ZIndexes.clickable
                }}
            >
                {variant === 'encode' ?
                    <ContentDisplayEncode encodingSettings={encodingSettings || {}} /> :
                    <ContentDisplayDecode decodingResponse={decodingResponse || {}} showDiff={showDiff} onShowDiffChange={onShowDiffChange} />
                }
            </Box>
            <Box
                display='block' width='100%' height='100%'
                visibility='hidden'
                component='img' src={image_square_1}
            // src={imageFile ? imageUrl : image_square_1}
            />
        </Box>
    );
}
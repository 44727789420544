import { BANNER_HEIGHT_PX, Colors, CompactMenu, FontWeights, NAVBAR_HEIGHT_PX, ResponsiveSpacing, TrufoButton, TrufoLink, ZIndexes, SHOW_BANNER, ResponsiveFontSizes } from "@/components"
import { Box } from "@mui/material"
import trufo_white from '@assets/trufo_white.png';
import { ABOUT_ROUTE, DEMO_REQUEST_LINK, LANDING_PAGE_ROUTE, LOGIN_ROUTE, MAIN_APP_ROUTE, PEOPLE_ROUTE, PRICING_ROUTE, PRODUCTS_ROUTE, RESEARCH_ROUTE } from "@/app-routes";
import { motion } from "framer-motion";
import MenuIcon from '@mui/icons-material/Menu';
import { ReactNode } from 'react';

export interface LpNavbarProps {
    compact?: boolean;
    loggedIn?: boolean;
    loggedInLoading?: boolean;
    marginLeftRight?: string;
    banner?: ReactNode;
}
export const LpNavbar = ({ compact, loggedIn, loggedInLoading, marginLeftRight, banner }: LpNavbarProps) => {

    const COMPACT_MENU_BUTTON_ICON_PX = 24;
    const COMPACT_MENU_BUTTON_PADDING_PX = 8;
    const COMPACT_MENU_BUTTON_DIMENSION_PX = COMPACT_MENU_BUTTON_ICON_PX + COMPACT_MENU_BUTTON_PADDING_PX * 2;

    const middleLinks: Record<string, string> = {
        'about': ABOUT_ROUTE,
        'products': PRODUCTS_ROUTE,
        'pricing': PRICING_ROUTE,
        // 'publications': PUBLICATIONS_ROUTE,
        research: RESEARCH_ROUTE,
        'people': PEOPLE_ROUTE,
        // 'press': PRESS_ROUTE,
    };
    const rightLinks: Record<string, string> = {
        ...(loggedIn ? { 'My Account': MAIN_APP_ROUTE, } : { 'Log In': LOGIN_ROUTE, })
    }

    const getNavbarItem = (name: string, href: string) => (
        <TrufoLink
            href={href} trufoVariant="white"
            noUnderline
            colorOnHover={Colors.accentLightLp}
        >
            {name}
        </TrufoLink>
    )

    const showBanner = SHOW_BANNER && banner;

    return (
        <>
            {showBanner &&
                <Box
                    position='fixed' left='0' top='0'
                    height={BANNER_HEIGHT_PX} width='100%'
                    paddingLeft={ResponsiveFontSizes.body0} paddingRight={ResponsiveFontSizes.body0}
                    color='white'
                    sx={{ backgroundColor: Colors.accentDarkLp }}
                    display='flex' justifyContent='center' alignItems='center'
                    borderBottom={`1px solid ${Colors.white}`}
                    zIndex={ZIndexes.landingPageNavbar}
                    boxSizing='border-box'
                >
                    {banner}
                </Box>
            }
            <Box
                position='fixed' left='0' top={showBanner ? BANNER_HEIGHT_PX : 0} width='100%' height={NAVBAR_HEIGHT_PX}
                boxSizing='border-box'
                paddingLeft={marginLeftRight || ResponsiveSpacing.space6} paddingRight={marginLeftRight || ResponsiveSpacing.space6}
                // flex={0} flexBasis={NAVBAR_HEIGHT_PX}
                display='flex' justifyContent='space-between' alignItems='center'
                borderBottom={`1px solid ${Colors.white}`}
                zIndex={ZIndexes.landingPageNavbar}
                sx={{ backgroundColor: Colors.dark }}
            >
                <Box
                    component='img' src={trufo_white} height='50px' alt='trufo'
                    onClick={() => { window.location.href = LANDING_PAGE_ROUTE }}
                    sx={{ cursor: 'pointer' }}
                />
                {!compact ?
                    <>
                        <Box
                            display='flex' justifyContent='center' alignItems='center' gap='20px'
                            zIndex={ZIndexes.landingPageClickable} color='white'
                            marginLeft='40px'
                        >
                            {Object.keys(middleLinks).map((linkName) =>
                                <Box>{getNavbarItem(linkName, middleLinks[linkName])}</Box>)
                            }
                        </Box>
                        <Box
                            display='flex' justifyContent='flex-end' alignItems='center' gap='20px' zIndex={ZIndexes.landingPageClickable}
                            width='240px'
                        >
                            {!loggedInLoading && Object.keys(rightLinks).map((linkName) =>
                                <Box>{getNavbarItem(linkName, rightLinks[linkName])}</Box>)
                            }
                            <TrufoButton
                                colorVariant='landingPagePrimaryLight' label='Request a Demo'
                                sx={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: FontWeights.bold }}
                                href={DEMO_REQUEST_LINK} newtab
                            />
                        </Box>
                    </>
                    : <CompactMenu
                        links={{
                            ...middleLinks,
                            ...rightLinks
                        }}
                        containerProps={{
                            position: 'absolute',
                            right: `calc(${marginLeftRight} - ${COMPACT_MENU_BUTTON_PADDING_PX}px)`,
                            top: `${(NAVBAR_HEIGHT_PX - COMPACT_MENU_BUTTON_DIMENSION_PX) / 2}`,
                            zIndex: ZIndexes.navbar,
                            visibility: compact ? 'visible' : 'hidden'
                            // visibility: (compact && !(extraCompact && onDetailPage)) ? 'visible' : 'hidden'
                        }}
                        buttonProps={{
                            sx: {
                                paddingLeft: 0, paddingRight: 0,
                                paddingTop: `${COMPACT_MENU_BUTTON_PADDING_PX}px`,
                                paddingBottom: `${COMPACT_MENU_BUTTON_PADDING_PX}px`,
                                width: `${COMPACT_MENU_BUTTON_DIMENSION_PX}px !important`,
                                minWidth: `${COMPACT_MENU_BUTTON_DIMENSION_PX}px !important`
                            }
                        }}
                    >
                        <motion.div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <MenuIcon />
                        </motion.div>
                    </CompactMenu>
                }
            </Box >
        </>
    )
}
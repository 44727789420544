import { Alert, Box } from "@mui/material";
import { Colors, FontSizes, ZIndexes, NAVBAR_HEIGHT_PX } from "@components";
import { Component } from "react";
import { getFileUploadErrorMessage, UploadError, validateImageAndUpload } from "./image-upload-utils";
import { ImageInfo, Method } from "@types";
import { debounce } from 'lodash';

type ImageUploadDragzoneProps = {
    onValidFileChange: (newFile: File, newInfo: ImageInfo) => void,
    onMethodChange?: (method: Method | undefined) => void,
    disableEncode?: boolean,
    forceShowDragzone?: boolean,
}

type ImageUploadDragzoneState = {
    error: UploadError,
    imageInfo: ImageInfo | undefined,
    method: Method | undefined,
    draggingLeft: boolean,
    draggingRight: boolean,
    dragging: boolean,
}


export class ImageUploadDragzone extends Component<ImageUploadDragzoneProps, ImageUploadDragzoneState> {

    constructor(props: ImageUploadDragzoneProps) {
        super(props);
        this.state = {
            error: undefined,
            imageInfo: undefined,
            method: undefined,
            dragging: false,
            draggingLeft: false,
            draggingRight: false,
        };
        this.setError = this.setError.bind(this);
        this.setImageInfo = this.setImageInfo.bind(this);
        this.setMethod = this.setMethod.bind(this);
        this.setDragging = this.setDragging.bind(this);
        this.setDraggingDebounced = this.setDraggingDebounced.bind(this);
        this.setDraggingLeft = this.setDraggingLeft.bind(this);
        this.setDraggingLeftDebounced = this.setDraggingLeftDebounced.bind(this);
        this.setDraggingRight = this.setDraggingRight.bind(this);
        this.setDraggingRightDebounced = this.setDraggingRightDebounced.bind(this);
    }

    setError(newError: UploadError) { this.setState({ error: newError }) };

    setImageInfo(newImageInfo: ImageInfo) { this.setState({ imageInfo: newImageInfo }) };

    setMethod(newMethod: Method) { this.setState({ method: newMethod }) };

    setDragging(dragging: boolean) {
        this.setState({ dragging: dragging });
    };
    setDraggingDebounced = debounce((dragging: boolean) => {
        const currentDragging = this.state.draggingRight || this.state.draggingLeft;
        const continueWithOp = dragging ? currentDragging : !currentDragging;
        if (continueWithOp) { this.setDragging(dragging); };
    }, 50);

    setDraggingLeft(draggingLeft: boolean) {
        this.setState({ draggingLeft: draggingLeft });
        this.setDragging(draggingLeft || this.state.draggingRight);
    };
    setDraggingLeftDebounced = debounce((draggingLeft: boolean) => {
        this.setDraggingLeft(draggingLeft);
        this.setDraggingDebounced(draggingLeft || this.state.draggingRight);
    }, 50);

    setDraggingRight(draggingRight: boolean) {
        this.setState({ draggingRight: draggingRight });
        this.setDragging(draggingRight || this.state.draggingLeft);
    };
    setDraggingRightDebounced = debounce((draggingRight: boolean) => {
        this.setDraggingRight(draggingRight);
        this.setDraggingDebounced(draggingRight || this.state.draggingLeft);
    }, 50);


    render() {

        const { onValidFileChange, onMethodChange, disableEncode, forceShowDragzone } = this.props;
        const { error, imageInfo, method, draggingLeft, draggingRight } = this.state;

        const getHandleDrag = (
            dragType: 'dragover' | 'drop' | 'dragenter' | 'dragleave',
            onDrag: (dragging: boolean) => void,
            method?: Method,
        ) => {
            return (e: React.DragEvent<HTMLDivElement> | DragEvent) => {
                e.preventDefault();
                e.stopPropagation();
                if (dragType === 'dragover') { onDrag(true); }
                if (dragType === 'dragleave') { onDrag(false); };
                if (dragType === 'drop') {
                    const dt = e.dataTransfer;
                    if (dt && dt.files && dt.files.length) {
                        validateImageAndUpload(dt.files[0], onValidFileChange, this.setError, this.setImageInfo, method);
                        method && this.setMethod(method);
                        method && onMethodChange && onMethodChange(method);
                    }
                    onDrag(false);
                };
            }
        };


        const dragOverlayStyle = {
            width: '50%', height: '100%',
            position: 'absolute', top: '0px',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            fontSize: FontSizes.header1, color: Colors.white, fontWeight: 'bold',
            transitionDuration: '0.2s',
        }

        return (
            <>
                {error && <Box
                    position='absolute' left='0px' top={`${NAVBAR_HEIGHT_PX}px`}
                    width='100dvw' zIndex={ZIndexes.alerts}
                >
                    <Alert severity="error">{getFileUploadErrorMessage(error, imageInfo, method)}</Alert>
                </Box>}
                {/* Visible-on-drag image upload drop overlay */}
                <Box
                    position='absolute' left='0px' top='0px'
                    height='100dvh' width='100dvw'
                    sx={{
                        opacity: (draggingLeft || draggingRight || forceShowDragzone) ? 1 : 0,
                        zIndex: (draggingLeft || draggingRight || forceShowDragzone) ? ZIndexes.dragOverlay : ZIndexes.base,
                        transitionDuration: '0.2s',
                    }}
                >

                    {/* Left drag & drop (Encode) */}
                    {!disableEncode &&
                        <div
                            style={{
                                ...dragOverlayStyle,
                                position: 'absolute', left: '0px',
                                backgroundColor: Colors.blue,
                                opacity: draggingLeft ? 0.9 : 0.5,
                                padding: 0,
                            }}
                            onDragOver={getHandleDrag('dragover', this.setDraggingLeft)}
                            onDragLeave={getHandleDrag('dragleave', this.setDraggingLeftDebounced)}
                            onDrop={getHandleDrag('drop', this.setDraggingLeft, Method.ENCODE)}
                        >
                            Encode
                        </div>
                    }

                    {/* Right drag & drop (Decode) */}
                    <div
                        style={{
                            ...dragOverlayStyle,
                            position: 'absolute', right: '0px',
                            backgroundColor: Colors.greenSage,
                            opacity: draggingRight ? 0.9 : 0.5,
                            ...(disableEncode ? { width: '100%' } : {})
                        }}
                        onDragOver={getHandleDrag('dragover', this.setDraggingRight)}
                        onDragLeave={getHandleDrag('dragleave', this.setDraggingRightDebounced)}
                        onDrop={getHandleDrag('drop', this.setDraggingRight, Method.DECODE)}
                    >
                        Decode
                    </div>

                </Box>
            </>
        );
    }
}
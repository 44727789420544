import { useEffect, useMemo, useState } from 'react';
import LoadingPage from '../loading-page';
import ImageUpload from './image-upload';
import { ImageInfo, Method } from '@types';
import Encode from './encode';
import Decode from './decode';
import { getGoogleUserLoginFromStorage } from '@utils';
import { ImageUploadDragzone } from './image-upload/image-upload-dragzone';
import { useDecodeImage, useGetUser, useSettingsModal } from '@/hooks';
import MainAppWrapper from './main-app-wrapper';

export default function MainApp() {
    const [validFile, setValidFile] = useState<File>();
    const [validImageInfo, setValidImageInfo] = useState<ImageInfo>();
    const [method, setMethod] = useState<Method>();

    const { getUser, data: userData, loading: getUserLoading } = useGetUser();
    const googleLogin = useMemo(() => getGoogleUserLoginFromStorage(), []);
    const refetchTrufoLogin = () => googleLogin && getUser(googleLogin);
    useEffect(() => { refetchTrufoLogin() }, [googleLogin, getUser]);
    const hasTrufoLogin = Boolean(userData);
    const userCanEncode = Boolean(googleLogin) && hasTrufoLogin;

    const { decodeImage, data: decodeImageData, loading: decodeImageLoading, error: decodeImageError } = useDecodeImage();
    const imageHasDecodingValue = decodeImageData?.contentIdentification && Object.keys(decodeImageData?.contentIdentification).length > 0;

    const loading = getUserLoading || decodeImageLoading;

    useEffect(() => {
        if (validFile) decodeImage(validFile);
    }, [validFile, decodeImage])

    const { SettingsModal, openSettingsModal } = useSettingsModal();
    const settingsModalWithRefetch = <SettingsModal onSuccessfulSave={refetchTrufoLogin} />

    const [isDragging, setIsDragging] = useState<boolean>(false);

    return (
        <>
            <div
                style={{ height: '100%', width: '100%' }}
                // style={{ height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`, width: '100vw' }}
                onDragOver={() => setIsDragging(true)}
                onDragLeave={() => setIsDragging(false)}
            >
                <MainAppWrapper onAccountSettingsSave={refetchTrufoLogin}>
                    {!loading && !validFile &&
                        <ImageUpload
                            onValidFileChange={
                                (newFile: File, newInfo: ImageInfo) => {
                                    setValidFile(newFile); setValidImageInfo(newInfo);
                                }
                            }
                            onMethodChange={setMethod}
                            disableEncode={!userCanEncode}
                        />
                    }

                    {(!loading && validFile && validImageInfo) &&
                        ((method === Method.ENCODE && hasTrufoLogin && googleLogin && !imageHasDecodingValue) ?
                            <Encode imageFile={validFile} imageInfo={validImageInfo} userLogin={hasTrufoLogin && googleLogin} settingsModal={settingsModalWithRefetch} openSettingsModal={openSettingsModal} />
                            : <Decode data={decodeImageData} loading={decodeImageLoading} error={decodeImageError} uploadedImageFile={validFile} uploadedImageInfo={validImageInfo} redirectedFromEncoding={method === Method.ENCODE} />
                        )
                    }

                    {loading && (<LoadingPage variant='dark' />)}
                </MainAppWrapper>
            </div>

            {!loading &&
                <ImageUploadDragzone
                    onValidFileChange={(newFile: File, newInfo: ImageInfo) => {
                        setValidFile(newFile); setValidImageInfo(newInfo);
                    }}
                    onMethodChange={setMethod}
                    disableEncode={!userCanEncode}
                    forceShowDragzone={isDragging}
                />
            }
        </>
    );
}
import { Colors } from "@/components";
import { Box } from "@mui/material";
import Login from "./login";
import MainAppWrapper from "../main-app/main-app-wrapper";

export default function LoginPage() {
    return (
        <MainAppWrapper>
            <Box
                display={'flex'} flexGrow={0} width='100%' height='100%' alignSelf='stretch'
                flexDirection='column' justifyContent='center'
                sx={{ backgroundColor: Colors.light, color: Colors.dark }}
            >
                <Box display='flex' flexDirection='column' gap='10px'>
                    <Login accessCodeFocus={true} onSuccessVariant='redirect' />
                </Box>
            </Box>
        </MainAppWrapper>
    )
}
import { Box } from "@mui/material"
import { LpText, SectionWrapper } from "./../components"
import { ResponsiveSpacing, TrufoLink } from "@/components";
import { SectionProps } from "../types-and-constants";
import { AISIC_TF21_PRESENTATION_RECORDING_ROUTE } from "@/app-routes";

export type SectionTrustProps = SectionProps;

export const SectionTrust = (props: SectionTrustProps) => {
    const compact = props?.pageWidthPx < 700;

    const row1 = <>
        <Box flex={1}>
            <LpText variant='header'>3 Million+</LpText>
            <LpText>images watermarked</LpText>
        </Box>
        <Box flex={compact ? 1 : 1.3}>
            <LpText variant='body'>Leading contributor to</LpText>
            <LpText variant='header'>AISIC, C2PA</LpText>
            <LpText>Trufo was recently invited to teach other experts about content authenticity. <TrufoLink href={AISIC_TF21_PRESENTATION_RECORDING_ROUTE} newtab>[Watch]</TrufoLink></LpText>
        </Box>
    </>
    const row2 = <>
        <Box flex={compact ? 1 : 1.8}>
            <LpText variant='body'>Founding team from</LpText>
            <LpText variant='header'>Princeton + Stanford</LpText>
            <LpText variant='header'>Google + Netflix</LpText>
        </Box>
        <Box flex={1}>
            <LpText variant='body'>R&D appearances at</LpText>
            <LpText variant='header'>NeurIPS '24</LpText>
            <LpText variant='header'>RWC '25</LpText>
        </Box>
    </>
    const rowBoxProps = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: ResponsiveSpacing.space4,
        width: '100%'
    }

    return (
        <SectionWrapper
            colorVariant="accentLight"
            {...props}
            gap={ResponsiveSpacing.space6}
        >
            <LpText variant='headerBold'>
                Trusted by organizations around the world
            </LpText>
            <Box
                {...rowBoxProps}
                flexDirection={compact ? 'column' : 'row'}
                textAlign='center'
                gap={compact ? ResponsiveSpacing.space6 : ResponsiveSpacing.space4}
            >
                {compact ?
                    <><Box {...rowBoxProps}>{row1}</Box><Box {...rowBoxProps}>{row2}</Box></> :
                    <>{row1}{row2}</>
                }

            </Box>

        </SectionWrapper>
    )
}
import Box from "@mui/material/Box"
import { ReactComponent as Discord } from '@assets/icons/social-discord.svg'
import { ReactComponent as XDark } from '@assets/icons/social-x-dark.svg'
import { ReactComponent as XLight } from '@assets/icons/social-x-light.svg'
import { ReactComponent as Linkedin } from '@assets/icons/social-linkedin.svg'
import { ReactComponent as Medium } from '@assets/icons/social-medium.svg'
import { ReactComponent as Youtube } from '@assets/icons/social-youtube.svg'
import { ReactComponent as Instagram } from '@assets/icons/social-instagram.svg'
import { ReactComponent as Github } from '@assets/icons/social-github.svg'
import { ReactComponent as LinkExternal } from '@assets/icons/bx-link-external.svg'
import { ReactComponent as Checkmark } from '@assets/icons/checkmark.svg'
import { Colors } from "./variables"
import { ReactNode } from "react"
import { SxProps } from "@mui/material"


export type IconProps = {
    // size?: 'small' | 'medium' | 'large';
    size?: string;
    clickable?: boolean;
    colorVariant?: 'light' | 'dark' | 'accentDark' | 'accentLight';
    href?: string;
    onClick?: () => void;
    className?: string;
} & { sx?: SxProps };

const IconWrapper = (
    {
        icon, clickable, colorVariant = 'light',
        size, // size = 'medium', 
        sx, className, onClick, href
    }: IconProps & { icon: ReactNode, sx?: SxProps }
) => {
    const variantStyle = {
        light: {
            base: {
                color: Colors.light
            },
            hover: {
                color: Colors.accentLightLp,
            }
        },
        dark: {
            base: {
                color: Colors.dark
            },
            hover: {
                color: Colors.accentDarkLp,
            }
        },
        accentLight: {
            base: {
                color: Colors.accentLightLp
            },
            hover: {
                color: Colors.accentDarkLp,
            }
        },
        accentDark: {
            base: {
                color: Colors.accentDarkLp
            },
            hover: {
                color: Colors.accentLightLp,
            }
        }
    }
    // TODO: make size style work
    // const sizeStyle = {
    //     small: {
    //         height: FontSizes.body3
    //     },
    //     medium: {
    //         height: FontSizes.header1
    //     },
    //     large: {
    //         width: FontSizes.header0
    //     }
    // }
    const clickableStyle = {
        cursor: 'pointer',
        '&:hover': {
            ...variantStyle[colorVariant]?.hover
        },
    }

    return (
        <Box
            onClick={onClick ? onClick : href ? () => { window?.open(href, '_blank')?.focus(); } : undefined}
            sx={{
                ...variantStyle[colorVariant],
                ...(clickable && clickableStyle),
                ...sx,
                '& svg': {
                    height: size
                }
            }}
            display='flex' alignItems='center' justifyContent='center'
            className={className}
        >
            {icon}
        </Box>
    )
}

export const IconDiscord = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<Discord />} />
}
export const IconX = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx}
        icon={iconProps?.colorVariant === 'dark' ? <XLight /> : <XDark />} />
}
export const IconLinkedin = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<Linkedin />} />
}
export const IconMedium = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<Medium />} />
}
export const IconYoutube = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<Youtube />} />
}
export const IconInstagram = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<Instagram />} />
}
export const IconGithub = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<Github />} />
}
export const IconLinkExternal = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<LinkExternal />} />
}
export const IconCheckmark = ({ sx, ...iconProps }: IconProps) => {
    return <IconWrapper {...iconProps} sx={sx} icon={<Checkmark />} />
}
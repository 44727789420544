import { FileType, ImageInfo, Method } from '@types';
import { isTestEnv } from '@utils';

export type UploadError = 'type' | 'dimensions' | null | undefined;

export const MAX_IMAGE_WIDTH = isTestEnv() ? 3840 : 3840;
export const MAX_IMAGE_HEIGHT = isTestEnv() ? 4320 : 4320;
export const VALID_FILE_TYPES: Record<Method, string[]> = {
    [Method.ENCODE]: [
        FileType.PNG,
        FileType.JPG,
        FileType.JPEG,
        FileType.BMP, // -> png
        FileType.GIF, // -> png
        FileType.TIFF, // -> png
        // FileType.HEIC, // -> jpg
        // FileType.HEIF, // -> jpg
        FileType.WEBP, // -> jpg
        FileType.AVIF, // -> jpg
    ],
    [Method.DECODE]: [
        FileType.PNG,
        FileType.JPG,
        FileType.JPEG,
        FileType.BMP,
        FileType.GIF,
        FileType.TIFF,
        // FileType.HEIC,
        // FileType.HEIF,
        FileType.WEBP,
        FileType.AVIF,
    ],
};

export const validateImageAndUpload = (
    file: File | undefined,
    onValidFileChange: (newFile: File, imageInfo: ImageInfo) => void,
    onUploadError: (error: UploadError) => void,
    onImageInfoChange?: (imageInfo: ImageInfo) => void,
    method?: Method,
) => {

    let valid = true;

    if (file) {
        let newFile = file;
        let validFileType: FileType;

        // TODO: extra parsing needed to support HEIC/HEIF https://stackoverflow.com/questions/57127365/make-html5-filereader-working-with-heic-files

        // check filetype
        if (VALID_FILE_TYPES[method || Method.ENCODE].includes(file.type)) {
            // // cast 'jpeg' to 'jpg'
            // if (file.type === FileType.JPEG) {
            //     newFile = new File([file], file.name, { type: FileType.JPG });
            // }
            validFileType = file.type as FileType;
        } else {
            valid = false;
            const imageInfo: ImageInfo = { name: newFile.name, width: 0, height: 0, type: file.type };
            onImageInfoChange && onImageInfoChange(imageInfo);
            onUploadError('type');
        }

        // // check size
        // if (file.size > (MAX_FILE_SIZE_MB * 1000000)) {
        //     valid = false;
        //     onUploadError('size');
        // }

        // check dimensions + upload if valid
        let u = URL.createObjectURL(file);
        const img = new Image();
        img.onload = function () {
            const imageInfo: ImageInfo = { name: newFile.name, width: img.width, height: img.height, type: validFileType || file.type };
            onImageInfoChange && onImageInfoChange(imageInfo);
            // TODO: have different dimension limits for encode vs decode
            // TODO: for variations in dimensions / filetypes for encode vs decode, handle case where encode redirects to decode
            if (img.height * img.width > (MAX_IMAGE_HEIGHT * MAX_IMAGE_WIDTH)) {
                // if dimension is not ok, deny upload
                onUploadError('dimensions');
                valid = false;
            }

            // if all previous checks are ok, allow upload
            if (valid) {
                onValidFileChange(newFile, { ...imageInfo });
                onUploadError(undefined);
            }
        };
        img.src = u;
    }
}

export const getFileUploadErrorMessage = (error: UploadError, imageInfo?: ImageInfo, method?: Method) => {
    const filetypeArr = imageInfo?.type.split('/');
    const filetypeStr = Array.isArray(filetypeArr) ? filetypeArr[filetypeArr.length - 1].toUpperCase() : 'UNKNOWN';

    if (error === 'dimensions') return `Error: image dimension is ${imageInfo?.width || 'UNKNOWN'}*${imageInfo?.height || 'UNKNOWN'}, which is larger than the maximum ${MAX_IMAGE_WIDTH}*${MAX_IMAGE_HEIGHT}. For custom support of larger files, contact support@trufo.ai.`;
    if (error === 'type') return `Error: file type ${filetypeStr} not supported; please upload your file in the following formats: ${getListOfSupportedFileTypesStr(method)}`;
    // if (error === 'size') return `Error: image size larger than supported.`
}

export const getListOfSupportedFileTypesStr = (method?: Method) => {
    return VALID_FILE_TYPES[method || Method.ENCODE]
        .filter(filetype => filetype !== FileType.JPEG)
        .map(filetype => filetype.split('/')[1].toUpperCase())
        .join(', ')
}
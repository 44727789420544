import { ExpressClient, TRUFO_API } from '@/http-clients';
import { getGoogleUserJwtFromStorage, getGoogleUserLoginFromJwt, getRandomInt } from '@/utils';
import { JwtPayloadGoogle, ApiUserCreateRequest } from '@types';
import { useState } from 'react';

type UseCreateUserRet = {
    createUser: (jwt?: JwtPayloadGoogle) => void,
    data: CreateUserData,
    loading: boolean,
    error: any,
};

export type CreateUserData = ApiUserCreateRequest | undefined;

export const getCreateUserRequestObj = (jwt: JwtPayloadGoogle): ApiUserCreateRequest | null => {
    const auth = getGoogleUserLoginFromJwt(jwt);
    if (!jwt.sub || !auth) return null;
    return {
        authData: auth,
        sourceInfo: {
            displayName: (jwt.given_name && jwt.family_name) ? `${jwt.given_name} ${jwt.family_name}` : `User${getRandomInt(100000000)}`,
        },
    }
}

export default function useCreateUser(): UseCreateUserRet {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [data, setData] = useState<CreateUserData>();

    const createUser = (jwt?: JwtPayloadGoogle) => {

        setLoading(true);

        if (!jwt) {
            const googleJwt = getGoogleUserJwtFromStorage();
            if (googleJwt) {
                jwt = googleJwt;
            } else {
                setLoading(false);
                setData(undefined);
                setError('no google signin');
                return;
            }
        }

        const requestObj = getCreateUserRequestObj(jwt);
        if (!requestObj) {
            setLoading(false);
            setData(undefined);
            setError('no google sub');
            return;
        }

        ExpressClient.post(TRUFO_API.USER_REGISTER, requestObj)
            .then((response) => {
                const error = response?.data?.error;
                if (!error) {
                    setData(response?.data as ApiUserCreateRequest);
                    setError(undefined);
                } else {
                    setData(undefined);
                    setError(error);
                }
                setLoading(false);
            })
            .catch((err) => {
                setData(undefined);
                setError(err.toString());
                setLoading(false);
            });

    };

    return { createUser, data, loading, error };

}
import { SettingsModal } from "@/components";
import { useState } from "react";

type UseSettingsModalRet = {
    SettingsModal: React.FC<SettingsModalPublicProps>,
    openSettingsModal: () => void,
    closeSettingsModal: () => void,
}

interface SettingsModalPublicProps {
    compact?: boolean;
    onSuccessfulSave?: () => void;
}

export default function useSettingsModal(): UseSettingsModalRet {
    const [open, setOpen] = useState<boolean>(false);

    const SettingsModalPublic: React.FC<SettingsModalPublicProps> = ({ compact, onSuccessfulSave }) => {
        return <SettingsModal open={open} setOpen={setOpen} compact={compact} onSuccessfulSave={onSuccessfulSave} />
    }
    return {
        SettingsModal: SettingsModalPublic,
        openSettingsModal: () => setOpen(true),
        closeSettingsModal: () => setOpen(false)
    };
}

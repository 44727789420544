import { TrufoButton, TrufoImage } from '@components';
import { Box, Link } from '@mui/material';
import { useRef } from 'react';
import { useMemoGetUrlFromFile } from '@utils';
import { MAIN_APP_ROUTE } from '@/app-routes';

type DownloadPageProps = {
    watermarkedImage: File,
    fileName?: string,
}

export default function DownloadPage({ watermarkedImage, fileName }: DownloadPageProps) {

    const downloadLink = useRef<HTMLAnchorElement>(null);

    return (
        <Box
            height='100%' width='100%'
            display='flex' flexDirection='column'
            justifyContent='center' alignItems='center'
            gap='20px'
        >
            <TrufoImage
                imageFile={watermarkedImage}
                maxHeight='80%' maxWidth='80%'
                centered
            />
            <Box
                display='flex' gap='20px'
            >
                <TrufoButton
                    onClick={() => downloadLink.current?.click()}
                    colorVariant='light'
                >
                    Download
                </TrufoButton>
                <TrufoButton
                    href={MAIN_APP_ROUTE}
                    colorVariant='light'
                >
                    Watermark New Image
                </TrufoButton>
                <Link
                    ref={downloadLink}
                    href={useMemoGetUrlFromFile(watermarkedImage)}
                    download
                    display='none'
                />
            </Box>
        </Box>
    )

}
import { Box } from "@mui/material"
import { DescriptionTextBlock, LpButton, LpCard, SectionWrapper } from "./../components"
import { ResponsiveSpacing } from "@/components";
import { ReactComponent as ArchivesIllustration } from '@assets/illustrations/industry-archives.svg';
import { ReactComponent as ArtistsIllustration } from '@assets/illustrations/industry-artists.svg';
import { ReactComponent as ContentCreatorsIllustration } from '@assets/illustrations/industry-contentcreators.svg';
import { ReactComponent as GenAiIllustration } from '@assets/illustrations/industry-genai.svg';
import { ReactComponent as HardwareIllustration } from '@assets/illustrations/industry-hardware.svg';
import { ReactComponent as NewsMediaIllustration } from '@assets/illustrations/industry-newsmedia.svg';
import { SectionProps } from "../types-and-constants";
import { DEMO_REQUEST_LINK } from "@/app-routes";

export type SectionUseCasesProps = SectionProps;

export const SectionUseCases = (props: SectionUseCasesProps) => {
    const { extraCompact } = props;
    return (
        <SectionWrapper
            {...props}
            flexDirection={!extraCompact ? 'row' : 'column-reverse'}
            gap={ResponsiveSpacing.space6}
            colorVariant="gray"
        >
            <Box
                flex={2}
                display='flex' flexDirection='column'
                gap={ResponsiveSpacing.space0}
            >
                <Box
                    display='flex' flexDirection='row' gap={ResponsiveSpacing.space0}
                >
                    <LpCard
                        variant='square' header='Gen AI' Illustration={GenAiIllustration}
                        description='Gen AI'
                    />
                    <LpCard
                        variant='square' header='News & Media' Illustration={NewsMediaIllustration}
                        description='News & Media'
                    />
                    <LpCard
                        variant='square' header='Content Creators' Illustration={ContentCreatorsIllustration}
                        description='Content Creators'
                    />
                </Box>
                <Box
                    display='flex' flexDirection='row' gap={ResponsiveSpacing.space0}
                >
                    <LpCard
                        variant='square' header='Artists & Photographers' Illustration={ArtistsIllustration}
                        description='Artists & Photographers'
                    />
                    <LpCard
                        variant='square' header='Libraries & Archives' Illustration={ArchivesIllustration}
                        description='Libraries & Archives'
                    />
                    <LpCard
                        variant='square' header='Device Manufacturers' Illustration={HardwareIllustration}
                        description='Device Manufacturers'
                    />
                </Box>
            </Box>
            <Box
                flex={1} display='flex' flexDirection='column'
                gap={!extraCompact ? ResponsiveSpacing.space6 : ResponsiveSpacing.space4}
            >
                <DescriptionTextBlock
                    headerBottom="Build a solution that suits your use case"
                    description="Trusted content labels will soon be used across industries. Explore ways in which content labels can benefit your use case."
                    alignSelf='center'
                    enlarged={extraCompact}
                />
                <Box
                    display='flex'
                // justifyContent={!extraCompact ? 'start' : 'end'}
                >
                    <LpButton colorVariant='accentDark' enlarged={extraCompact} href={DEMO_REQUEST_LINK} newtab>Request a Demo</LpButton>
                </Box>
            </Box>
        </SectionWrapper>
    )
}
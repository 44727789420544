import { Box } from '@mui/material';
import { CONTENT_LABEL_COPY, ENCODING_SETTINGS_COPY, UiEncodingSettings } from '@types';
import { ContentDetailBox, contentDetailHeaderStyle, contentDetailValueStyle } from './content-display';
import { useGetUser } from '@/hooks';
import { getGoogleUserLoginFromStorage } from '@/utils';
import { useEffect } from 'react';
import { getContentLicenseTags } from '../utils';
import TagDisplay from './tag-display';
import { ResponsiveFontSizes, ResponsiveSpacing } from '@/components';

type ContentDisplayEncodeProps = {
    encodingSettings: UiEncodingSettings,
}

export default function ContentDisplayEncode({ encodingSettings }: ContentDisplayEncodeProps) {

    const es = encodingSettings;
    const getContentLicense = () => {
        if (es?.contentLicense?.selected && es?.doNotTrain) {
            return `XA.${es?.contentLicense?.val}`;
        } else if (es?.contentLicense?.selected) {
            return es?.contentLicense?.val;
        } else if (es?.doNotTrain) {
            return 'XA';
        } else {
            return undefined;
        }
    }
    const contentLicense = getContentLicense();

    const { getUser, data: userData, loading: userLoading, error: userError } = useGetUser();
    useEffect(() => { const userLogin = getGoogleUserLoginFromStorage(); userLogin && getUser(userLogin); }, [])

    return (
        <>
            {es?.includeUserDetails &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.user}:</Box>
                    <Box {...contentDetailValueStyle}>{userData?.sourceInfo?.displayName || 'anonymous user'}</Box>
                    {userData?.sourceInfo?.description &&
                        <Box {...contentDetailValueStyle} fontSize={ResponsiveFontSizes.body2}>
                            {userData?.sourceInfo?.description}
                        </Box>
                    }
                    {userData?.sourceInfo?.sourceLinks &&
                        <Box {...contentDetailValueStyle} fontSize={ResponsiveFontSizes.body2}>
                            {userData?.sourceInfo?.sourceLinks}
                        </Box>
                    }
                </ContentDetailBox >
            }

            <ContentDetailBox>
                <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.date}:</Box>
                <Box {...contentDetailValueStyle}>{new Date().toLocaleString()}</Box>
            </ContentDetailBox>

            {es?.contentLabel?.selected &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.contentLabel}:</Box>
                    <Box {...contentDetailValueStyle}>{es?.contentLabel?.val ? CONTENT_LABEL_COPY[es?.contentLabel?.val] : ''}</Box>
                </ContentDetailBox>
            }

            {contentLicense &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.contentLicense}:</Box>
                    <Box {...contentDetailValueStyle}>{contentLicense}</Box>
                    <Box marginTop={ResponsiveSpacing.space1} marginBottom={ResponsiveSpacing.space1}>
                        <TagDisplay tags={getContentLicenseTags(contentLicense)} />
                    </Box>
                </ContentDetailBox>
            }

            {es?.name?.selected &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.name}:</Box>
                    <Box {...contentDetailValueStyle}>{es?.name?.val}</Box>
                </ContentDetailBox>
            }

            {es?.description?.selected &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.description}:</Box>
                    <Box {...contentDetailValueStyle}>{es?.description?.val}</Box>
                </ContentDetailBox>
            }
        </>

    );
}
import { Box } from "@mui/material"
import { LpButton, LpText, SectionWrapper } from "./../components"
import c2pa_logo from '@assets/logo_c2pa.png'
import watermarking_illustration from '@assets/illustrations/free-watermarking.svg';
import tagging_illustration from '@assets/illustrations/free-tagging.svg';
import { Colors, ResponsiveBorderWeights, responsiveSize, ResponsiveSpacing } from "@/components";
import { getBoxMarginLeftRight, SectionProps, useBoxSize } from "../types-and-constants";
import { GET_STARTED_HREF, PRICING_ROUTE } from "@/app-routes";
import { useRef } from "react";

export type SectionFreeProductsProps = SectionProps;

export const SectionFreeProducts = (props: SectionFreeProductsProps) => {
    const contentBoxRef = useRef<HTMLElement>(null);
    const { widthPx: contentBoxWidthPx } = useBoxSize(contentBoxRef);
    const extraCompact = contentBoxWidthPx < 300;
    const getProductBox = (labelTop?: string, labelBottom?: string, image?: string, imageWidth?: string) => (
        <Box
            border={`${ResponsiveBorderWeights.weight1} solid white`}
            borderRadius={ResponsiveSpacing.space0}
            flex={1} minWidth='0px'
            height={responsiveSize(28)}
            padding={ResponsiveSpacing.space5} boxSizing='border-box'
            display='flex' flexDirection='column' justifyContent='center' alignItems='center'
            gap={ResponsiveSpacing.space4}
        >
            {image && <Box component='img' src={image} alt={image} height='auto' width={imageWidth || responsiveSize(20)} />}
            <Box display='flex' flexDirection='column' gap={ResponsiveSpacing.space0}>
                {labelTop && <LpText variant='subheaderLight'>{labelTop}</LpText>}
                {labelBottom && <LpText variant='subheaderLargeLight' lineHeight={0.9}>{labelBottom}</LpText>}
            </Box>
        </Box>
    )

    return (
        <SectionWrapper {...props} outerBoxProps={{ paddingTop: 0, paddingBottom: 0 }}>
            <Box width='100%' ref={contentBoxRef}>
                <SectionWrapper
                    ref={contentBoxRef}
                    colorVariant="accentDark"
                    {...props}
                    gap={ResponsiveSpacing.space6}
                    marginLeftRight={getBoxMarginLeftRight(contentBoxWidthPx, 900, ResponsiveSpacing.space6, ResponsiveSpacing.space5, extraCompact)}
                    textAlign='center'
                >
                    <LpText variant='headerBold'>
                        As part of our commitment to content authenticity, essential features are free:
                    </LpText>
                    <Box
                        display='flex' flexDirection={extraCompact ? 'column' : 'row'}
                        width='100%' boxSizing='border-box'
                        gap={ResponsiveSpacing.space4}
                    >
                        {getProductBox('One-Click', 'C2PA Integration', c2pa_logo, responsiveSize(20))}
                        {getProductBox('Durable', 'Invisible Watermarking', watermarking_illustration, responsiveSize(9))}
                        {getProductBox('"AI Generated"', 'Compliance Tagging', tagging_illustration, responsiveSize(12))}
                    </Box>
                    <Box
                        display='flex'
                        gap={ResponsiveSpacing.space4} justifyContent='center'
                    >
                        <LpButton colorVariant='light' sx={{ color: Colors.accentDarkLp }} href={GET_STARTED_HREF}>Get Started</LpButton>
                        <LpButton colorVariant='light' typeVariant='secondary' href={PRICING_ROUTE}>Premium Features</LpButton>
                    </Box>
                </SectionWrapper>
            </Box>
        </SectionWrapper >
    )
}
// Account Auth Types
import { JwtPayload } from "jwt-decode";
export interface JwtPayloadGoogleExtras {
    azp?: string[] | string;
    email?: string;
    email_verified?: boolean;
    family_name?: string;
    given_name?: string;
    hd?: string;
    locale?: string;
    name?: string;
}
export type JwtPayloadGoogle = JwtPayload & JwtPayloadGoogleExtras;

// File Upload Types
export enum FileType {
    JPG = 'image/jpg',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    WEBP = 'image/webp',
    GIF = 'image/gif',
    TIFF = 'image/tiff',
    BMP = 'image/bmp',
    HEIC = 'image/heic',
    HEIF = 'image/heif',
    AVIF = 'image/avif'
}

export enum Method {
    ENCODE = 'encode',
    DECODE = 'decode',
}

export interface ImageAspectRatio {
    width: number,
    height: number,
}
export type ImageInfo = {
    name: string,
    type: FileType | string,
} & ImageAspectRatio;

export interface ImageBase64 {
    base64: string,
    type: FileType,
}

export const isTypeofImageBase64 = (variable: any): boolean => {
    return 'base64' in variable && 'type' in variable;
}

// API Types

export enum ApiAvReturnMimeType {
    JPG = 'image/jpeg',
    PNG = 'image/png',
    MP4 = 'video/mp4',
}

export enum ApiAvFormat {
    IMAGE = 'image/bytes',
    VIDEO = 'video/bytes',
}

export type ApiWmSettings = { [key in ApiWmType]?: any; };

export enum ApiWmType {
    TESTING = "trufo.testing",

    G0_META = "trufo.gen0.meta",
    G0_IMAGE_MED = "trufo.gen0.image-med",
    G0_IMAGE_VIS = "trufo.gen0.image-vis",
    G0_VIDEO = "trufo.gen0.video",

    G1_META_C2PA = "trufo.gen1.meta-c2pa",
    G1_IMAGE = "trufo.gen1.image",
    G1_IMAGE_LITE = "trufo.gen1.image-lite",
    G1_STAMP = "trufo.gen1.stamp",
}

export enum UiWmSettingsInvisible {
    NONE = 'none',
    LIGHT = 'mvp-light',
    HEAVY = 'mvp-heavy',
}

export enum UiWmSettingsVisible {
    NONE = 'none',
    INSIDE = 'INSIDE',
    OUTSIDE = 'OUTSIDE',
}
export enum UiWmSettingsMetadata {
    NONE = 'none',
    REPLACE = 'false',
    APPEND = 'true',
}

export enum ApiWmContentLabel {
    NONE = 'none',
    AI = 'ai-generated',
    CAMERA = 'camera-captured',
    HUMAN = 'human-created',
};
export const CONTENT_LABEL_COPY: Record<ApiWmContentLabel, string> = {
    [ApiWmContentLabel.NONE]: 'None',
    [ApiWmContentLabel.AI]: 'AI Generated',
    [ApiWmContentLabel.CAMERA]: 'Camera Captured',
    [ApiWmContentLabel.HUMAN]: 'Human Created',
}

export enum ApiContentLicense {
    NONE = 'none',
    CC_ZERO = 'CC.0',
    CC = 'CC.BY',
    CC_SA = 'CC.BY-SA',
    CC_NC = 'CC.BY-NC',
    CC_NC_SA = 'CC.BY-NC-SA',
    CC_ND = 'CC.BY-ND',
    CC_NC_ND = 'CC.BY-NC-ND',
    XA = 'XA',
    XA_CC_ZERO = 'XA.CC.0',
    XA_CC = 'XA.CC.BY',
    XA_CC_SA = 'XA.CC.BY-SA',
    XA_CC_NC = 'XA.CC.BY-NC',
    XA_CC_NC_SA = 'XA.CC.BY-NC-SA',
    XA_CC_ND = 'XA.CC.BY-ND',
    XA_CC_NC_ND = 'XA.CC.BY-NC-ND',
}

export type ApiContentLicenseCC = Extract<ApiContentLicense,
    ApiContentLicense.CC_ZERO | ApiContentLicense.CC | ApiContentLicense.CC_SA | ApiContentLicense.CC_NC
    | ApiContentLicense.CC_NC_SA | ApiContentLicense.CC_ND | ApiContentLicense.CC_NC_ND>

export interface ApiContentInfo {
    displayName?: string, // max length 70
    description?: string, // max length 700
    contentLinks?: string[], // ui should check for specific whitelisted URLs
    contentLabel?: ApiWmContentLabel,
    contentLicense?: ApiContentLicense,
}
export const MAX_LENGTH_CONTENT_NAME = 70;
export const MAX_LENGTH_CONTENT_DESCRIPTION = 700;

export interface ApiSourceInfo {
    displayName?: string, // max 70 char
    description?: string, // max 700 char
    sourceLinks?: [string], // ui should check for url format
}
export const MAX_LENGTH_SOURCE_DISPLAY_NAME = 70;
export const MAX_LENGTH_SOURCE_DESCRIPTION = 700;

// ENCODING

export interface ApiEncodingRequest {
    authData: ApiUserLogin,
    avData: string, // b64-utf8 of PNG/JPG
    avReturnMimeType: ApiAvReturnMimeType,
    avFormat: ApiAvFormat,
    wmSettings: ApiWmSettings,
    contentInfo: ApiContentInfo,
    isPreview?: boolean,
}

export interface UiEncodingSettings {
    includeUserDetails?: boolean,
    name?: {
        selected?: boolean,
        val?: string // max length 70
    },
    description?: {
        selected?: boolean,
        val?: string // max length 700
    },
    contentLabel?: {
        selected?: boolean,
        val?: ApiWmContentLabel,
    }
    contentLicense?: {
        selected?: boolean,
        val?: ApiContentLicenseCC,
    },
    doNotTrain?: boolean,
    visible?: {
        selected?: boolean,
        val?: UiWmSettingsVisible,
    },
    invisible?: {
        selected?: boolean,
        val?: UiWmSettingsInvisible,
    },
    metadata?: {
        selected?: boolean,
        val?: UiWmSettingsMetadata,
    },
}
export const defaultEncodingSettings: UiEncodingSettings = {
    includeUserDetails: true,
    name: { // This default will be overridden in the encoding page; sets this to the first 70 characters of filename
        selected: true,
        val: '',
    },
    description: {
        selected: false,
        val: '',
    },
    contentLabel: {
        selected: false,
        val: ApiWmContentLabel.CAMERA,
    },
    contentLicense: {
        selected: false,
        val: ApiContentLicense.CC_ZERO,
    },
    doNotTrain: true,
    visible: {
        selected: false,
        val: UiWmSettingsVisible.OUTSIDE,
    },
    invisible: {
        selected: true,
        val: UiWmSettingsInvisible.HEAVY,
    },
    metadata: {
        selected: true,
        val: UiWmSettingsMetadata.APPEND,
    },
}
export const ENCODING_SETTINGS_COPY: Record<keyof UiEncodingSettings | 'date' | 'user', string> = {
    includeUserDetails: 'Include User Details',
    name: 'Name',
    description: 'Description',
    contentLabel: 'Origination Claim',
    contentLicense: 'Copyright License Claim',
    doNotTrain: 'Do Not Train',
    visible: 'Visible',
    invisible: 'Invisible',
    metadata: 'Metadata',
    date: 'Date of signature',
    user: 'User of signature',
}

// DECODING

export interface ApiDecodingRequest {
    avData: string, // b64-utf8 of PNG/JPG
    avFormat: ApiAvFormat;
}

export interface ApiDecodingResponse {
    contentIdentification?: {
        creationTime?: number,
        contentType?: string,
        contentInfo?: ApiContentInfo,
        sourceType?: string,
        sourceInfo?: ApiSourceInfo,
    },
    contentVerification?: {
        bytesMatch?: boolean, // don't really need this; reflected in reprScoreMode
        imageMatch?: boolean, // don't really need this; reflected in reprScoreMode
        reprScore?: number,
        reprMode?: string,
        diffFrame?: string,
        diffMode?: string,
    },
    error?: boolean,
    errorDetails?: string,
    // UI parsing
    diffImage?: File | ImageBase64,
}


// USER

export const MAX_LENGTH_USER_NAME = 70;
export const MAX_LENGTH_USER_PASSWORD = 70;

export enum ApiUserAccountType {
    GOOGLE = 't1-web-google',
    TRUFO = 't1-web-trufo',
}
export interface ApiUserLoginTrufo {
    password: string, // max length 70
    username: string, // max length 70
}
export interface ApiUserLoginGoogle {
    sub: string,
    token?: string, // may go in header later // technically mandatory
    data?: {
        email?: string,
        firstName?: string,
        lastName?: string,
    },
}
export type ApiUserLogin = { accountType: ApiUserAccountType } & (ApiUserLoginGoogle | ApiUserLoginTrufo);

export type ApiUserCreateRequest = {
    authData: ApiUserLogin,
    sourceInfo?: ApiSourceInfo,
    accountDetails?: Object, // ignore this for now
}

export type ApiUserRequestType = 'AUTH' | 'FETCH' | 'UPDATE' | 'SIGN';

export type ApiUserRequest = {
    authData: ApiUserLogin,
    action: ApiUserRequestType,
    UPDATE?: {
        newPassword?: string, // only applies to Trufo account
        newSourceInfo?: ApiSourceInfo,
        newAccountDetails?: Object,
    },
    SIGN?: {
        message: string,
    },
}

export interface ApiCreateUserReturn {
    error?: boolean,
    errorDetails?: boolean,
    aid?: string,
    sid?: number,
}

export interface ApiFetchUserReturn {
    error?: boolean,
    errorDetails?: boolean,
    accountType?: string,
    accountInfo?: any,
    accountData?: any, // non user editable
    sourceType?: string,
    sourceInfo?: ApiSourceInfo,
    sourceData?: any, // non user editable
}

export interface UiUserSettings {
    displayName?: string,
    description?: string,
    website?: string,
}
export const USER_SETTINGS_COPY: Record<keyof UiUserSettings, string> = {
    displayName: 'Display Name',
    description: 'Description',
    website: 'Website',
}
import { LinksSection, LinksSectionLinkRow, LpText, LpWrapper, SectionWrapper } from "../components"
import { useSectionProps } from "../types-and-constants";
import { Box } from "@mui/material";
import { ResponsiveSpacing, TrufoLink } from "@/components";
import { AISIC_TF21_PRESENTATION_SLIDES_ROUTE, CPCPS_ROUTE, ERASING_THE_INVISIBLE_LINK, PARTNERSHIP_EMAIL, PEOPLE_ROUTE, WHITEPAPER_ROUTE } from "@/app-routes";

export interface ResearchPageProps {
}

export const ResearchPage = ({ }: ResearchPageProps) => {

    const featuredLinks: LinksSectionLinkRow[] = [
        {
            title: 'Solutions to the Content Authenticity Problem',
            date: new Date('2024-08-26'),
            source: 'Whitepaper',
            url: WHITEPAPER_ROUTE,
        },
        {
            title: 'Invited Talk: Towards a Robust Content Authenticity Infrastructure',
            date: new Date('2024-09-11'),
            source: 'AISIC',
            url: AISIC_TF21_PRESENTATION_SLIDES_ROUTE,
        },
        {
            title: 'Sponsored Event: Erasing the Invisible Competition',
            date: new Date('2024-07-31'),
            source: 'University of Maryland',
            url: ERASING_THE_INVISIBLE_LINK,
        }
        // {
        //     title: '(Hash Title Here)',
        //     date: new Date('PREPRINT'),
        //     source: 'Jiaxin Guan, Bill Huang',
        //     url: 'https://trufo.ai/404',
        // },
    ]
    const blogLinks: LinksSectionLinkRow[] = [
        {
            title: 'Differential Analysis. A new way to authenticate content.',
            date: new Date('2024-10-22'),
            source: 'Blog',
            url: 'https://medium.com/p/14cf03bed707',
        },
        {
            title: 'How Good are Invisible Watermarks Now?',
            date: new Date('2024-10-06'),
            source: 'Blog',
            url: 'https://medium.com/p/d98b78e6f808',
        },
        {
            title: 'Benchmarking Watermarking',
            date: new Date('2024-02-19'),
            source: 'Blog',
            url: 'https://medium.com/p/436cbf1bd9ca',
        },
        {
            title: "Why Watermark (Everything)? A cryptographer's perspective.",
            date: new Date('2024-02-19'),
            source: 'Blog',
            url: 'https://medium.com/p/e7b9df714bf7',
        },
    ]
    const otherLinks: LinksSectionLinkRow[] = [
        {
            title: 'Certification Practice Statement, Version 1.0',
            date: new Date('2024-10-23'),
            source: 'Legal',
            url: CPCPS_ROUTE,
        },
        {
            title: 'Release Notes',
            date: new Date('2024-08-12'),
            source: 'Blog',
            url: 'https://medium.com/p/730f9f2691e1',
        },
    ]


    const sectionProps = useSectionProps();
    return (
        <LpWrapper>
            <SectionWrapper {...sectionProps}>
                <Box alignSelf='start' marginBottom={ResponsiveSpacing.space5}><LpText variant='header'>Research</LpText></Box>
                <LpText width='100%' variant='subheaderLight'>
                    <Box width='100%' display='flex' flexDirection='column' gap={ResponsiveSpacing.space3}>
                        <Box>Trufo is a research-heavy team with a mix of in-house researchers and collaborations with research teams at various academic institutions.</Box>
                        <Box>Our next R&D appearance will be at NeurIPS 2024. Come find us on Dec 15th 2024 at the <TrufoLink sx={{ marginTop: '-5px' }} href={ERASING_THE_INVISIBLE_LINK}>Erasing the Invisible Competition</TrufoLink>!</Box>
                        <Box>See our <TrufoLink sx={{ marginTop: '-5px' }} href={PEOPLE_ROUTE}>people page</TrufoLink> for careers information or email us at <TrufoLink sx={{ marginTop: '-5px' }} href={PARTNERSHIP_EMAIL}>collabs@trufo.ai</TrufoLink> if you're working on something you think we'd be interested in.</Box>
                    </Box>
                </LpText>
            </SectionWrapper>
            <SectionWrapper {...sectionProps} gap={ResponsiveSpacing.space5} outerBoxProps={{ paddingTop: 0 }}>
                <LinksSection
                    links={featuredLinks}
                    sectionHeaderBottom="Featured"
                />
                <LinksSection
                    links={blogLinks}
                    sectionHeaderBottom="Blogs"
                />
                <LinksSection
                    links={otherLinks}
                    sectionHeaderBottom="Other"
                />
            </SectionWrapper>
        </LpWrapper >
    );
}
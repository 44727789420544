import { BANNER_HEIGHT_PX, NAVBAR_HEIGHT_PX, ResponsiveSpacing, SHOW_BANNER } from "@/components";
import { useEffect, useState } from "react";

export enum AnimStage {
    INITIAL = 1,
    WMDETAILS = 2,
    WMMERGING = 3,
    WMMERGED = 4,
    // WMBITS = 2,
    // WMDETAILS = 3,
    // WMINITIAL2 = 4,
    // WMMERGED = 5,
}
export const NUM_ANIM_STAGE = Object.keys(AnimStage).length;

export const MIN_ANIM_SIZE_PX = 160;
export const MAX_ANIM_SIZE_PX = 1000;
export const MAX_CONTENT_WIDTH_PX = 1200;
export const MAX_CONTENT_HEIGHT_PX = 800;
export const SCROLL_DIV_HEIGHT = '200vh';

export const getResponsiveComponentPositions = (animSizePx: number) => {
    const m = animSizePx / 300;
    const ret = {
        top: {
            t: { initial: -50 * m, hover: -150 * m, merged: 50 * m, centered: 0, noAnim: -50 * m },
        },
        bottom: {
            t: { initial: 50 * m, exitX: 1400 * m, exitY: 2000 * m, merged: 50 * m, centered: 0 },
        },
        parent: {
            t: { appX: -180 },
            rotate: { X: 51, Z: 43 },
        },
    }
    return ret;
};

export const SCROLL_POSITIONS: Record<AnimStage | 'scroll', number> = {
    [AnimStage.INITIAL]: 0.01,
    [AnimStage.WMDETAILS]: 0.2,
    [AnimStage.WMMERGING]: 0.3,
    [AnimStage.WMMERGED]: 0.45,
    scroll: 0.5
};

export const getPointOnLine = (pointA: number, pointB: number, ratio: number) => {
    return (pointB - pointA) * ratio + pointA;
}

export const COMPACT_VIEW_WIDTH_PX = 880;
export const EXTRA_COMPACT_VIEW_WIDTH_PX = 480;
export const EXTRA_COMPACT_MAX_PAGE_HEIGHT_PX = 750;

export const isCompact = (widthPx?: number) => {
    return widthPx ? widthPx < COMPACT_VIEW_WIDTH_PX : false;
}
export const isExtraCompact = (widthPx?: number) => {
    return widthPx ? widthPx < EXTRA_COMPACT_VIEW_WIDTH_PX : false;
}

const DEFAULT_MARGIN = ResponsiveSpacing.space6;
const DEFAULT_MARGIN_COMPACT = ResponsiveSpacing.space5;

export const getBoxMarginLeftRight = (boxWidthPx?: number, maxContentWidthPx?: number, defaultMargin?: string, compactMargin?: string, compact?: boolean) => {
    const marginLeftRight = compact ?
        (compactMargin || defaultMargin || DEFAULT_MARGIN_COMPACT) :
        `calc(max(${((boxWidthPx || 0) - (maxContentWidthPx || MAX_CONTENT_WIDTH_PX)) / 2}px, ${defaultMargin || DEFAULT_MARGIN}))`;
    return marginLeftRight;
}
export const getPageMarginLeftRight = (compact?: boolean, pageWidthPx?: number) => {
    return getBoxMarginLeftRight(pageWidthPx, MAX_CONTENT_WIDTH_PX, DEFAULT_MARGIN, DEFAULT_MARGIN_COMPACT, compact);
}
export const getExtraCompactViewPageMarginTopBottom = (pageHeightPx?: number) => {
    return `calc(max(12%, ${((pageHeightPx || 0) - EXTRA_COMPACT_MAX_PAGE_HEIGHT_PX) / 2}px))`;
}

export const getAnimSize = (width?: number, height?: number, compact?: boolean) => {
    if (!height || !width) return 0;
    const animSizeFromHeight = height * 0.55;
    const animSizeFromWidth = Math.min(width, MAX_CONTENT_WIDTH_PX) * 0.32;
    const animSize = Math.max(Math.min(animSizeFromHeight, animSizeFromWidth), MIN_ANIM_SIZE_PX);
    const animSizeCompact = Math.min(height * 0.3, width * 0.66); // TODO: improve this
    return compact ? animSizeCompact : animSize;
}
export const getAnimRightMargin = (marginLeftRight: string, animSizePx: number) => {
    return `calc(${marginLeftRight || '0px'} + ${animSizePx * 0.16}px)`;
}

export interface SectionProps {
    marginLeftRight: string;
    compact: boolean;
    extraCompact: boolean;
    pageWidthPx: number;
    pageHeightPx: number;
    windowWidthPx: number;
    windowHeightPx: number;
    ready: boolean,
}
export type useBoxSizeRet = {
    marginLeftRight: string;
    compact: boolean;
    extraCompact: boolean;
    widthPx: number;
    heightPx: number;
    windowWidthPx: number;
    windowHeightPx: number;
    ready: boolean,
};

export const useBoxSize = (ref?: React.RefObject<HTMLElement>): useBoxSizeRet => {
    const [widthPx, setWidthPx] = useState<number>(0);
    const [heightPx, setHeightPx] = useState<number>(0);
    const [windowWidthPx, setWindowWidthPx] = useState<number>(0);
    const [windowHeightPx, setPageHeightPx] = useState<number>(0);
    const [ready, setReady] = useState<boolean>(false);
    // if no ref passed in, we track the size of document.body instead
    const refToTrack = ref ? ref.current : document?.body;
    useEffect(() => {
        if (!refToTrack) return;
        const resizeObserver = new ResizeObserver((elementArr) => {
            // Whenever the size of the element changes:
            const widthPx = refToTrack?.offsetWidth;
            const heightPx = refToTrack?.offsetHeight;
            const windowWidthPx = window?.innerWidth;
            const windowHeightPx = window?.innerHeight;
            widthPx && setWidthPx(widthPx);
            heightPx && setHeightPx(heightPx);
            windowWidthPx && setWindowWidthPx(windowWidthPx);
            windowHeightPx && setPageHeightPx(windowHeightPx);
            if (widthPx && heightPx) setReady(true);
        });
        resizeObserver.observe(refToTrack);
        return () => resizeObserver.disconnect(); // clean up 
    }, [ref, refToTrack]);

    const compact = isCompact(widthPx);
    const extraCompact = isExtraCompact(widthPx);
    const marginLeftRight = getPageMarginLeftRight(compact, widthPx);
    return { widthPx, heightPx, ready, compact, extraCompact, marginLeftRight, windowHeightPx, windowWidthPx }
}
export const useWindowSize = (): useBoxSizeRet => {
    return useBoxSize();
}
export const useSectionProps = (): SectionProps => {
    const { marginLeftRight, compact, extraCompact, widthPx, heightPx, windowHeightPx, windowWidthPx, ready } = useWindowSize();
    return { marginLeftRight, compact, extraCompact, pageWidthPx: widthPx, pageHeightPx: heightPx, windowHeightPx, windowWidthPx, ready };
}

export const getPageTopOffsetString = (showNavbar?: boolean, showBanner?: boolean) => {
    if (showNavbar === undefined || showNavbar === null) showNavbar = true;
    if (showBanner === undefined || showBanner === null) showBanner = SHOW_BANNER;
    return (`calc(${showNavbar ? NAVBAR_HEIGHT_PX : 0}px + ${showBanner ? BANNER_HEIGHT_PX : 0}px)`)
}
export const getPageTopOffsetNumber = (showNavbar?: boolean, showBanner?: boolean) => {
    if (showNavbar === undefined || showNavbar === null) showNavbar = true;
    if (showBanner === undefined || showBanner === null) showBanner = SHOW_BANNER;
    return (showNavbar ? NAVBAR_HEIGHT_PX : 0) + (showBanner ? BANNER_HEIGHT_PX : 0);
}

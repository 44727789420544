import { ExpressClient, TRUFO_API } from '@/http-clients';
import { getGoogleUserLoginFromStorage } from '@/utils';
import { ApiFetchUserReturn, ApiUserLogin, ApiUserRequest } from '@types';
import { useMemo, useState } from 'react';

type UseGetUserRet = {
    getUser: (login?: ApiUserLogin, onFetchSuccess?: () => void, onFetchComplete?: () => void) => void,
    data: GetUserData;
    loading: boolean,
    error: any,
};

export type GetUserData = ApiFetchUserReturn | undefined;

export const getGetUserRequestObj = (login: ApiUserLogin | null): ApiUserRequest | undefined => {

    if (!login) return undefined;

    const requestObj: ApiUserRequest = {
        authData: login,
        action: 'FETCH',
    }

    return requestObj;
}

export default function useGetUser(): UseGetUserRet {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [data, setData] = useState<GetUserData>();

    const getUser = useMemo(() => (login?: ApiUserLogin, onFetchSuccess?: () => void, onFetchComplete?: () => void) => {

        setLoading(true);

        if (!login) {
            const googleLogin = getGoogleUserLoginFromStorage();
            if (googleLogin) {
                login = googleLogin;
            } else {
                setData(undefined);
                setError('no goole signin');
                setLoading(false);
                return;
            }
        }

        const requestObj = getGetUserRequestObj(login);
        if (!requestObj) {
            setData(undefined);
            setError('no google signin');
            setLoading(false);
            return;
        }

        ExpressClient.post(TRUFO_API.USER_ACCOUNT, requestObj)
            .then((response) => {
                const error = response?.data?.error;
                if (!error) {
                    setData(response?.data as GetUserData);
                    setError(undefined);
                    onFetchSuccess && onFetchSuccess();
                } else {
                    setError(error);
                }
                setLoading(false);
                onFetchComplete && onFetchComplete();
            })
            .catch((err) => {
                setData(undefined);
                setError(err.toString());
                setLoading(false);
                onFetchComplete && onFetchComplete();
            });

    }, [setError, setLoading, setData]);

    return { getUser, data, loading, error };

}
import { Checkbox, CheckboxProps } from "@mui/material";
import { Colors, ZIndexes } from "./variables";

type TrufoCheckboxColorVariant = 'light' | 'dark' | 'accentLight' | 'accentDark';

type TrufoCheckboxProps = CheckboxProps & {
    colorVariant?: TrufoCheckboxColorVariant
}

export default function TrufoCheckbox({ colorVariant, ...muiProps }: TrufoCheckboxProps) {

    if (!colorVariant) { colorVariant = 'dark' };

    const variantStyles = {
        dark: {
            checked: Colors.dark,
            hover: Colors.dark05,
        },
        light: {
            checked: Colors.light,
            hover: Colors.light05,
        },
        accentDark: {
            checked: Colors.accentDark,
            hover: Colors.accentDark05,
        },
        accentLight: {
            checked: Colors.accentLight,
            hover: Colors.accentLight05,
        },
    }

    return (
        <Checkbox
            {...muiProps}
            sx={{
                zIndex: ZIndexes.clickable,
                '&.Mui-checked': { color: variantStyles[colorVariant].checked, },
                '&:hover': { bgcolor: variantStyles[colorVariant].hover, },
                '&.Mui-disabled': { color: Colors.darkGray, },
                ...muiProps?.sx,
            }}
        />
    );
}
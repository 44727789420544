import { getFontSize, LpButton, LpText, LpWrapper, SectionWrapper } from "../components"
import { useSectionProps } from "../types-and-constants";
import { Box } from "@mui/material";
import { Colors, FontWeights, ResponsiveSpacing } from "@/components";
import { PricingPlans } from "./pricing-plans";
import { DEMO_REQUEST_LINK, LOGIN_ROUTE } from "@/app-routes";
import { PricingFeatureChart } from "./pricing-feature-chart";

export interface PricingPageProps {
}

export const PricingPage = ({ }: PricingPageProps) => {

    const sectionProps = useSectionProps();
    const { pageWidthPx } = sectionProps;
    const compact = pageWidthPx < 1060;
    const extraCompact = pageWidthPx < 600;

    return (
        <LpWrapper>
            <SectionWrapper {...sectionProps} outerBoxProps={{ paddingBottom: ResponsiveSpacing.space6 }}>
                <Box
                    display='flex' flexDirection={!compact ? 'row' : 'column'}
                    alignItems='center' textAlign={!compact ? 'left' : 'center'}
                    gap={ResponsiveSpacing.space4}
                >
                    <Box flex={1} fontSize={getFontSize('headerLarge', extraCompact)} lineHeight='0.8'>Plans for any use case</Box>
                    <Box flex={1} fontSize={getFontSize('subheader', extraCompact)} lineHeight='1.3' fontWeight={FontWeights.light} textAlign={!compact ? 'left' : 'center'}>Choose the pricing model that fits your needs. The plans are flexible and scalable with size, and switching between plans is easy.</Box>
                </Box>
            </SectionWrapper>
            <SectionWrapper {...sectionProps} outerBoxProps={{ paddingTop: 0 }}>
                <PricingPlans compact={compact} extraCompact={extraCompact} sectionProps={sectionProps} />
            </SectionWrapper>
            <SectionWrapper {...sectionProps} outerBoxProps={{ paddingTop: 0 }}>
                <Box
                    display='flex' flexDirection={!extraCompact ? 'row' : 'column'}
                    gap={ResponsiveSpacing.space5} sx={{ backgroundColor: Colors.accentLightLp }}
                    padding={ResponsiveSpacing.space6} textAlign='left' fontWeight={FontWeights.light}
                >
                    <Box display='flex' flexDirection='column' gap={ResponsiveSpacing.space4}>
                        <LpText variant='headerLight' enlarged={extraCompact}>Work at a startup or non-profit?</LpText>
                        <LpText variant='body' lineHeight={1.5} enlarged={extraCompact}>Eligible non-profit organizations, venture-backed startups, and open-source developers can apply to the <span style={{ fontWeight: FontWeights.semibold }}>Trufo Authenticity Initiative</span>. We believe that content authenticity is a societal good, and want to support such efforts when possible. This program includes a limited-time allotment of free identity and content verification from the Professional plan.</LpText>
                    </Box>
                    <LpButton
                        typeVariant='secondary' shapeVariant='round'
                        extraPadding sx={{ alignSelf: !extraCompact ? 'center' : 'end', backgroundColor: Colors.lightestGray, fontWeight: FontWeights.light }}
                        href={DEMO_REQUEST_LINK} enlarged={extraCompact}
                    >
                        Learn More
                    </LpButton>
                </Box>
            </SectionWrapper>
            <SectionWrapper {...sectionProps} outerBoxProps={{ paddingTop: 0 }}>
                <PricingFeatureChart sectionProps={sectionProps} />
            </SectionWrapper>
            <SectionWrapper {...sectionProps} colorVariant='accentLight' outerBoxProps={{ paddingTop: ResponsiveSpacing.space6, paddingBottom: ResponsiveSpacing.space6 }}>
                <Box
                    width='100%'
                    display='flex'
                    flexDirection='column' // flexDirection={!compact ? 'row' : 'column'}
                    alignItems='center' justifyContent='space-between'
                    gap={ResponsiveSpacing.space3}
                >
                    <Box
                        display='flex' flexDirection='column' gap={ResponsiveSpacing.space1}
                        textAlign='center' // textAlign={!compact ? 'left' : 'center'}
                    >
                        <LpText variant='headerLight' enlarged={extraCompact}>Ready to get started?</LpText>
                        <LpText variant='subheaderLight' enlarged={extraCompact}>Amplify the value of your content. Start exploring Trufo today.</LpText>
                    </Box>
                    <Box display='flex' gap={ResponsiveSpacing.space3}>
                        <LpButton
                            typeVariant='secondary' shapeVariant='round' enlarged={extraCompact}
                            sx={{ fontWeight: FontWeights.light, backgroundColor: Colors.lightestGray }}
                            href={LOGIN_ROUTE}
                        >
                            Try it now
                        </LpButton>
                        <LpButton
                            typeVariant='secondary' shapeVariant='round' enlarged={extraCompact}
                            sx={{ fontWeight: FontWeights.light }}
                            href={DEMO_REQUEST_LINK}
                        >
                            Get a demo
                        </LpButton>
                    </Box>

                </Box>
            </SectionWrapper>
        </LpWrapper >
    );
}
import { Box } from "@mui/material"
import { EXTRA_COMPACT_VIEW_WIDTH_PX, getAnimRightMargin, getAnimSize, getExtraCompactViewPageMarginTopBottom, getPageTopOffsetNumber, getPageTopOffsetString, MAX_CONTENT_WIDTH_PX, SCROLL_POSITIONS, SectionProps } from "../types-and-constants"
import { Colors, FontWeights, ResponsiveFontSizes, ResponsiveSpacing, SHOW_BANNER, ZIndexes } from "@/components"
import { easeInOut, motion, MotionValue, useTransform } from "framer-motion"
import WatermarkAnim from "../watermark-anim"
import { LpButton } from "./../components"
import { GET_STARTED_HREF } from "@/app-routes"

export type SectionLandingProps = {
    scrollYProgress: MotionValue<number>;
    initialAnimationComplete: boolean;
    onInitialAnimationCompleteChange: (newVal: boolean) => void;
} & SectionProps;

export const SectionLanding = ({ pageWidthPx, pageHeightPx, marginLeftRight, scrollYProgress, initialAnimationComplete, onInitialAnimationCompleteChange }: SectionLandingProps) => {
    const extraCompact = pageWidthPx < EXTRA_COMPACT_VIEW_WIDTH_PX;
    const pageHeightPxToUse = pageHeightPx;
    const pageWidthPxToUse = pageWidthPx > MAX_CONTENT_WIDTH_PX ? MAX_CONTENT_WIDTH_PX : pageWidthPx;
    const animContainerHeightPx = pageHeightPxToUse - getPageTopOffsetNumber(true, SHOW_BANNER);
    const scrollPointY = pageHeightPxToUse * -1;
    const animSizePx = getAnimSize(pageWidthPx, animContainerHeightPx, extraCompact);
    const translateY = useTransform(scrollYProgress, [0, SCROLL_POSITIONS['scroll'], 1], [0, 0, scrollPointY]);
    const marginTopBottomExtraCompact = getExtraCompactViewPageMarginTopBottom(pageHeightPxToUse);
    // const glowColor = Colors?.accentLightLp20;

    return (
        <Box
            width='100%'
        >
            {/* Main section */}
            <motion.div
                style={{
                    position: 'fixed',
                    height: `${animContainerHeightPx}px`,
                    width: '100%',
                    translateY: translateY,
                    top: getPageTopOffsetString(true, SHOW_BANNER),
                }}
            >
                {/* Landing section text */}
                <motion.div
                    style={{
                        zIndex: ZIndexes.landingPageClickable
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1] }}
                    transition={{
                        duration: 1,
                        ease: easeInOut,
                        times: [0, 1]
                    }}
                >
                    <Box
                        width={!extraCompact ? `calc(${pageWidthPxToUse * 0.48}px)` : `${pageWidthPxToUse}px`}
                        maxWidth={`${MAX_CONTENT_WIDTH_PX}px`}
                        height={`calc(100vh - ${getPageTopOffsetString(true, SHOW_BANNER)})`}
                        display='flex' flexDirection='column' gap='2em'
                        justifyContent={!extraCompact ? 'center' : 'start'}
                        textAlign='left' color={Colors.white}
                        paddingLeft={marginLeftRight}
                        paddingRight={!extraCompact ? undefined : marginLeftRight}
                        paddingTop={!extraCompact ? undefined : marginTopBottomExtraCompact}
                        boxSizing={!extraCompact ? undefined : 'border-box'}
                    >
                        <Box fontSize={!extraCompact ? ResponsiveFontSizes.header1 : ResponsiveFontSizes.header0} lineHeight='90%'>Embed Trust Into Your Digital Content</Box>
                        <Box fontSize={!extraCompact ? ResponsiveFontSizes.header6 : ResponsiveFontSizes.header4}>Label your content—images, video, and audio—with secure information that sticks. Whether you need to quickly add C2PA metadata or want to leverage the state-of-the-art in cryptography and watermarking, Trufo makes it easy and effective.</Box>
                        <LpButton
                            typeVariant='secondary'
                            colorVariant='light'
                            shapeVariant='round'
                            sizeVariant={!extraCompact ? 'large' : 'xlarge'}
                            sx={{ alignSelf: 'start', fontWeight: FontWeights.regular }}
                            href={GET_STARTED_HREF}
                        >
                            Get Started Now
                        </LpButton>
                    </Box>
                </motion.div>

                {/* Watermark animation */}
                <Box
                    position='absolute'
                    right={getAnimRightMargin(marginLeftRight, animSizePx)}
                    // top={!extraCompact ? `calc(50% - ${animSizePx / 2}px)` : `calc(100% - ${marginTopBottomExtraCompact} - ${animSizePx}px)`}
                    bottom={!extraCompact ? `calc(50% - ${animSizePx / 2}px)` : marginTopBottomExtraCompact}
                >
                    {animSizePx &&
                        <WatermarkAnim
                            animSizePx={animSizePx}
                            scrollYProgress={scrollYProgress}
                            initialAnimationComplete={initialAnimationComplete}
                            setInitialAnimationComplete={() => onInitialAnimationCompleteChange(true)}
                        />
                    }
                </Box>

                {/* "Scroll down to learn more" text (appears only after the initial animation is complete) */}
                {
                    initialAnimationComplete &&
                    <motion.div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: ResponsiveSpacing.space5,
                            fontWeight: FontWeights.light,
                            fontSize: !extraCompact ? ResponsiveFontSizes.body0 : ResponsiveFontSizes.header6,
                            width: '100%',
                            color: Colors.accentLightLp,
                            zIndex: ZIndexes.landingPageClickable,
                            // textShadow: `0 0 .5vh ${glowColor}, 0 0 .5vh ${glowColor}, 0 0 .5vh ${glowColor}, 0 0 .5vh ${glowColor}, 0 0 1vh ${glowColor}`,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: [0, 1] }}
                        transition={{
                            duration: 0.2,
                            ease: easeInOut,
                            times: [0, 1]
                        }}
                    >
                        <Box>SCROLL DOWN TO LEARN MORE</Box>
                        <Box marginTop={`-6px`}>⌄</Box>
                    </motion.div>
                }
            </motion.div>
        </Box>
    )
}
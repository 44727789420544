import { ExpressClient, TRUFO_API } from '@/http-clients';
import { useMemo, useState } from 'react';

export enum JoinNewsletterAction {
    CHECK_AND_ADD = 'SMART_ADD',
    REMOVE = 'REMOVE',
    CHECK = 'CHECK',
}

type UseJoinNewsletterRet = {
    joinNewsletter: (email: string, action?: JoinNewsletterAction) => void,
    data: JoinNewsletterData;
    loading: boolean,
    error: any,
};

export type JoinNewsletterData = {
    action?: string,
    email?: string,
    found?: boolean,
    error?: boolean,
    errorDetails?: string,
} | undefined;

export default function useJoinNewsletter(): UseJoinNewsletterRet {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [data, setData] = useState<JoinNewsletterData>();

    const joinNewsletter = useMemo(() => (email: string, action?: JoinNewsletterAction) => {

        setLoading(true);

        const requestObj = {
            action: action || JoinNewsletterAction.CHECK_AND_ADD,
            email: email,
        }

        ExpressClient.post(TRUFO_API.WAITLIST, requestObj)
            .then((response) => {
                const error = response?.data?.error;
                if (!error) {
                    setData(response?.data as JoinNewsletterData);
                    setError(undefined);
                } else {
                    setData(undefined);
                    setError(response?.data?.errorDetails || 'error');
                }
                setLoading(false);
            })
            .catch((err) => {
                setData(undefined);
                setError(err.toString());
                setLoading(false);
            });

    }, [setError, setLoading, setData]);

    return { joinNewsletter, data, loading, error };

}
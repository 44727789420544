import Box from '@mui/material/Box';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getFileUploadErrorMessage, getListOfSupportedFileTypesStr, UploadError, VALID_FILE_TYPES } from './image-upload-utils';
import { useRef } from 'react';
import { Colors, FontSizes } from '@components';
import { ImageInfo, Method } from '@/types';

interface ImageUploadButtonProps {
    onFileChange: (newFile: File) => void;
    error?: UploadError;
    info?: ImageInfo;
    disableEncode?: boolean;
}

export default function ImageUploadButton({ onFileChange, error, info, disableEncode }: ImageUploadButtonProps) {

    const uploaderRef = useRef<HTMLInputElement>(null);

    return (
        <>
            {/* Invisible image upload processor for button-triggered  */}
            <Box display='none'>
                <input
                    type="file" ref={uploaderRef}
                    accept={VALID_FILE_TYPES[Method.ENCODE].join(', ')} // accept="image/*"
                    onChange={(e) => {
                        const files = e.target.files;
                        files && files.length && onFileChange(files[0]);
                    }}
                />
            </Box>
            <Box
                height='100%' width='100%'
                display='flex' flexDirection='column' justifyContent='center' alignItems='center'
                gap='30px' color={Colors.dark}
                sx={{
                    border: 1, borderRadius: '10px',
                    cursor: 'pointer', borderStyle: 'dashed',
                    '&:hover': {
                        backgroundColor: Colors.light50, transitionDuration: '0.2s'
                    },
                }}
                onClick={() => uploaderRef.current?.click()}
            >
                <>
                    <Box>
                        <UploadFileIcon sx={{ fontSize: '80px' }} />
                    </Box>

                    <Box>
                        <Box fontSize={FontSizes.header3} fontWeight='bold'>
                            {!disableEncode ? 'Drag or upload an image to encode or decode' : 'Drag or upload an image to decode'}
                        </Box>
                        {disableEncode &&
                            <Box marginBottom='10px' fontWeight='bold'>
                                <Box>(sign in to encode)</Box>
                            </Box>
                        }

                        {error === undefined &&
                            <Box>
                                <Box>supported formats: {getListOfSupportedFileTypesStr(Method.ENCODE).toLowerCase()}</Box>
                            </Box>
                        }
                        {error &&
                            <Box color={Colors.red}>{getFileUploadErrorMessage(error, info, Method.ENCODE)}</Box>
                        }
                    </Box>
                </>
            </Box >
        </>
    );
}
import { Colors, FontSizes, TrufoButton, TrufoModal, TrufoTextField } from "@components";
import { Box, BoxProps, IconButton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getApiSourceInfo, getUiUserSettings, getGoogleUserLoginFromStorage } from "@/utils";
import { MAX_LENGTH_SOURCE_DESCRIPTION, MAX_LENGTH_SOURCE_DISPLAY_NAME, USER_SETTINGS_COPY, UiUserSettings } from "@/types";
import CloseIcon from '@mui/icons-material/Close';
import { useGetUser, useUpdateUser } from "@/hooks";

type SettingsTextInputProps = {
    variant: keyof UiUserSettings,
    value: string,
    loading: boolean,
    compact?: boolean,
    onValChange: (newVal: string) => void,
} & BoxProps;
const settingsFields: (keyof UiUserSettings)[] = ['displayName', 'description', 'website'];
function SettingsTextInput({ variant, value, loading, compact, onValChange, children, ...muiProps }: React.PropsWithChildren<SettingsTextInputProps>) {

    const MAX_LENGTHS: Record<string, number> = {
        displayName: MAX_LENGTH_SOURCE_DISPLAY_NAME,
        description: MAX_LENGTH_SOURCE_DESCRIPTION,
    }

    return (
        <Box
            display='flex' flexDirection={compact ? 'column' : 'row'}
            alignItems='center' width='100%' gap={compact ? '-5px' : undefined}
            {...muiProps}
        >
            <Box
                marginRight={compact ? undefined : '10px'}
                width={compact ? undefined : '120px'}
                textAlign={compact ? 'center' : 'right'}
            >
                {USER_SETTINGS_COPY[variant]}
            </Box>
            <TrufoTextField
                colorVariant='accentDark' trufoVariant='filled'
                sx={{ flex: 1 }}
                inputProps={{ maxLength: MAX_LENGTHS[variant] || undefined }}
                disabled={loading}
                value={value}
                onValueChange={(newVal: string) => { onValChange(newVal) }}
                multiline={variant === 'description'}
                type={variant === 'website' ? 'url' : undefined}
            />
        </Box>
    )
}

interface SettingsModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    compact?: boolean;
    onSuccessfulSave?: () => void;
}

export default function SettingsModal({ open, setOpen, compact, onSuccessfulSave }: SettingsModalProps) {

    const { getUser, data: getUserData, loading: getUserLoading, error: getUserError } = useGetUser();
    const { updateUser, data: updateUserData, loading: updateUserLoading, error: updateUserError } = useUpdateUser();
    const userLogin = useMemo(() => getGoogleUserLoginFromStorage(), []);
    const [userSettings, setUserSettings] = useState<UiUserSettings>({});
    const [edited, setEdited] = useState<boolean>(false);

    useEffect(() => { if (userLogin) getUser(userLogin); }, [getUser, userLogin]);
    useEffect(() => { if (getUserData?.sourceInfo) setUserSettings(getUiUserSettings(getUserData?.sourceInfo)) }, [getUserData]);

    const onSubmit = () => {
        userLogin ?
            updateUser(getApiSourceInfo(userSettings) || {}, userLogin, () => { setEdited(false); onSuccessfulSave && onSuccessfulSave(); }) :
            console.log("error: unable to update as user has not logged in")
    };

    return (<TrufoModal
        colorVariant='white' open={open} onClose={() => setOpen(false)}
        sx={{ '& >.MuiBox-root': { position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 0 } }}
    >
        <>
            <Box position='absolute' top='10px' right='10px'>
                <IconButton onClick={() => setOpen(false)}><CloseIcon /></IconButton>
            </Box>
            <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
                <Box
                    display='flex' flexDirection='column' justifyContent='center' alignItems='center'
                    padding='50px 30px 30px 30px' gap='20px' maxHeight='95%'
                >
                    <Box fontSize={FontSizes.header2} marginBottom='20px'>User Info</Box>
                    <Box
                        display='flex' flexDirection='column' width='100%' gap='10px'
                        overflow='auto'
                    >
                        {settingsFields.map(variant =>
                            <SettingsTextInput
                                key={variant}
                                variant={variant}
                                loading={getUserLoading || updateUserLoading}
                                value={userSettings[variant] || ''}
                                onValChange={(newVal) => {
                                    setUserSettings(prevValue => ({ ...prevValue, [variant]: newVal }));
                                    setEdited(true);
                                }}
                                compact={compact}
                            />
                        )}
                    </Box>
                    <TrufoButton
                        disabled={!edited || getUserLoading || updateUserLoading}
                        loading={getUserLoading || updateUserLoading}
                        sx={{ minWidth: '150px', maxWidth: '400px', padding: '10px', marginTop: '20px' }}
                        type='submit'
                    >
                        {edited ? 'Save' : 'Saved'}
                    </TrufoButton>
                    <Box
                        marginTop='-10px' marginBottom='10px'
                        fontSize={FontSizes.body1} color={Colors.accentDark70}
                        visibility={edited ? 'visible' : 'hidden'}
                    >
                        Note: updates here will reflect on all content you watermark (past and future).
                    </Box>
                </Box>
            </form>
        </>
    </TrufoModal>)
}
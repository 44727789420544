import { Box, BoxProps } from "@mui/material"
import { LpText, LpTextVariant } from "./lp-text"
import { ResponsiveSpacing } from "@/components";
import { LpButton, LpButtonProps } from "./lp-button";

type DescriptionTextBlockAlignment = 'left' | 'center' | 'right';
type DescriptionTextBlockSizeVariant = 'small' | 'medium' | 'large';
export type DescriptionTextBlockProps = BoxProps & {
    headerTop?: string,
    headerBottom?: string,
    description?: string,
    align?: DescriptionTextBlockAlignment,
    buttonName?: string,
    buttonHref?: string,
    buttonProps?: LpButtonProps,
    sizeVariant?: DescriptionTextBlockSizeVariant,
    enlarged?: boolean,
}

export const DescriptionTextBlock = ({ headerTop, headerBottom, description, align = 'left', buttonName, buttonHref, buttonProps, sizeVariant = 'medium', enlarged, ...boxProps }: DescriptionTextBlockProps) => {
    const flexAlignment: Record<DescriptionTextBlockAlignment, 'start' | 'center' | 'end'> = {
        left: 'start',
        center: 'center',
        right: 'end'
    }
    const sizeVariantLpTextVariant: Record<string, Record<DescriptionTextBlockSizeVariant, LpTextVariant>> = {
        headerTop: {
            small: 'body',
            medium: 'subheader',
            large: 'subheaderLarge'
        },
        headerBottom: {
            small: 'subheaderLargeBold',
            medium: 'headerBold',
            large: 'headerBold'
        },
        description: {
            small: 'body',
            medium: 'subheader',
            large: 'subheaderLarge'
        }
    }
    return (
        <Box
            display='flex' flexDirection='column'
            alignSelf={flexAlignment[align]}
            textAlign={flexAlignment[align]}
            gap={ResponsiveSpacing.space3}
            {...boxProps}
        >
            {headerTop &&
                <LpText variant={sizeVariantLpTextVariant['headerTop'][sizeVariant]} extraEnlarged={enlarged}>{headerTop?.toLocaleUpperCase()}</LpText>
            }
            {headerBottom &&
                <LpText variant={sizeVariantLpTextVariant['headerBottom'][sizeVariant]} lineHeight={1.02} enlarged={enlarged}>{headerBottom}</LpText>
            }
            {description &&
                <LpText variant={sizeVariantLpTextVariant['description'][sizeVariant]} extraEnlarged={enlarged}>{description}</LpText>
            }
            {buttonName &&
                <Box alignSelf='start' marginTop={ResponsiveSpacing.space3}>
                    <LpButton href={buttonHref} enlarged={enlarged} newtab {...buttonProps}>{buttonName}</LpButton>
                </Box>
            }
        </Box>
    )

}
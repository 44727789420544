import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { Colors } from '.';

export type LoadingIconProps = {
    size: number | string;
}
export default function LoadingIcon({ size }: LoadingIconProps) {
    const icon = {
        hidden: {
            opacity: 0,
            pathLength: 0,
            fill: "rgba(255, 255, 255, 0)"
        },
        visible: {
            opacity: 1,
            pathLength: 1,
            fill: "rgba(255, 255, 255, 1)"
        }
    };

    // TODO: Replace w Trufo Logo
    return (
        <Box
            width={size} height={size} borderRadius='30px'
            display='flex' overflow='hidden'
            sx={{ placeContent: 'center', background: 'rgba(255, 255, 255, 0.2)' }}
        >
            <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                style={{
                    width: '56%',
                    overflow: 'visible',
                    stroke: Colors.white,
                    strokeWidth: 2,
                    strokeLinejoin: 'round',
                    strokeLinecap: 'round',
                }}
            >
                <motion.path
                    d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    transition={{
                        default: { duration: 2, ease: "easeInOut" },
                        fill: { duration: 2, ease: [1, 0, 0.8, 1] }
                    }}
                />
            </motion.svg>
        </Box>
    )
}
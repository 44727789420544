import { Colors, ResponsiveSpacing } from "@/components";
import { Box } from "@mui/material"
import { LpText } from ".";
import { useRef } from "react";

export type LpCardProps = {
    Illustration?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    header?: string;
    description?: string;
    variant?: 'wide' | 'square';
    enlarged?: boolean;
    extraEnlarged?: boolean;
}

export const LpCard = ({ Illustration, header, description, variant = 'wide', enlarged, extraEnlarged }: LpCardProps) => {
    const illustrationRef = useRef(null);
    // @ts-ignore
    const illustrationWidth = illustrationRef?.current?.offsetWidth;
    if (variant === 'wide') {
        return (
            <Box
                display='flex'
                minWidth='200px'
                flexBasis='0px' flexShrink='1' flexGrow='1'
                padding={ResponsiveSpacing.space5}
                sx={{ backgroundColor: 'white' }}
                alignItems='center'
                boxSizing='border-box'
            >
                {Illustration &&
                    <Box
                        height={ResponsiveSpacing.space9} display='flex' alignItems='center'
                        ref={illustrationRef}
                        sx={{ transform: 'translateX(-15%)' }}
                    >
                        <Illustration
                            ref={illustrationRef}
                            height='80%'
                        />
                    </Box>
                }
                {(header || description) && <Box
                    textAlign='left'
                    display='flex' flexDirection='column'
                    gap={ResponsiveSpacing.space5}
                    marginLeft={illustrationWidth ? `${illustrationWidth * -0.15}px` : 0}
                >
                    {header && <LpText variant='subheaderLargeLight' enlarged={extraEnlarged}>{header}</LpText>}
                    {description && <LpText variant='label' enlarged={enlarged} extraEnlarged={extraEnlarged} lineHeight={1.3}>{description}</LpText>}
                </Box>}
            </Box>
        )
    }

    if (variant === 'square') {
        return (
            <Box
                display='flex' flexDirection='column'
                sx={{ backgroundColor: 'white' }}
                boxSizing='border-box'
                alignItems='center' justifyContent='center'
                flex={1}
                // maxWidth='300px'
                position='relative'
            >
                {Illustration &&
                    <Box
                        height={ResponsiveSpacing.space9}
                        display='flex' alignItems='center' justifyContent='center'
                        ref={illustrationRef}
                    >
                        <Illustration
                            ref={illustrationRef}
                            width='60%'
                        />
                    </Box>
                }

                {header &&
                    <Box
                        width='80%'
                        display='flex' flexDirection='column'
                        marginBottom={ResponsiveSpacing.space4} marginLeft={ResponsiveSpacing.space2}
                        boxSizing='border-box'
                        textAlign='left'
                    >
                        <LpText variant='bodyLight'>{header}</LpText>
                    </Box>
                }

                {description &&
                    <Box
                        width='100%' height='100%' position='absolute'
                        sx={{
                            opacity: 0, '&:hover': { opacity: 1 }, transitionDuration: '0.3s',
                            backgroundColor: Colors.accentDarkLp,
                            cursor: 'default'
                        }}
                        padding='10%' boxSizing='border-box'
                        display='flex' justifyContent='center' alignItems='center'
                        color='white'
                    >
                        <LpText variant='label'>{description}</LpText>
                    </Box>
                }
            </Box>
        )
    }

}
import { isLocalEnv } from "@/utils";
import axios from "axios";

const LOCAL_SERVER_PORT = 8080;

const ExpressClient = axios.create({
    ...(isLocalEnv() && { baseURL: `http://localhost:${LOCAL_SERVER_PORT}` }),
    headers: {
        "content-type": "application/json",
    }
});

export default ExpressClient;
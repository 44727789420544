import { Navbar, NAVBAR_HEIGHT_PX, ZIndexes } from "@components";
import { Box } from "@mui/material";

type MainAppWrapperProps = {
    hideNavbar?: boolean;
    navbarZIndex?: number;
    onAccountSettingsSave?: () => void;
}

export default function MainAppWrapper({ hideNavbar, navbarZIndex, onAccountSettingsSave, children }: React.PropsWithChildren<MainAppWrapperProps>) {

    return (
        <Box width='100dvw' height='100dvh'>
            {!hideNavbar ? (
                <>
                    <Navbar theme='dark' zIndex={navbarZIndex || ZIndexes.navbar} onAccountSettingsSave={onAccountSettingsSave} />
                    <Box height={`calc(100dvh - ${NAVBAR_HEIGHT_PX}px)`} position='relative'>
                        {children}
                    </Box>
                </>
            ) : children}
        </Box>
    );
}
import { ResponsiveFontSizes, TrufoButton, TrufoButtonColorVariant, TrufoButtonProps } from "@/components";
import { ResponsiveSpacing, ZIndexes } from "@/components/variables";
import { ButtonProps, SxProps } from "@mui/material";

type LpButtonVariant = 'primary' | 'secondary';
type LpButtonColorVariant = 'light' | 'dark' | 'accentDark';
type LpButtonSizeVariant = 'xlarge' | 'large' | 'medium' | 'small';
type LpButtonShapeVariant = 'square' | 'round';
export type LpButtonProps = {
    typeVariant?: LpButtonVariant,
    colorVariant?: LpButtonColorVariant,
    sizeVariant?: LpButtonSizeVariant,
    shapeVariant?: LpButtonShapeVariant,
    extraPadding?: boolean,
    sx?: SxProps,
    enlarged?: boolean,
} & ButtonProps & TrufoButtonProps;

export const LpButton = ({ typeVariant = 'primary', colorVariant = 'dark', sizeVariant = 'medium', shapeVariant = 'square', extraPadding, enlarged, sx, children, ...muiButtonProps }: React.PropsWithChildren<LpButtonProps>) => {
    const buttonSizeHierarchy: LpButtonSizeVariant[] = ['xlarge', 'large', 'medium', 'small'];
    const sizeVariantToUse = !enlarged ? sizeVariant : buttonSizeHierarchy[((buttonSizeHierarchy.findIndex((value) => value === sizeVariant) || 1) - 1)];
    const getTfButtonColorVariant = (): TrufoButtonColorVariant => {
        switch (colorVariant) {
            case 'light':
                return typeVariant === 'primary' ? 'landingPagePrimaryLight' : 'landingPageSecondaryLight';
            case 'dark':
                return typeVariant === 'primary' ? 'landingPagePrimaryDark' : 'landingPageSecondaryDark';
            case 'accentDark':
                return typeVariant === 'primary' ? 'landingPagePrimaryAccentDark' : 'landingPageSecondaryAccentDark';
        }
    }
    const sizeVariantStyles: Record<LpButtonSizeVariant, TrufoButtonProps> = {
        xlarge: {
            sx: {
                fontSize: ResponsiveFontSizes.header4,
                paddingTop: ResponsiveSpacing.space5, paddingBottom: ResponsiveSpacing.space5,
                paddingLeft: extraPadding ? ResponsiveSpacing.space7 : ResponsiveSpacing.space6,
                paddingRight: extraPadding ? ResponsiveSpacing.space7 : ResponsiveSpacing.space6,
                borderRadius: shapeVariant === 'round' ? ResponsiveFontSizes.header1 : ResponsiveFontSizes.body1,
            }
        },
        large: {
            sx: {
                fontSize: ResponsiveFontSizes.header5,
                paddingTop: ResponsiveSpacing.space4, paddingBottom: ResponsiveSpacing.space4,
                paddingLeft: extraPadding ? ResponsiveSpacing.space6 : ResponsiveSpacing.space5,
                paddingRight: extraPadding ? ResponsiveSpacing.space6 : ResponsiveSpacing.space5,
                borderRadius: shapeVariant === 'round' ? ResponsiveFontSizes.header3 : ResponsiveFontSizes.body2,
            }
        },
        medium: {
            sx: {
                fontSize: ResponsiveFontSizes.header7,
                paddingTop: ResponsiveSpacing.space3, paddingBottom: ResponsiveSpacing.space3,
                paddingLeft: extraPadding ? ResponsiveSpacing.space5 : ResponsiveSpacing.space4,
                paddingRight: extraPadding ? ResponsiveSpacing.space5 : ResponsiveSpacing.space4,
                borderRadius: shapeVariant === 'round' ? ResponsiveFontSizes.header5 : ResponsiveFontSizes.body3,
            }
        },
        small: {
            sx: {
                fontSize: ResponsiveFontSizes.body0,
                paddingTop: ResponsiveSpacing.space2, paddingBottom: ResponsiveSpacing.space2,
                paddingLeft: extraPadding ? ResponsiveSpacing.space4 : ResponsiveSpacing.space3,
                paddingRight: extraPadding ? ResponsiveSpacing.space4 : ResponsiveSpacing.space3,
                borderRadius: shapeVariant === 'round' ? ResponsiveFontSizes.header6 : ResponsiveFontSizes.body4,
            }
        }
    }

    return (
        <TrufoButton
            colorVariant={getTfButtonColorVariant()}
            sx={{
                ...sizeVariantStyles[sizeVariantToUse]?.sx,
                ...sx,
                zIndex: ZIndexes.landingPageClickable,
            } as SxProps}
            {...muiButtonProps}
        >
            {children}
        </TrufoButton>
    )
}
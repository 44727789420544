import { Box } from "@mui/material"
import { Colors, FontWeights, ResponsiveSpacing } from "@/components"
import { DescriptionTextBlock, LpButton, LpCard, SectionWrapper } from "./../components"
import { PRODUCTS_ROUTE, WHITEPAPER_ROUTE } from "@/app-routes";
import { ReactComponent as AttributionIllustration } from '@assets/illustrations/value-attribution.svg'
import { ReactComponent as ComplianceIllustration } from '@assets/illustrations/value-compliance.svg'
import { ReactComponent as MonetizationIllustration } from '@assets/illustrations/value-monetization.svg'
import { ReactComponent as ProtectionIllustration } from '@assets/illustrations/value-protection.svg'
import { EXTRA_COMPACT_VIEW_WIDTH_PX, SectionProps } from "../types-and-constants";

export type SectionValueProps = SectionProps;

export const SectionValue = (props: SectionValueProps) => {
    const compact = props?.pageWidthPx < 680;
    const extraCompact = props?.pageWidthPx < EXTRA_COMPACT_VIEW_WIDTH_PX;

    const cardsRow1 = (<>
        <LpCard
            header='Attribution'
            description='Wherever your content is consumed, make its provenance known -- even when traditional metadata or citations fail.'
            Illustration={AttributionIllustration}
            enlarged={compact} extraEnlarged={extraCompact}
        />
        <LpCard
            header='Compliance'
            description='Add trusted C2PA metadata with the click of a button. Comply with AISIC standards and impending regulation.'
            Illustration={ComplianceIllustration}
            enlarged={compact} extraEnlarged={extraCompact}
        />
    </>);
    const cardsRow2 = (<>
        <LpCard
            header='Monetization'
            description='Embed a respected copyright license into your content. Prove your ownership and manage (or transfer) your IP rights.'
            Illustration={MonetizationIllustration}
            enlarged={compact} extraEnlarged={extraCompact}
        />
        <LpCard
            header='Protection'
            description='Discourage misuse of your assets with automatic tamper recognition whenever your content is altered then consumed.'
            Illustration={ProtectionIllustration}
            enlarged={compact} extraEnlarged={extraCompact}
        />
    </>);

    return (
        <SectionWrapper {...props} gap={ResponsiveSpacing.space5}>
            <DescriptionTextBlock
                headerTop="INVEST IN YOUR CONTENT"
                headerBottom="Content Labels Are Valuable"
                align='left'
                sizeVariant={extraCompact ? 'large' : 'medium'}
            />
            <Box>
                <Box width='100%' display='flex' flexDirection='column' gap={ResponsiveSpacing.space3}>
                    {!compact ? (<>
                        <Box display='flex' flexDirection='row' gap={ResponsiveSpacing.space3}>
                            {cardsRow1}
                        </Box>
                        <Box display='flex' flexDirection='row' gap={ResponsiveSpacing.space3}>
                            {cardsRow2}
                        </Box>
                    </>) : (<>{cardsRow1}{cardsRow2}</>)}
                </Box>
            </Box>
            <Box
                alignSelf={!compact ? 'flex-end' : 'center'}
                display='flex'
                gap={ResponsiveSpacing.space5}
            >
                <LpButton
                    typeVariant='secondary' sizeVariant='medium' shapeVariant='round'
                    sx={{ fontWeight: FontWeights.regular, backgroundColor: Colors.accentLightLp }}
                    href={WHITEPAPER_ROUTE} newtab
                    enlarged={compact}
                >
                    Read our Whitepaper
                </LpButton>
                <LpButton
                    typeVariant='secondary' sizeVariant='medium' shapeVariant='round'
                    sx={{ fontWeight: FontWeights.regular }}
                    href={PRODUCTS_ROUTE}
                    enlarged={compact}
                >
                    Discover Use Cases
                </LpButton>
            </Box>
        </SectionWrapper>
    )
}
import { getGoogleUserJwtFromStorage, getGoogleUserLoginFromJwt, logInGoogleUser } from "@utils";
import { Alert, Box, CircularProgress } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { Colors, FontSizes, TrufoButton, TrufoLink, TrufoTextField, ZIndexes } from "@components";
import { useNavigate } from "react-router-dom";
import { useCreateUser, useGetUser, useJoinNewsletter } from "@/hooks";
import { useEffect, useRef, useState } from "react";
import { JwtPayloadGoogle } from "@/types";
import { jwtDecode } from 'jwt-decode';
import { LANDING_PAGE_ROUTE, MAIN_APP_ROUTE, PRIVACY_POLICY_ROUTE, TERMS_OF_SERVICE_ROUTE } from "@/app-routes";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { JoinNewsletterAction } from "@/hooks/use-join-newsletter";

interface LoginProps {
    accessCodeFocus?: boolean;
    onSuccessVariant?: 'button' | 'redirect';
}

const accessCodes = ['hellotrufo', 'trufoiscool'];

export default function Login({ accessCodeFocus, onSuccessVariant }: LoginProps) {

    if (!onSuccessVariant) onSuccessVariant = 'button';

    const navigate = useNavigate();

    const [trufoUserFetched, setTrufoUserFetched] = useState<boolean>(false);
    const { getUser: getTrufoUser, data: trufoUserData, loading: getTrufoUserDataLoading, error: getTrufoUserError } = useGetUser();
    const { createUser: createTrufoUser, data: createTrufoUserData, loading: createTrufoUserDataLoading, error: createTrufoUserError } = useCreateUser();

    const [googleUserJwt, setGoogleUserJwt] = useState<JwtPayloadGoogle | undefined>(getGoogleUserJwtFromStorage() || undefined);
    const googleUserData = googleUserJwt && getGoogleUserLoginFromJwt(googleUserJwt);
    const [googleLoginError, setGoogleLoginError] = useState<boolean>(false);

    const { joinNewsletter, data: joinNewsletterData, loading: joinNewsletterLoading, error: joinNewsletterError } = useJoinNewsletter();
    const [joinNewsletterClicked, setJoinNewsletterClicked] = useState<boolean>(false);
    // const joinWaitlist = () => {
    //     if (googleUserJwt?.email && googleUserJwt?.email_verified) {
    //         joinNewsletter(googleUserJwt?.email)
    //     } else {
    //         window.location.href = LANDING_PAGE_ROUTE;
    //     }
    // }

    const accessCodeInputRef = useRef<HTMLInputElement>(null);
    const [accessCodeInput, setAccessCodeInput] = useState<string>('');
    // focus on access code box onload if prop is passed
    useEffect(() => {
        if (accessCodeFocus) accessCodeInputRef?.current?.focus();
    }, [accessCodeFocus, accessCodeInputRef, googleUserData])
    // if access code matches, create google user
    useEffect(() => {
        if (accessCodes.includes(accessCodeInput) && googleUserJwt) {
            createTrufoUser(googleUserJwt);
        }
    }, [accessCodeInput, accessCodes, googleUserJwt])
    // whenever google jwt changes, re-fetch trufo user and newsletter info
    useEffect(() => {
        if (googleUserData) {
            getTrufoUser(googleUserData, undefined, () => setTrufoUserFetched(true));
            googleUserJwt?.email && joinNewsletter(googleUserJwt?.email, JoinNewsletterAction.CHECK);
        }
    }, [googleUserJwt])
    // whenever trufo user is successfully created/fetched, and onSuccessVariant = redirect, redirect to app
    useEffect(() => {
        if (onSuccessVariant === 'redirect') {
            if ((createTrufoUserData && !createTrufoUserError) || (trufoUserData && !getTrufoUserError)) {
                navigate(MAIN_APP_ROUTE);
            }
        }
    }, [createTrufoUserData, createTrufoUserError, trufoUserData, getTrufoUserError, onSuccessVariant])


    const getLoadingCircle = (size?: string | number) => < CircularProgress size={size} sx={{ color: Colors.accentDark }} />;
    const AppButton = onSuccessVariant === 'button' ? (
        <>
            <Box display='flex' justifyContent='center'>
                <TrufoButton label='Go to app' colorVariant='dark' onClick={() => navigate(MAIN_APP_ROUTE)} sx={{ padding: '10px', borderRadius: '4px' }} />
            </Box>
        </>
    ) : undefined;

    const GoogleLoginPill = (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap='10px'>
            <GoogleLogin
                onSuccess={(credentialResponse) => {
                    // google login
                    const jwt = credentialResponse?.credential;
                    if (jwt) {
                        const jwtObj = jwtDecode(jwt) as JwtPayloadGoogle;
                        const googleUserLogin = getGoogleUserLoginFromJwt(jwtObj);
                        if (googleUserLogin) {
                            setGoogleLoginError(false);
                            logInGoogleUser(jwt);
                            setGoogleUserJwt(jwtObj);
                        } else {
                            setGoogleLoginError(true);
                        }
                    } else {
                        setGoogleLoginError(true);
                    }
                }}
                onError={() => setGoogleLoginError(true)}
                theme='filled_black'
                shape='pill'
            />
            <Box paddingLeft='10px' paddingRight='10px' fontSize={FontSizes.body3}>
                <Box>by signing in you are agreeing to our</Box>
                <Box><TrufoLink href={TERMS_OF_SERVICE_ROUTE}>terms of service</TrufoLink> and <TrufoLink href={PRIVACY_POLICY_ROUTE}>privacy policy</TrufoLink></Box>
            </Box>
        </Box>
    )

    const AccessCodeInput = (
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Box>Please enter your access code:</Box>
            {!createTrufoUserDataLoading ?
                (<TrufoTextField
                    inputRef={accessCodeInputRef}
                    value={accessCodeInput} onValueChange={setAccessCodeInput}
                    colorVariant='accentDark' trufoVariant='filled'
                    sx={{ width: '80%', maxWidth: '500px' }}
                />)
                : getLoadingCircle()
            }
            {(joinNewsletterData?.found || (joinNewsletterData?.action === JoinNewsletterAction.CHECK_AND_ADD)) ?
                (<Box marginTop='5px' fontSize={FontSizes.body3} display='flex' alignItems='center' gap='3px'>
                    <CheckCircleIcon sx={{ fontSize: FontSizes.body1, color: Colors.green }} />
                    <Box color={Colors.green}>Waitlist / newsletter joined!</Box>
                </Box>) :
                (<Box
                    fontSize={FontSizes.body3} display='flex' gap='3px' marginTop='10px'
                >
                    <Box>No access code? Click</Box>
                    {joinNewsletterLoading ?
                        (<Box>{getLoadingCircle(FontSizes.body5)}</Box>) :
                        (<TrufoLink
                            onClick={() => {
                                setJoinNewsletterClicked(true);
                                if (googleUserJwt?.email) {
                                    joinNewsletter(googleUserJwt?.email)
                                } else { window.location.href = LANDING_PAGE_ROUTE }
                            }}
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            here
                        </TrufoLink>)
                    }
                    <Box>to join the waitlist</Box>
                </Box>)
            }
            {
                joinNewsletterError && joinNewsletterClicked && (<Box marginTop='5px' fontSize={FontSizes.body3} display='flex' alignItems='center' gap='3px'>
                    <ErrorIcon sx={{ fontSize: FontSizes.body1, color: Colors.red }} />
                    <Box color={Colors.red}>Error in joining waitlist</Box>
                </Box>)
            }
        </Box >
    )

    // TODO: support Google Login ux_mode="redirect" (opening login on same page not popup)
    return trufoUserFetched || !googleUserData ? (
        <>
            <Box display='flex' flexDirection='column' gap='10px'>
                {((trufoUserData && !getTrufoUserError) || (createTrufoUserData && !createTrufoUserError)) ? AppButton :
                    (<>
                        <Box fontSize={FontSizes.header3}>
                            Sign In / Sign Up
                        </Box>
                        {googleUserData ? AccessCodeInput : GoogleLoginPill}
                    </>)
                }
            </Box>
            {(createTrufoUserError || googleLoginError) && <Box
                position='absolute' left='0px' bottom='0px'
                width='100dvw' zIndex={ZIndexes.landingPageAlerts}
            >
                <Alert severity="error">{googleLoginError ? 'Google Login Error' : 'Trufo Login Error'}</Alert>
            </Box>}
        </>
    ) : (<Box width='100%' display='flex' justifyContent='center'>{getLoadingCircle()}</Box>);
}
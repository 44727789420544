import { jwtDecode } from 'jwt-decode';
import { googleLogout } from '@react-oauth/google';
import { ApiSourceInfo, FileType, ImageInfo, JwtPayloadGoogle, UiUserSettings, ApiUserLogin, ApiUserAccountType, ImageBase64, isTypeofImageBase64, ImageAspectRatio } from './types';
import { useMemo } from 'react';


// --- ENV ------------------------------------------------------------------------------------------------------------
// test = deployed testenv or local
export const isTestEnv = () => {
    if (typeof window !== 'undefined') {
        const url = window?.location?.href;
        return url.includes('test') || url.includes('localhost');
    } else {
        return true; // to be safe, say env is test when window is undefined
    }
};

export const isLocalEnv = () => {
    if (typeof window !== 'undefined') {
        const url = window?.location?.href;
        return url.includes('localhost');
    } else {
        return true; // say env is local when window is undefined
    }
}

// --- AUTH ------------------------------------------------------------------------------------------------------------

export const getGoogleUserJwtFromStorage = (): JwtPayloadGoogle | null => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
        return jwtDecode(jwt) as JwtPayloadGoogle;
    } else {
        return null;
    }
}

export const getGoogleUserLoginFromJwt = (jwt: JwtPayloadGoogle): ApiUserLogin | null => {
    if (jwt.sub) {
        return {
            accountType: ApiUserAccountType.GOOGLE,
            sub: jwt.sub,
            data: {
                email: jwt.email_verified ? jwt.email : undefined,
                firstName: jwt.given_name,
                lastName: jwt.family_name,
            },
        }
    } else {
        return null;
    }
}

export const getGoogleUserLoginFromStorage = (): ApiUserLogin | null => {
    const jwt = getGoogleUserJwtFromStorage();
    if (jwt) {
        return getGoogleUserLoginFromJwt(jwt);
    } else {
        return null;
    }
}

export const logInGoogleUser = (jwt: string) => {
    localStorage.setItem("jwt", jwt);
}

export const logOutGoogleUser = () => {
    localStorage.removeItem('jwt');
    googleLogout();
    window.location.reload();
    // window.location.href = '/';
}

// --- IMAGES & FILES ---------------------------------------------------------------------------------------------------------

// Get Image File
export const setFileFromPathOrUrl = (filePathOrUrl: string, name: string, type: string, setFile: (newFile: File) => void) => {
    fetch(filePathOrUrl)
        .then(response => response.blob())
        .then(blob => {
            const newFile = new File([blob], name, {
                type: type,
            });
            setFile(newFile);
        });
}

// Getting Image URLs
export const getUrlFromBase64 = (base64: string, type: FileType) => {
    return `data:${type};base64,${base64}`;
}
export const getUrlFromFile = (file: File) => {
    return window.URL.createObjectURL(file);
}
export const useMemoGetUrlFromFile = (file?: File) => {
    return useMemo(() => file ? window.URL.createObjectURL(file) : undefined, [file]);
}
export const getImageUrl = (image: File | ImageBase64): string => {
    if (isTypeofImageBase64(image)) { // image is type of ImageBase64
        const img = image as ImageBase64;
        return getUrlFromBase64(img.base64, img.type);
    } else { // image is type File
        return getUrlFromFile(image as File);
    }
}

// Get Image Info & Aspect Ratio
export const setImageInfo = (file: File, onImageInfoChange: (newInfo: ImageInfo) => void) => {
    const imageUrl = getImageUrl(file);
    const img = new Image();
    img.onload = function () {
        const imageInfo: ImageInfo =
            { name: file.name || 'image-file', width: img.width, height: img.height, type: file.type };
        onImageInfoChange(imageInfo);
    };
    img.src = imageUrl;
}
export const setImageAspectRatio = (
    image: File | ImageBase64 | string,
    onImageAspectRatioChange: (newAspectRatio: ImageAspectRatio) => void,
) => {
    // parse dimensions (note: the only string type image should be url)
    const imageUrl = (typeof image === 'string') ? image : getImageUrl(image);
    const img = new Image();
    img.onload = function () {
        onImageAspectRatioChange({ width: img.width, height: img.height });
    };
    img.src = imageUrl;
}
export const getImageAspectRatioStr = (aspectRatio: ImageInfo | ImageAspectRatio | undefined): string => {
    return aspectRatio ? `${aspectRatio.width} / ${aspectRatio.height}` : '';
}

// --- API <-> UI DATATYPE CONVERSIONS -----------------------------------------------------------------------------------------

export function getApiSourceInfo(settings: UiUserSettings): ApiSourceInfo {
    const newSourceInfo: ApiSourceInfo = {
        displayName: settings.displayName,
        description: settings.description,
        sourceLinks: settings.website ? [settings.website] : undefined,
    }
    return newSourceInfo;
}
export function getUiUserSettings(sourceInfo: ApiSourceInfo): UiUserSettings {
    const userSettings: UiUserSettings = {
        displayName: sourceInfo.displayName,
        description: sourceInfo.description,
        website: Array.isArray(sourceInfo.sourceLinks) ? sourceInfo.sourceLinks[0] : undefined,
    }
    return userSettings;
}

// --- OTHER ------------------------------------------------------------------------------------------------------------------

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}
import { ExpressClient, TRUFO_API } from '@/http-clients';
import { getUrlFromBase64, setFileFromPathOrUrl } from '@utils';
import { ApiEncodingRequest, UiEncodingSettings, FileType, ImageInfo, ApiUserLogin } from '@types';
import { createEncodingRequest } from '@pages/main-app/utils';
import { useMemo, useState } from 'react';

interface UseEncodeImageRet {
    encodeImage: (imageFile: File, encodingSettings: UiEncodingSettings, imageInfo: ImageInfo, login: ApiUserLogin) => void;
    data?: File;
    loading: boolean;
    error?: string;
}

export default function useEncodeImage(isPreview?: boolean): UseEncodeImageRet {

    const [data, setData] = useState<File>(); // watermarked image
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const encodeImage = useMemo(() => (image: File, encodingSettings: UiEncodingSettings, imageInfo: ImageInfo, login: ApiUserLogin) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            // Create payload + API call
            const base64 = reader?.result as string | null;
            const requestObj: ApiEncodingRequest = createEncodingRequest(encodingSettings, base64 || '', imageInfo, login, Boolean(isPreview))
            setLoading(true);
            ExpressClient.post(TRUFO_API.ENCODE, requestObj)
                .then((response) => {
                    // On BE data return, set display
                    const watermarked = response?.data;
                    const encodedBase64 = watermarked?.avData;
                    if (watermarked && !watermarked.error && encodedBase64) {
                        setFileFromPathOrUrl(
                            getUrlFromBase64(encodedBase64, imageInfo.type as FileType),
                            `watermarked_image.${(imageInfo.type).split('/')[1]}`,
                            imageInfo.type,
                            setData,
                        );
                        setError(undefined);
                        // TODO: save encoding settings in localstorage to use for future requests
                    } else {
                        setError(watermarked?.error || 'no data returned');
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setData(undefined);
                    setError(err.toString());
                    setLoading(false);
                });
        };
        reader.readAsDataURL(image);
    }, [isPreview])
    return { encodeImage, data, loading, error };
}
import { UiEncodingSettings } from '@types';
import { TrufoCheckbox, TrufoLink, ZIndexes } from '@components';
import { Box } from '@mui/material';

export type EncodeCheckboxProps = {
    variant: 'includeUserDetails' | 'doNotTrain';
    encodingSettings: UiEncodingSettings;
    onEncodingSettingsChange: (newVal: UiEncodingSettings) => void;
    openSettingsModal?: () => void;
}

export default function EncodeCheckbox({ variant, encodingSettings, onEncodingSettingsChange, openSettingsModal }: EncodeCheckboxProps) {

    const displayText = {
        includeUserDetails: 'Include User Details',
        doNotTrain: 'Do not use for AI training',
    }

    const selected = encodingSettings[variant];

    return (
        <Box display='flex' alignItems='center' zIndex={ZIndexes.clickable}>
            <TrufoCheckbox
                checked={selected}
                disabled={variant === 'includeUserDetails'}
                onChange={() => { onEncodingSettingsChange({ [variant]: !selected }); }}
                colorVariant='accentDark'
                sx={variant === 'includeUserDetails' ? { marginTop: '-9px' } : {}}
            />
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
                <Box>{displayText[variant]}</Box>
                {variant === 'includeUserDetails' &&
                    <Box display='flex'>
                        <Box marginRight='5px'>(edit details</Box>
                        <TrufoLink onClick={openSettingsModal} trufoVariant='accentDark'>here</TrufoLink>
                        <Box>)</Box>
                    </Box>
                }
            </Box>
        </Box>
    );
}
import { Box, BoxProps, Button, ButtonProps, Menu } from "@mui/material";
import { useState } from "react";
import Fade from '@mui/material/Fade';
import { Colors, TrufoMenuItem, ZIndexes } from "@/components";
import MenuIcon from '@mui/icons-material/Menu';

interface CompactMenuProps {
    links: Record<string, string>;
    containerProps?: BoxProps;
    buttonProps?: ButtonProps;
}
export default function CompactMenu({ links, containerProps, buttonProps, children }: React.PropsWithChildren<CompactMenuProps>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box {...containerProps}>
            <Button
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                tabIndex={0}
                onClick={handleClick}
                {...buttonProps}
                sx={{
                    color: Colors.white, zIndex: ZIndexes.navbar,
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    ':focus-visible': {
                        transform: 'scale(1.2)',
                        backgroundColor: Colors.accentLight20,
                    },
                    ...buttonProps?.sx,
                }}
            >
                {children || <MenuIcon sx={{ color: Colors.white }} />}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                sx={{ ...containerProps }}
            >
                {Object.keys(links).map(linkName => {
                    const linkHref = links[linkName];
                    return (<TrufoMenuItem key={linkName} onClick={() => window.location.href = linkHref}>{linkName}</TrufoMenuItem>)
                })}
            </Menu>
        </Box>
    );
}
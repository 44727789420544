import { Box } from "@mui/material";
import ImageUploadButton from "./image-upload-button";
import { UploadError, validateImageAndUpload } from "./image-upload-utils";
import { ImageInfo, Method } from "@types";
import { useState } from "react";
import { FontSizes, TrufoLink, ZIndexes } from "@/components";
import { FEATURE_REQUEST_LINK } from "@/app-routes";

type ImageUploadProps = {
    onValidFileChange: (newFile: File, newInfo: ImageInfo) => void,
    onMethodChange?: (method: Method | undefined) => void,
    disableEncode?: boolean,
}

export default function ImageUpload({ onValidFileChange, onMethodChange, disableEncode }: ImageUploadProps) {

    const [error, setError] = useState<UploadError>();
    const [imageInfo, setImageInfo] = useState<ImageInfo>();

    return (
        <>
            {/* Image upload button + info display */}
            <Box
                width='100%' height='100%'
                position='absolute' left='0px' top='0px'
                display='flex' flexDirection='column' alignItems='center' justifyContent='center' gap='20px'
            >
                <Box
                    width='70%' height='60%' marginTop='20px'
                    zIndex={ZIndexes.clickable}
                >
                    <ImageUploadButton
                        onFileChange={(newFile: File) => {
                            validateImageAndUpload(newFile, onValidFileChange, setError, setImageInfo);
                            onMethodChange && onMethodChange(Method.ENCODE);
                        }}
                        error={error}
                        info={imageInfo}
                        disableEncode={disableEncode}
                    />
                </Box>
                <Box zIndex={ZIndexes.clickable} fontSize={FontSizes.body3}>
                    Any feature requests? <TrufoLink href={FEATURE_REQUEST_LINK} newtab>Fill out this form.</TrufoLink>
                </Box>
            </Box>
        </>
    );
}
import { Colors, Fonts, ResponsiveFontSizes, ResponsiveSpacing } from "@/components";
import { Box, BoxProps, Chip, ChipProps } from "@mui/material";

type TagDisplayProps = {
    tags: string[];
    chipProps?: ChipProps;
} & BoxProps;
export default function TagDisplay({ tags, chipProps, ...muiProps }: TagDisplayProps) {
    return (
        <Box
            width='100%' display='flex' flexWrap='wrap'
            alignItems='center' justifyContent='center' gap={ResponsiveSpacing.space0}
            {...muiProps}
        >
            {tags.map((tag) =>
                <Chip
                    key={tag} label={tag} size='small'
                    {...chipProps}
                    sx={{
                        fontFamily: Fonts.monospace, fontSize: ResponsiveFontSizes.body2,
                        color: Colors.dark50, borderRadius: '5px', ...chipProps?.sx
                    }}
                />
            )}
        </Box>
    );
}
import { MenuItem, MenuItemProps } from "@mui/material"
import { Fonts, ZIndexes } from "./variables";

type TrufoMenuItemProps = MenuItemProps;

export default function TrufoMenuItem({ children, ...muiProps }: React.PropsWithChildren<TrufoMenuItemProps>) {
    return (
        <MenuItem
            {...muiProps}
            sx={{
                fontFamily: Fonts.trufo,
                zIndex: ZIndexes.clickable,
                ...muiProps?.sx,
            }}
        >
            {children}
        </MenuItem>
    );
}
import { getFontSize, LpButton, LpText } from "../components"
import { Box, List, ListItem } from "@mui/material";
import { Colors, FontWeights, ResponsiveSpacing } from "@/components";
import { DEMO_REQUEST_LINK, LOGIN_ROUTE } from "@/app-routes";
import { SectionProps } from "../types-and-constants";

interface PricingPlansProps {
    compact?: boolean;
    extraCompact?: boolean;
    sectionProps: SectionProps;
}

export const PricingPlans = ({ extraCompact }: PricingPlansProps) => {

    interface PricingPlanCardProps {
        title: string,
        subtitle: string,
        buttonName: string,
        buttonHref: string,
        buttonAnnotation?: string,
        featuresTitle: string,
        features: string[],
        backgroundColor?: string,
        buttonColor?: string,
    }
    const PricingPlanCard = ({ title, subtitle, buttonName, buttonHref, buttonAnnotation, featuresTitle, features, backgroundColor, buttonColor }: PricingPlanCardProps) => (
        <Box
            display='flex' flexDirection='column'
            gap={ResponsiveSpacing.space1}
            sx={{ backgroundColor: backgroundColor || Colors.lightGray }}
            textAlign='left' fontWeight={FontWeights.light}
            padding={!extraCompact ? ResponsiveSpacing.space5 : ResponsiveSpacing.space6}
            lineHeight={1.2}
        >
            <LpText variant='header' enlarged={extraCompact}>{title}</LpText>
            <Box
                position='relative'
                fontSize={getFontSize('body', extraCompact, extraCompact)}
                marginBottom={ResponsiveSpacing.space5}
            >
                <Box {...(!extraCompact ? { position: 'absolute', left: '0', top: '0' } : {})}>{subtitle}</Box>
                {/* hack containing the longest sentence out of the 3 boxes to mimic grid behavior (i.e. align all card components across cards)  */}
                {!extraCompact && <Box sx={{ opacity: 0, userSelect: 'none' }}>Ensure that your content labels and your reputation can be trusted.</Box>}
            </Box>
            <LpButton
                href={buttonHref} enlarged={extraCompact} typeVariant='secondary' shapeVariant='round' extraPadding
                sx={{
                    fontWeight: FontWeights.light,
                    backgroundColor: buttonColor || Colors.offWhiteDarker, alignSelf: 'start',
                }}
            >
                {buttonName}
            </LpButton>
            <Box
                fontSize={getFontSize('label', extraCompact, extraCompact)}
                marginBottom={ResponsiveSpacing.space5} marginTop={ResponsiveSpacing.space1}
                sx={{ opacity: buttonAnnotation ? 1 : 0 }}
            >
                {buttonAnnotation || (!extraCompact ? '.' : '')}
            </Box>
            <LpText variant='subheader' extraEnlarged={extraCompact}>{featuresTitle}</LpText>
            <List sx={{ paddingLeft: ResponsiveSpacing.space2 }}>
                {features.map(featureStr =>
                    <ListItem
                        sx={{
                            fontSize: getFontSize('label', extraCompact, extraCompact),
                            display: 'flex', gap: ResponsiveSpacing.space2,
                            padding: !extraCompact ? 0.3 : 0.1, // space between bullet pointed rows
                        }}
                    >
                        <Box marginTop={ResponsiveSpacing.space0} fontWeight={FontWeights.medium} padding={0} alignSelf='start'>•</Box>
                        <Box>{featureStr}</Box>
                    </ListItem>
                )}
            </List>
        </Box>
    )
    return (
        <Box display='flex' flexDirection={!extraCompact ? 'row' : 'column'} width='100%'>
            <PricingPlanCard
                title='Essential'
                subtitle="Get started quickly with Trufo's most popular products & services."
                buttonName='Try it now'
                buttonHref={LOGIN_ROUTE}
                buttonAnnotation='This plan is free!'
                featuresTitle='What you get:'
                features={[
                    'C2PA metadata with a trusted certificate associated with your account.',
                    'Basic content licenses (creative commons) and tags (AI-generated, do-not-train).',
                    'An invisible watermark that carries this information wherever the content goes.',
                    'Label up to 100 images per day.'
                ]}
                backgroundColor='#EEEEEE' buttonColor='#FFFFFF'
            />
            <PricingPlanCard
                title='Professional'
                subtitle='Ensure that your content labels and your reputation can be trusted.'
                buttonName='Contact Us'
                buttonHref={DEMO_REQUEST_LINK}
                buttonAnnotation='Starting at $0.01/item.'
                featuresTitle='Everything in Essential, plus:'
                features={[
                    'Identity verification that shows a “blue checkmark” to downstream consumers.',
                    'IP management, including proof of ownership and transferal of rights.',
                    'Advanced content hashes that reveal what has and has not been changed.',
                    'Label images, video, and audio.'
                ]}
                backgroundColor='#DDDDDD' buttonColor='#EEEEEE'
            />
            <PricingPlanCard
                title='Enterprise'
                subtitle='Custom tailored solutions for all of your content labeling needs.'
                buttonName='Contact Us'
                buttonHref={DEMO_REQUEST_LINK}
                featuresTitle='Everything in Professional, plus:'
                features={[
                    'Designated API endpoint for guaranteed fast data retrieval.',
                    'Custom integrations of functionalities in both software and hardware.',
                    'Fine-grained control over source identities and gating of content information.',
                    'Tailored features, pricing, and support.'
                ]}
                backgroundColor='#CCCCCC' buttonColor='#DDDDDD'
            />
        </Box>
    );
}
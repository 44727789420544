import { LinksSection, LinksSectionLinkRow, LpText, LpWrapper, SectionWrapper } from "../components"
import { useSectionProps } from "../types-and-constants";
import { Box } from "@mui/material";
import { ResponsiveSpacing } from "@/components";
import { WHITEPAPER_ROUTE } from "@/app-routes";

export interface AboutPageProps {
}

export const AboutPage = ({ }: AboutPageProps) => {

    const pressLinks: LinksSectionLinkRow[] = [
        {
            title: "Worried About Deepfakes? Trufo's New Watermark Could Solve Your Problems",
            date: new Date('2024-07-30'),
            source: 'AP',
            url: 'https://apnews.com/press-release/prodigy-news/artificial-intelligence-639aee96c28c3c6f0adc46075eea66ec',
        },
        {
            title: "Cindy Han '22 Is Working to Protect Artists From Theft by AI",
            date: new Date('2024-07-24'),
            source: 'Princeton Alumni Weekly',
            url: 'https://paw.princeton.edu/article/cindy-han-22-working-protect-artists-theft-ai',
        },
    ]
    const blogLinks: LinksSectionLinkRow[] = [
        {
            title: 'What is Trufo? And how you can start labeling your content.',
            date: new Date('2024-10-26'),
            source: 'Blog',
            url: 'https://medium.com/p/e70ba3fb8cbe',
        },
        {
            title: "Why Watermark (Everything)? A cryptographer's perspective.",
            date: new Date('2024-02-19'),
            source: 'Blog',
            url: 'https://medium.com/p/e7b9df714bf7',
        },
        {
            title: "Our Vision",
            date: new Date('2024-02-19'),
            source: 'Blog',
            url: 'https://medium.com/p/ae4afed08930',
        },
        {
            title: "FAQs",
            date: new Date('2024-02-15'),
            updatedDate: new Date('2024-08-15'),
            source: 'Blog',
            url: 'https://medium.com/p/a78e608e04f5',
        },
    ]
    const resourceLinks: LinksSectionLinkRow[] = [
        {
            title: 'Solutions to the Content Authenticity Problem',
            date: new Date('2024-08-26'),
            source: 'Whitepaper',
            url: WHITEPAPER_ROUTE,
        },
    ]

    const sectionProps = useSectionProps();

    return (
        <LpWrapper>
            <SectionWrapper {...sectionProps} gap={ResponsiveSpacing.space5}>
                <Box alignSelf='start' marginBottom={ResponsiveSpacing.space5}><LpText variant='header'>About Trufo</LpText></Box>
                <LinksSection
                    links={pressLinks}
                    sectionHeaderBottom="Press"
                />
                <LinksSection
                    links={blogLinks}
                    sectionHeaderBottom="Blogs"
                />
                <LinksSection
                    links={resourceLinks}
                    sectionHeaderBottom="Resources"
                />
            </SectionWrapper>
        </LpWrapper >
    );
}
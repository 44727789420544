import { getFontSize } from "../components"
import { Box, SxProps, Table, TableCell, TableRow } from "@mui/material";
import { Colors, Fonts, FontWeights, IconCheckmark, ResponsiveSpacing } from "@/components";
import { SectionProps } from "../types-and-constants";

interface PricingFeatureChartProps {
    sectionProps: SectionProps;
}

export const PricingFeatureChart = ({ sectionProps }: PricingFeatureChartProps) => {
    const { compact, extraCompact } = sectionProps;
    const iconSize = getFontSize('subheader', compact, extraCompact);
    const tableCellStyle: SxProps = {
        fontFamily: Fonts.trufo,
        paddingTop: ResponsiveSpacing.space2, paddingBottom: ResponsiveSpacing.space2,
        lineHeight: 1.1,
        ...(extraCompact ? { paddingLeft: ResponsiveSpacing.space2, paddingRight: ResponsiveSpacing.space2 } : {})
    }
    const tableHeaderCellStyle: SxProps = {
        ...tableCellStyle,
        borderBottom: 'none',
        textAlign: 'center',
        fontSize: getFontSize('body', compact, extraCompact),
        fontWeight: FontWeights.light,
        paddingTop: ResponsiveSpacing.space3, paddingBottom: ResponsiveSpacing.space3,
    }
    const sectionHeaderCellStyle: SxProps = {
        ...tableCellStyle,
        backgroundColor: Colors.accentLightLp,
        fontSize: getFontSize('body', compact, extraCompact)
    }
    const bodyCellStyle: SxProps = {
        ...tableCellStyle,
        fontSize: getFontSize('label', compact, extraCompact),
        fontWeight: FontWeights.light,
        textAlign: 'center'
    }

    interface PricingFeatureRow {
        featureName: string,
        inEssential?: boolean | string,
        inProfessional?: boolean | string,
        inEnterprise?: boolean | string,
    }
    interface PricingFeatureBlockProps {
        blockName: string,
        rows: PricingFeatureRow[]
    }
    const PricingFeatureBlock = ({ rows, blockName }: PricingFeatureBlockProps) => {
        return (
            <>
                <TableRow>
                    <TableCell colSpan={4} sx={sectionHeaderCellStyle}>{blockName}</TableCell>
                </TableRow>
                {rows?.map(row => {
                    const { featureName, inEssential, inProfessional, inEnterprise } = row;
                    const getCellDisplay = (value: boolean | string | undefined) =>
                        typeof value === 'string' ? value : value ? < IconCheckmark size={iconSize} /> : '';
                    return (
                        <TableRow>
                            <TableCell sx={{ ...bodyCellStyle, textAlign: 'left' }}>{featureName}</TableCell>
                            <TableCell sx={bodyCellStyle}>{getCellDisplay(inEssential)}</TableCell>
                            <TableCell sx={bodyCellStyle}>{getCellDisplay(inProfessional)}</TableCell>
                            <TableCell sx={bodyCellStyle}>{getCellDisplay(inEnterprise)}</TableCell>
                        </TableRow>
                    )
                })}
                <Box height='20px' />
            </>
        )
    }

    return (
        <Table sx={{ width: '100%', fontFamily: Fonts.trufo }}>
            <TableRow>
                <TableCell sx={{ ...tableHeaderCellStyle, textAlign: 'left', fontSize: getFontSize('subheader', compact, extraCompact) }}>Plan comparison:</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, backgroundColor: '#EEEEEE', borderBottom: 'none' }}>Essential</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, backgroundColor: '#DDDDDD', borderBottom: 'none' }}>Professional</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, backgroundColor: '#CCCCCC', borderBottom: 'none' }}>Enterprise</TableCell>
            </TableRow>
            <Box height='20px' />
            <PricingFeatureBlock
                blockName='Source Verification'
                rows={[
                    { featureName: 'Google & Apple Login', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Source Profile with Information Fields', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Public Key Management', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Social Media Profile Linking', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Device & Camera Registration', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Recognition as a Verified Source', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Enterprise Account Management', inEssential: false, inProfessional: false, inEnterprise: true },
                    { featureName: 'Intermediate CA Privileges', inEssential: false, inProfessional: false, inEnterprise: true },
                ]}
            />
            <PricingFeatureBlock
                blockName='Annotations'
                rows={[
                    { featureName: 'Source Attribution', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Standard Metadata (name, date & time)', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Common Copyright Licenses (CC)', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Compliance Tag: "Generated by AI"', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Compliance Tag: "Do Not Use in AI Training"', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'C2PA Provenance Manifest', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Secure Forensic Copy', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Secure Blockchain Record', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Specification of IP Royalty Terms', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Secure IP Ownership Transferal', inEssential: false, inProfessional: true, inEnterprise: true },
                ]}
            />
            <PricingFeatureBlock
                blockName='Associations'
                rows={[
                    { featureName: 'C2PA-Compliant Metadata', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Invisible Watermark, General Images', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Invisible Watermark, Style-Specific Images', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Invisible Watermark, Video and Audio', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Invisible Watermark, Intra-Model (AI)', inEssential: false, inProfessional: false, inEnterprise: true },
                    { featureName: 'Perceptual Hash Reverse Search', inEssential: false, inProfessional: false, inEnterprise: true },
                ]}
            />
            <PricingFeatureBlock
                blockName='Content Verification'
                rows={[
                    { featureName: 'C2PA Recognition (Trust List)', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Source Identification', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Cryptographic Hash Tamper Detection', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Verified Checkmark', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Anti-Spoofing Validation', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Differential Hash Tamper Awareness', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Holistic Visual of Content Changes', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'AI-Powered Tamper Classification', inEssential: false, inProfessional: false, inEnterprise: true },
                ]}
            />
            <PricingFeatureBlock
                blockName='Workflow Integration'
                rows={[
                    { featureName: 'Web Application', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Camera Application (iOS/Android)', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Batch Labeling', inEssential: true, inProfessional: true, inEnterprise: true },
                    { featureName: 'Scalable Image Labeling', inEssential: '100/day', inProfessional: 'Unlimited', inEnterprise: 'Unlimited' },
                    { featureName: 'Scalable Video and Audio Labeling', inEssential: false, inProfessional: 'Unlimited', inEnterprise: 'Unlimited' },
                    { featureName: 'Content Consumption Data', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Digital Library Integration (e.g. Photos)', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Professional Product Plugins (e.g. Photoshop)', inEssential: false, inProfessional: false, inEnterprise: true },
                    { featureName: 'High-Performance API Endpoint', inEssential: false, inProfessional: true, inEnterprise: true },
                    { featureName: 'Custom API Endpoint', inEssential: false, inProfessional: false, inEnterprise: true },
                    { featureName: 'Downloadable Software for Local Labeling', inEssential: false, inProfessional: false, inEnterprise: true },
                    { featureName: 'Custom Workflow Integration', inEssential: false, inProfessional: false, inEnterprise: true },
                    { featureName: 'Analyze & Visualize Content Consumption', inEssential: false, inProfessional: false, inEnterprise: true },
                ]}
            />
        </Table>
    );
}
import { ExpressClient, TRUFO_API } from '@/http-clients';
import { ApiDecodingRequest, ApiDecodingResponse, FileType, ImageBase64 } from '@types';
import { createDecodingRequest } from '@pages/main-app/utils';
import { useMemo, useState } from 'react';

interface UseDecodeImageRet {
    decodeImage: (imageFile: File) => void;
    data?: ApiDecodingResponse;
    loading: boolean;
    error?: string;
}

export default function useDecodeImage(): UseDecodeImageRet {

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ApiDecodingResponse>();
    const [error, setError] = useState<string>();

    const decodeImage = useMemo(() => (image: File) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            // Create payload + API call
            const base64 = reader?.result as string | null;
            const requestObj: ApiDecodingRequest = createDecodingRequest(base64 || '')
            setLoading(true);
            ExpressClient.post(TRUFO_API.DECODE, requestObj)
                .then((response) => {
                    // On BE data return, set display
                    if (response?.data && !response?.data?.error) {
                        // parse diff image
                        const diff = response?.data?.contentVerification?.diffFrame;
                        const diffBase64: ImageBase64 | undefined = diff ? {
                            base64: diff,
                            type: FileType.PNG,
                        } : undefined;
                        // set data
                        setData({ ...(response?.data as ApiDecodingResponse), diffImage: diffBase64 });
                        setError(undefined);
                    } else {
                        setError(response?.data?.error || 'no result');
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setData(undefined);
                    setError(err.toString());
                    setLoading(false);
                });
        };
        reader.readAsDataURL(image);
    }, [])

    return { decodeImage, data, loading, error };

}
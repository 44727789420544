import { ExpressClient, TRUFO_API } from '@/http-clients';
import { ApiSourceInfo, ApiUserRequest, ApiUserLogin } from '@types';
import { useState } from 'react';

type UseUpdateUserRet = {
    updateUser: (userSourceInfo: ApiSourceInfo, login: ApiUserLogin, onUpdateSucess: () => void) => boolean,
    data: UpdateUserData,
    loading: boolean,
    error: any,
};

type UpdateUserData = {
    username: string,
} | undefined;

export default function useUpdateUser(): UseUpdateUserRet {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [data, setData] = useState<UpdateUserData>();

    const updateUser = (userSourceInfo: ApiSourceInfo, login: ApiUserLogin, onUpdateSuccess?: () => void) => {

        setLoading(true);

        if (!login) {
            setLoading(false);
            return false;
        };

        const requestObj: ApiUserRequest = {
            authData: login,
            action: 'UPDATE',
            UPDATE: {
                newSourceInfo: userSourceInfo,
            }
        }
        ExpressClient.post(TRUFO_API.USER_ACCOUNT, requestObj)
            .then((response) => {
                const error = response?.data?.error;
                if (!error) {
                    setData(response?.data as UpdateUserData);
                    setError(undefined);
                    onUpdateSuccess && onUpdateSuccess();
                } else {
                    setError(error);
                }
                setLoading(false);
            })
            .catch((err) => {
                setData(undefined);
                setError(err.toString());
                setLoading(false);
            });

        return false;

    };

    return { updateUser, data, loading, error };

}
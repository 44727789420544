import { ApiDecodingResponse, ImageInfo } from "@types";
import { LoadingPage } from "@pages";
import { Alert, Box } from "@mui/material";
import ImageAndContentDisplay from "../image-and-content-display";
import { TrufoButton, ZIndexes } from "@/components";
import { MAIN_APP_ROUTE } from "@/app-routes";

type DecodeProps = {
    uploadedImageFile: File,
    uploadedImageInfo: ImageInfo,
    data?: ApiDecodingResponse;
    loading?: boolean;
    error?: string;
    redirectedFromEncoding?: boolean;
}

export default function Decode({ uploadedImageFile, uploadedImageInfo, data, loading, error, redirectedFromEncoding }: DecodeProps) {

    return loading ? (<LoadingPage variant='dark' />) : (
        <Box
            height='100%' width='100%'
            display='flex' flexDirection='column'
            justifyContent='center' alignItems='center'
        >
            {redirectedFromEncoding &&
                <Box position='absolute' width='100%' top='0px' left='0px' zIndex={ZIndexes.alerts}>
                    <Alert severity="info">Cannot encode: image has already been watermarked</Alert>
                </Box>
            }
            {error &&
                <Box position='absolute' width='100%' top='0px' left='0px' zIndex={ZIndexes.alerts}>
                    <Alert severity="error">Decoding error: please contact support@trufo.ai</Alert>
                </Box>
            }
            <ImageAndContentDisplay
                uploadedImageFile={uploadedImageFile}
                uploadedImageInfo={uploadedImageInfo}
                decodingResponse={data}
                variant='decode'
            />
            <Box
                display='flex' gap='5px' flexDirection='row'
                paddingBottom='30px'
            >
                <TrufoButton
                    href={MAIN_APP_ROUTE}
                    colorVariant='light'
                >
                    Watermark/Verify New Image
                </TrufoButton>
            </Box>
        </Box>
    );
}
import { UiWmSettingsInvisible, UiWmSettingsMetadata, UiWmSettingsVisible, UiEncodingSettings } from '@types';
import { TrufoCheckbox, TrufoToggleButtonGroup } from '@components';
import { Box, ToggleButton } from '@mui/material';

export type EncodeCheckboxToggleProps = {
    variant: 'visible' | 'invisible' | 'metadata',
    encodingSettings: UiEncodingSettings,
    onEncodingSettingsChange: (newVal: UiEncodingSettings) => void,
}

export default function EncodeCheckboxToggle({ variant, encodingSettings, onEncodingSettingsChange }: EncodeCheckboxToggleProps) {

    const displayText = {
        visible: 'Checkered',
        invisible: 'Invisible',
        metadata: 'Metadata',
    }
    const toggleOptions = {
        visible: [
            { val: UiWmSettingsVisible.INSIDE, displayText: 'Inside' },
            { val: UiWmSettingsVisible.OUTSIDE, displayText: 'Outside' },
        ],
        invisible: [
            { val: UiWmSettingsInvisible.LIGHT, displayText: 'Lite' },
            { val: UiWmSettingsInvisible.HEAVY, displayText: 'Normal' },
        ],
        metadata: [
            { val: UiWmSettingsMetadata.REPLACE, displayText: 'Replace' },
            { val: UiWmSettingsMetadata.APPEND, displayText: 'Append' },
        ],
    }

    const obj = encodingSettings[variant];
    const val = obj?.val;
    const selected = obj?.selected;

    return (
        <Box display='flex' alignItems='center' justifyContent='center'>
            <TrufoCheckbox
                checked={selected}
                colorVariant='accentDark'
                onClick={() => onEncodingSettingsChange({ [variant]: { ...obj, selected: !selected } })}
            />
            <Box>{displayText[variant]}</Box>
            <TrufoToggleButtonGroup
                colorVariant='accentDark' exclusive sx={{ marginLeft: '10px' }}
                value={val}
                disabled={!selected}
                onValueChange={(newVal: string) => { if (newVal) onEncodingSettingsChange({ [variant]: { ...obj, val: newVal } }) }}
            >
                {toggleOptions[variant].map(option => <ToggleButton key={option.val} value={option.val}>{option.displayText}</ToggleButton>)}
            </TrufoToggleButtonGroup>
        </Box>
    );
}
import { Colors } from "@components";
import LoadingIcon from "@/components/loading-icon";
import { Box } from "@mui/material";
import MainAppWrapper from "./main-app/main-app-wrapper";

type LoadingPageProps = {
    variant?: 'light' | 'dark',
    withNavbar?: boolean,
}

export default function LoadingPage({ variant, withNavbar }: LoadingPageProps) {
    variant = variant || 'dark';

    const LoadingBody =
        <Box
            width='100%' height='100%'
            display='flex' justifyContent='center' alignItems='center'
            sx={{ backgroundColor: variant === 'dark' ? Colors.dark : Colors.offWhite }}
        >
            <LoadingIcon size='100px' />
        </Box>

    // TODO: support light variant
    return withNavbar ? (
        <MainAppWrapper>
            {LoadingBody}
        </MainAppWrapper>
    ) : LoadingBody;
}
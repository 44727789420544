import { ResponsiveFontSizes } from "@/components";
import { FontWeights } from "@/components/variables";
import { Box, BoxProps, SxProps } from "@mui/material";

export type LpTextVariant = 'headerLarge' | 'header' | 'headerBold' | 'headerLight' | 'subheaderLarge' | 'subheaderLargeBold' | 'subheaderLargeLight' | 'subheader' | 'subheaderBold' | 'subheaderLight' | 'body' | 'bodyLight' | 'label';
export type LpTextSizeVariant = 'headerLarge' | 'header' | 'subheaderLarge' | 'subheader' | 'body' | 'label';
type LpTextProps = {
    variant?: LpTextVariant,
    fontSize?: string | number,
    lineHeight?: number,
    enlarged?: boolean,
    extraEnlarged?: boolean,
} & BoxProps;
export const getFontSize = (variant: LpTextSizeVariant, enlarged?: boolean, extraEnlarged?: boolean) => {
    const fontSizes = {
        headerLarge: extraEnlarged ? ResponsiveFontSizes.header0 : enlarged ? ResponsiveFontSizes.header1 : ResponsiveFontSizes.header2,
        header: extraEnlarged ? ResponsiveFontSizes.header1 : enlarged ? ResponsiveFontSizes.header2 : ResponsiveFontSizes.header3,
        subheaderLarge: extraEnlarged ? ResponsiveFontSizes.header2 : enlarged ? ResponsiveFontSizes.header3 : ResponsiveFontSizes.header4,
        subheader: extraEnlarged ? ResponsiveFontSizes.header4 : enlarged ? ResponsiveFontSizes.header5 : ResponsiveFontSizes.header6,
        body: extraEnlarged ? ResponsiveFontSizes.header5 : enlarged ? ResponsiveFontSizes.header6 : ResponsiveFontSizes.header7,
        label: extraEnlarged ? ResponsiveFontSizes.header6 : enlarged ? ResponsiveFontSizes.header7 : ResponsiveFontSizes.body0,
    }
    return fontSizes[variant];
}
export const LpText = ({ variant = 'body', fontSize, lineHeight, enlarged, extraEnlarged, children, ...muiBoxProps }: React.PropsWithChildren<LpTextProps>) => {
    const DEFAULT_LINE_HEIGHT = 1.1;

    const variantStyles: Record<LpTextVariant, SxProps> = {
        headerLarge: {
            fontSize: fontSize || getFontSize('headerLarge', enlarged, extraEnlarged),
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        header: {
            fontSize: fontSize || getFontSize('header', enlarged, extraEnlarged),
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        headerBold: {
            fontSize: fontSize || getFontSize('header', enlarged, extraEnlarged),
            fontWeight: FontWeights.semibold,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        headerLight: {
            fontSize: fontSize || getFontSize('header', enlarged, extraEnlarged),
            fontWeight: FontWeights.light,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheaderLarge: {
            fontSize: fontSize || getFontSize('subheaderLarge', enlarged, extraEnlarged),
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheaderLargeLight: {
            fontSize: fontSize || getFontSize('subheaderLarge', enlarged, extraEnlarged),
            fontWeight: FontWeights.light,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheaderLargeBold: {
            fontSize: fontSize || getFontSize('subheaderLarge', enlarged, extraEnlarged),
            fontWeight: FontWeights.semibold,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheader: {
            fontSize: fontSize || getFontSize('subheader', enlarged, extraEnlarged),
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheaderBold: {
            fontSize: fontSize || getFontSize('subheader', enlarged, extraEnlarged),
            fontWeight: FontWeights.semibold,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheaderLight: {
            fontSize: fontSize || getFontSize('subheader', enlarged, extraEnlarged),
            fontWeight: FontWeights.light,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        body: {
            fontSize: fontSize || getFontSize('body', enlarged, extraEnlarged),
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        bodyLight: {
            fontSize: fontSize || getFontSize('body', enlarged, extraEnlarged),
            fontWeight: FontWeights.light,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        label: {
            fontSize: fontSize || getFontSize('label', enlarged, extraEnlarged),
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
    }
    return (<Box {...muiBoxProps} sx={{ ...variantStyles[variant], ...muiBoxProps?.sx } as SxProps}>{children}</Box>)
}
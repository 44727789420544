import { Colors, TrufoButton, BoxShadows, FontSizes } from '@components';
import { Alert, Box } from '@mui/material';
import ImageAndContentDisplay from '../image-and-content-display';
import EncodeForm from './encode-form';
import { UiEncodingSettings, ImageInfo, ApiUserLogin } from '@types';
import { ErrorPage } from '@/pages';
import { ReactNode } from 'react';
import { noEncodingMethodSelected } from '../utils';
import { useEncodeImage } from '@/hooks';

type EncodePageProps = {
    imageFile: File,
    imageInfo: ImageInfo,
    encodingSettings: UiEncodingSettings,
    onEncodingSettingsChange: (newEncodingSettings: UiEncodingSettings) => void,
    encodeImage: (imageFile: File, encodingSettings: UiEncodingSettings, imageInfo: ImageInfo, login: ApiUserLogin) => void,
    userLogin: ApiUserLogin,
    settingsModal: ReactNode;
    openSettingsModal: () => void;
    error?: any;
}

const ENCODE_FORM_MIN_WIDTH_PX = 380;
const ENCODE_FORM_MAX_WIDTH_PX = 450;

export default function EncodePage({ imageFile, imageInfo, encodingSettings, onEncodingSettingsChange, encodeImage, userLogin, settingsModal, openSettingsModal, error }: EncodePageProps) {

    const { encodeImage: previewImage, data: previewImageFile, loading: previewImageLoading, error: previewImageError } = useEncodeImage(true);

    return userLogin ? (
        <Box display='flex' width='100%' height='100%'>

            {/* Left Column: Image Preview */}
            <Box
                height='100%' minWidth='260px'
                display='flex' flexGrow={1} gap='1dvh'
                flexDirection='column'
                sx={{ backgroundColor: Colors.white }}
            >
                {error || previewImageError ?
                    (<Box>
                        <Alert severity="error">Encoding error: please contact support@trufo.ai</Alert>
                    </Box>) :
                    (<Box position='absolute' left='10px' top='10px' fontSize={FontSizes.header3} >
                        [ Preview ]
                    </Box>)
                }

                <ImageAndContentDisplay
                    uploadedImageFile={imageFile}
                    uploadedImageInfo={imageInfo}
                    encodePreviewImageFile={previewImageFile}
                    encodeShowPreviewImageFile={true}
                    encodingSettings={encodingSettings}
                    variant='encode'
                />
            </Box>

            {/* Right Column: Watermark Options Form */}
            <Box
                height='100%' minWidth={`${ENCODE_FORM_MIN_WIDTH_PX}px`} maxWidth={`${ENCODE_FORM_MAX_WIDTH_PX}px`}
                display='flex' flexGrow={1}
                flexDirection='column' justifyContent='space-between' alignItems='center'
                sx={{
                    backgroundColor: Colors.light50,
                    boxShadow: BoxShadows.dense1,
                }}
            >
                <EncodeForm
                    encodingSettings={encodingSettings}
                    onEncodingSettingsChange={onEncodingSettingsChange}
                    previewImage={() => previewImage(imageFile, encodingSettings, imageInfo, userLogin)}
                    previewImageFile={previewImageFile}
                    previewImageLoading={previewImageLoading}
                    settingsModal={settingsModal}
                    openSettingsModal={openSettingsModal}
                />
                <TrufoButton
                    colorVariant='accentDark'
                    sx={{ height: '60px', width: '100%', borderRadius: '0px', boxShadow: BoxShadows.dense2 }}
                    onClick={() => encodeImage(imageFile, encodingSettings, imageInfo, userLogin)}
                    disabled={noEncodingMethodSelected(encodingSettings)}
                >
                    Confirm
                </TrufoButton>

            </Box>
        </Box>
    ) : (
        <ErrorPage />
    );
}
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ApiDecodingResponse, UiEncodingSettings, ImageBase64, ImageAspectRatio, ImageInfo } from '@types';
import ImageDisplay from './image-display';
import ContentDisplay from './content-display';
import { setImageAspectRatio } from '@/utils';

export type ImageAndContentDisplayVariant = 'encode' | 'decode';

type PreviewProps = {
    variant: ImageAndContentDisplayVariant,
    uploadedImageFile: File,
    uploadedImageInfo: ImageInfo,
    encodePreviewImageFile?: File,
    encodeShowPreviewImageFile?: boolean,
    encodingSettings?: UiEncodingSettings,
    decodingResponse?: ApiDecodingResponse,
}

export type ImageAlignment = 'vertical' | 'horizontal';

export const MAX_LENGTHS = {
    shorter: '45%',
    longer: '80%',
}

export default function ImageAndContentDisplay({ variant, uploadedImageFile, uploadedImageInfo, encodePreviewImageFile, encodeShowPreviewImageFile, encodingSettings, decodingResponse }: PreviewProps) {

    // Encoding settings
    const isEncodingPreview = encodePreviewImageFile && encodeShowPreviewImageFile;
    // Decoding settings
    const DEFAULT_SHOW_DIFF = true;
    const [decodeShowDiff, setDecodeShowDiff] = useState<boolean>(DEFAULT_SHOW_DIFF);
    const decodeDiffImage = decodingResponse?.diffImage;
    const displayCardApsectRatioTakeDiff = variant === 'decode' && DEFAULT_SHOW_DIFF && decodeDiffImage;
    // Images & aspect ratios
    const [imageToDisplay, setImageToDisplay] = useState<File | ImageBase64>(decodeShowDiff && variant === 'decode' && decodeDiffImage ? decodeDiffImage : uploadedImageFile); // changes to preview image or diff when user clicks buttons
    const [imageToDisplayAspectRatio, setImageToDisplayAspectRatio] = useState<ImageAspectRatio>();
    // Resize observer states & settings
    const [imageAlignment, setImageAlignment] = useState<ImageAlignment>('vertical');
    const [displayCardAspectRatio, setDisplayCardAspectRatio] = useState<ImageAspectRatio>(uploadedImageInfo);
    const imageContainerRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (!imageContainerRef.current) return;
        const resizeObserver = new ResizeObserver((elementArr) => {
            // Whenever the size of the element changes:
            const aspectRatio = displayCardAspectRatio;
            if (aspectRatio) {
                const element = elementArr?.[0]?.borderBoxSize?.[0];
                const height = element?.blockSize;
                const width = element?.inlineSize;
                if ((width / aspectRatio.width) > (height / aspectRatio.height)) {
                    setImageAlignment('horizontal');
                } else {
                    setImageAlignment('vertical');
                }
            }
        });
        resizeObserver.observe(imageContainerRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [displayCardAspectRatio]);

    // for encoding, when "preview" is pressed, show preview image
    useEffect(() => { if (isEncodingPreview) setImageToDisplay(encodePreviewImageFile) }, [isEncodingPreview, encodePreviewImageFile]);

    // for decoding, when "showDiff" is changed, show / hide diff
    useEffect(() => {
        if (decodeShowDiff && decodeDiffImage) setImageToDisplay(decodeDiffImage);
        if (!decodeShowDiff) setImageToDisplay(uploadedImageFile);
    }, [decodeShowDiff, decodeDiffImage, uploadedImageFile])

    // for decoding, if default setting is to show diff + diff exists, then set card aspect ratio diff instead of uploaded image
    useEffect(() => {
        if (displayCardApsectRatioTakeDiff) { setImageAspectRatio(decodeDiffImage, setDisplayCardAspectRatio) }
    }, [decodeDiffImage, displayCardApsectRatioTakeDiff])

    // whenever imageToDisplay changes, change imageToDisplayAspectRatio
    useEffect(() => {
        imageToDisplay && setImageAspectRatio(imageToDisplay, setImageToDisplayAspectRatio);
    }, [imageToDisplay]);

    return (
        <>
            <Box
                display='flex' height='calc(100% - 50px)' width='100%' marginTop='20px' gap='1dvh'
                justifyContent='center' alignItems='center' flex={1} flexGrow={1}
                flexDirection={imageAlignment === 'vertical' ? 'column' : 'row'}
                ref={imageContainerRef}
            >

                <ImageDisplay
                    image={imageToDisplay || uploadedImageFile}
                    imageAspectRatio={imageToDisplayAspectRatio || uploadedImageInfo}
                    imageAlignment={imageAlignment}
                    isPreview={isEncodingPreview}
                />

                <ContentDisplay
                    variant={variant}
                    aspectRatio={displayCardAspectRatio}
                    imageAlignment={imageAlignment}
                    showDiff={decodeShowDiff}
                    onShowDiffChange={setDecodeShowDiff}
                    encodingSettings={encodingSettings}
                    decodingResponse={decodingResponse}
                />

            </Box>
        </>
    );
}
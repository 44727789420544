import { CareersSection, LpText, LpWrapper, SectionWrapper } from "../components"
import { useSectionProps } from "../types-and-constants";
import { Box } from "@mui/material";
import { ResponsiveSpacing, TrufoLink } from "@/components";
import { CAREERS_EMAIL, VISION_LINK } from "@/app-routes";

export interface PeoplePageProps {
}

export const PeoplePage = ({ }: PeoplePageProps) => {

    const sectionProps = useSectionProps();
    return (
        <LpWrapper>
            <SectionWrapper {...sectionProps}>
                <Box alignSelf='start' marginBottom={ResponsiveSpacing.space5}><LpText variant='header'>People</LpText></Box>
                <LpText width='100%' variant='subheaderLight'>
                    <Box width='100%' display='flex' flexDirection='column' gap={ResponsiveSpacing.space3}>
                        <Box>We are a team of engineers and researchers who have gathered over <TrufoLink sx={{ marginTop: '-5px' }} href={VISION_LINK}>a problem and a vision</TrufoLink>.</Box>
                        <Box>Some organizations that our team previously did research or worked at: Princeton, Stanford, UChicago, NYU, Google, Netflix, Meta, Citadel, Jane Street.</Box>
                        <Box >To submit your job applications, please email your resume and cover letter to <TrufoLink sx={{ marginTop: '-5px' }} href={CAREERS_EMAIL}>careers@trufo.ai</TrufoLink>.</Box>
                    </Box>
                </LpText>
            </SectionWrapper>
            <SectionWrapper {...sectionProps} outerBoxProps={{ paddingTop: 0 }}>
                <CareersSection
                    headerTop="Careers"
                    headerBottom="We are hiring for the following roles:"
                    roles={[
                        { jobTitle: 'Research Engineer', team: 'Research Team | NYC', description: 'Experience working with research teams. Experience building out testing tools to evaluate research results in a practical setting, as well as translating research results into product code. Experience with Python. Ability to reason & communicate mathematically.' },
                        { jobTitle: 'Software Engineer, AV Processing', team: 'Watermarking Team | NYC', description: 'Experience with C++, FFmpeg, and optimizing the speed of AV encoding and decoding.' },
                        { jobTitle: 'Software Engineer, Mobile', team: 'Product Team | NYC', description: 'Experience with building and deploying iOS & Android applications. Experience with React Native, TypeScript.' },
                        { jobTitle: 'Software Engineer, API', team: 'Product Team | NYC', description: 'Experience building scalable API products and writing documentation. Experience with Python.' },
                        { jobTitle: 'Full Stack Engineer, Integrations', team: 'Product Team | NYC', description: 'Experience building out both UI and BE integrations with various third party apps. Good design & product thinking.' },
                        // { jobTitle: 'Full Stack Engineer, Core Products', team: 'Product Team | NYC', description: 'Experience building full-stack applications. Experience with React, Typescript, Python. Good design & product thinking.' },
                    ]}
                />
            </SectionWrapper>
        </LpWrapper >
    );
}
import { getUrlFromBase64, useMemoGetUrlFromFile } from "@utils";
import { Box, BoxProps } from "@mui/material"
import { ImageBase64 } from "@types";

type TrufoImageProps = BoxProps & {
    imageFile?: File;
    imageBase64?: ImageBase64;
    maxWidth?: string;
    maxHeight?: string;
    label?: string;
    outerBoxProps?: BoxProps;
    centered?: boolean;
}

export default function TrufoImage({ imageFile, imageBase64, maxWidth, maxHeight, label, outerBoxProps, centered, ...muiProps }: TrufoImageProps) {

    let fileSrc = undefined;
    fileSrc = useMemoGetUrlFromFile(imageFile);
    if (imageBase64) fileSrc = getUrlFromBase64(imageBase64.base64, imageBase64.type);

    return (
        <>
            <Box
                maxWidth={maxWidth || '100%'} maxHeight={maxHeight || '100%'}
                {...(centered && { display: 'flex', justifyContent: 'center', alignItems: 'center' })}
                {...outerBoxProps}
            >
                <Box
                    component='img' src={fileSrc} width='100%' height='100%'
                    {...muiProps}
                />
                {label && <Box textAlign='center'>{label}</Box>}
            </Box>
        </>
    );
}
import { Box } from "@mui/material"
import { DescriptionTextBlock, SectionWrapper } from "./../components"
import { ResponsiveSpacing } from "@/components";
import { DEMO_REQUEST_LINK } from "@/app-routes";
import { EXTRA_COMPACT_VIEW_WIDTH_PX, SectionProps } from "../types-and-constants";
import integrations_illustration from '@assets/illustrations/integrations1.png'

export type SectionIntegrationsProps = SectionProps;

export const SectionIntegrations = (props: SectionIntegrationsProps) => {
    const compact = props?.pageWidthPx < EXTRA_COMPACT_VIEW_WIDTH_PX;
    return (
        <SectionWrapper {...props} flexDirection={compact ? 'column' : 'row'} gap={ResponsiveSpacing.space6}>
            <DescriptionTextBlock
                headerTop="INTEGRATIONS"
                headerBottom="Available within your content management workflow"
                description="Trufo offers a number of workflow integrations that make content labeling easy. Explore the ones we already have or request a custom enterprise solution."
                flex={1}
                buttonName='Schedule a Consultation'
                buttonHref={DEMO_REQUEST_LINK}
                alignSelf='center'
                enlarged={compact}
            />
            <Box flex={1}><Box component='img' src={integrations_illustration} width='100%' /></Box>
        </SectionWrapper>
    )
}
import { Box } from "@mui/material"
import { DescriptionTextBlock, DescriptionTextBlockProps, SectionWrapper, SectionWrapperProps } from "./../components"
import { ResponsiveSpacing } from "@/components";
import { BLOG_C2PA_LINK, BLOG_DIFF_LINK, BLOG_SCALABILITY_LINK, BLOG_WATERMARK_LINK } from "@/app-routes";
import { getAnimSize, SectionProps } from "../types-and-constants";
import secure_illustration from '@assets/illustrations/labels-secure.png'
import recognized_illustration from '@assets/illustrations/labels-recognized.png'
import scalable_illustration from '@assets/illustrations/labels-scalable.png'
import WatermarkAnim from "../watermark-anim";
import { useMotionValue } from "framer-motion";
import image_stmoritz_square from '@assets/image_stmoritz_square2.jpg';

export type SectionProductsProps = SectionProps;

export const SectionProducts = (props: SectionProductsProps) => {
    const { pageWidthPx, extraCompact } = props;
    const animSizePx = getAnimSize(pageWidthPx, 500, false);

    const sectionWrapperProps: SectionWrapperProps = {
        ...props,
        flexDirection: extraCompact ? 'column' : 'row',
        gap: extraCompact ? ResponsiveSpacing.space6 : ResponsiveSpacing.space3
    }
    const descriptionTextBlockProps: DescriptionTextBlockProps = {
        flex: 1,
        alignSelf: 'center',
        enlarged: extraCompact
    }

    return (
        <>
            <SectionWrapper {...sectionWrapperProps} colorVariant='gray'>
                <DescriptionTextBlock
                    headerTop="DURABLE LABELS"
                    headerBottom="Embed invisible watermarks directly into your content"
                    description="Our industry-defining watermarks survive compression, cropping, color filters, and much more. If you are concerned about your content labels getting lost online, this is for you."
                    buttonName='Learn More'
                    buttonHref={BLOG_WATERMARK_LINK}
                    {...descriptionTextBlockProps}
                />
                <WatermarkAnim
                    noAnim
                    animSizePx={animSizePx}
                    scrollYProgress={useMotionValue(0)}
                    initialAnimationComplete={true} setInitialAnimationComplete={() => { }}
                    paddingLeft='10%' paddingRight='10%'
                    imageUrl={image_stmoritz_square}
                />
            </SectionWrapper>
            <SectionWrapper {...sectionWrapperProps} flexDirection={extraCompact ? 'column-reverse' : 'row'}>
                <Box flex={1} marginBottom='-50px' paddingRight={extraCompact ? undefined : ResponsiveSpacing.space5}><Box component='img' src={secure_illustration} width='100%' /></Box>
                <DescriptionTextBlock
                    headerTop="SECURE LABELS"
                    headerBottom="Detect any third-party changes made to your content"
                    description="Traditional cryptography only proves exact matches. When changes are made, our content hashes can show you what and where the changes are without needing the original copy."
                    buttonName='Learn More'
                    buttonHref={BLOG_DIFF_LINK}
                    {...descriptionTextBlockProps}
                />
            </SectionWrapper>
            <SectionWrapper {...sectionWrapperProps} colorVariant='gray'>
                <DescriptionTextBlock
                    flex={1}
                    headerTop="RECOGNIZED LABELS"
                    headerBottom="Ensure that your labels are respected by other parties"
                    description="Our content labels are built on standards recognized by both companies and governments. This interoperability empowers you to certify, protect, monetize, and control your content."
                    buttonName='Learn More'
                    buttonHref={BLOG_C2PA_LINK}
                    {...descriptionTextBlockProps}
                />
                <Box flex={1.2}><Box component='img' src={recognized_illustration} width='100%' /></Box>
            </SectionWrapper>
            <SectionWrapper {...sectionWrapperProps} {...sectionWrapperProps} flexDirection={extraCompact ? 'column-reverse' : 'row'}>
                <Box flex={1} paddingRight={extraCompact ? undefined : ResponsiveSpacing.space5}><Box component='img' src={scalable_illustration} width='100%' /></Box>
                <DescriptionTextBlock
                    headerTop="SCALABLE LABELS"
                    headerBottom="Add content labels without worrying about the future"
                    description="Our patented technology enables affordable watermarking that scales to millions of images, and further promotes the security, longevity, and backwards compatibility of content labels."
                    buttonName='Learn More'
                    buttonHref={BLOG_SCALABILITY_LINK}
                    {...descriptionTextBlockProps}
                />
            </SectionWrapper>
        </>
    )
}
import { SCROLL_DIV_HEIGHT, useSectionProps } from "./types-and-constants";
import { Colors } from "@components";
import { useEffect, useRef, useState } from "react";
import { motion, useScroll } from 'framer-motion';
import { SectionFreeProducts, SectionLanding, SectionProducts, SectionTrust, SectionUseCases, SectionValue, SectionIntegrations } from "./sections";
import { LpWrapper } from "./components";
import { Box } from "@mui/material";

export default function LandingPage() {
    const sectionProps = useSectionProps();
    const [scrollTop, setScrollTop] = useState<number>(0);
    const sectionLandingContainer = useRef<HTMLDivElement>(null);
    const { scrollYProgress: scrollYProgressLanding } = useScroll({ target: sectionLandingContainer, offset: ["start start", "end start"] });
    const [initialAnimationComplete, setInitialAnimationComplete] = useState<boolean>(false);

    // Track scroll position and during initial animation, scroll to top whenever someone scrolls down (hack because can't set overflowY to hidden)
    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollTop(e?.target?.documentElement?.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
    }, [scrollTop]);
    useEffect(() => { if (!initialAnimationComplete && scrollTop !== 0) window.scrollTo(0, 0) }, [scrollTop, initialAnimationComplete])

    return (
        <LpWrapper>
            <motion.div
                style={{
                    height: SCROLL_DIV_HEIGHT, width: '100%',
                    backgroundColor: Colors.dark,
                }}
                ref={sectionLandingContainer}
            >
                <SectionLanding
                    {...sectionProps}
                    scrollYProgress={scrollYProgressLanding}
                    initialAnimationComplete={initialAnimationComplete}
                    onInitialAnimationCompleteChange={setInitialAnimationComplete}
                />
            </motion.div>
            {initialAnimationComplete ?
                <Box width='100%'>
                    <SectionValue {...sectionProps} />
                    <SectionProducts {...sectionProps} />
                    <SectionFreeProducts {...sectionProps} />
                    <SectionIntegrations {...sectionProps} />
                    <SectionTrust {...sectionProps} />
                    <SectionUseCases {...sectionProps} />
                </Box>
                : null
            }

        </LpWrapper >
    )
}
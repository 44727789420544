import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { LpText, LpWrapper, SectionWrapper } from "./landing-page/components";
import { getPageTopOffsetString, useSectionProps } from "./landing-page/types-and-constants";
import { ReactComponent as ErrorIllustration } from '@assets/illustrations/404-dog.svg'
import { ReactComponent as ConstructionIllustration } from '@assets/illustrations/404-cat.svg'
import { ReactNode } from "react";
import { Box } from "@mui/material";
import { Spaces } from "@/components";

export type ErrorPageVariant = 'error' | 'construction';
export interface ErrorPageProps {
    variant?: ErrorPageVariant
}

export default function ErrorPage({ variant = 'error' }: ErrorPageProps) {
    const error = useRouteError();
    // console.error(error);
    let errorMessage: string;

    if (isRouteErrorResponse(error)) {
        errorMessage = error.data?.message || error.statusText;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        errorMessage = 'Unknown error';
    }

    const sectionProps = useSectionProps();

    const variantText: Record<ErrorPageVariant, string> = {
        error: 'Page Not Found',
        construction: 'This page is under maintenance and will be live again soon!'
    }
    const variantIllustration: Record<ErrorPageVariant, ReactNode> = {
        error: <ErrorIllustration width='100%' />,
        construction: <ConstructionIllustration width='100%' />
    }

    const HIDE_BANNER = true;

    return (
        <LpWrapper hideBanner={HIDE_BANNER}>
            <SectionWrapper
                {...sectionProps} gap={Spaces.space0}
                outerBoxProps={{
                    height: `calc(100vh - ${getPageTopOffsetString(true, !HIDE_BANNER)})`,
                    boxSizing: 'border-box',
                    alignItems: 'center', justifyContent: 'center',
                    gap: 0
                }}
            >
                <LpText variant="header">{variantText[variant]}</LpText>
                <Box marginTop='-120px' marginBottom='-120px'>{variantIllustration[variant]}</Box>
            </SectionWrapper>
        </LpWrapper>
    );
}
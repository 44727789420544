import { Box } from "@mui/material"
import { Colors, FontWeights, IconLinkExternal, ResponsiveSpacing } from "@/components";
import { DescriptionTextBlock } from "./description-text-block";
import { LpText } from "./lp-text";
import { CAREERS_EMAIL } from "@/app-routes";

export type CareersSectionLinkRow = {
    jobTitle: string,
    team: string,
    description: string,
}
export type CareersSectionProps = {
    roles: CareersSectionLinkRow[];
    headerTop?: string;
    headerBottom?: string;
}

const SECTION_BACKGROUND_COLOR = Colors.lightestGray;
const TRANSITION_STYLE = 'opacity 0.3s ease-in-out';

export const CareersSection = ({ roles, headerTop, headerBottom }: CareersSectionProps) => {
    return (
        <Box
            borderRadius='8px'
            padding='40px'
            width='100%'
            boxSizing='border-box'
            display='flex' flexDirection='column'
            sx={{ backgroundColor: SECTION_BACKGROUND_COLOR }}
        >
            <DescriptionTextBlock
                sizeVariant='small' headerTop={headerTop} headerBottom={headerBottom}
                marginBottom={ResponsiveSpacing.space5}
            />

            {roles.map(role => (
                <Box
                    position='relative' width='100%'
                    display='flex' alignItems='center'
                    borderTop={`1px solid ${Colors.gray}`} borderBottom={`1px solid ${Colors.gray}`}
                    gap={ResponsiveSpacing.space1}
                    paddingTop={ResponsiveSpacing.space3} paddingBottom={ResponsiveSpacing.space3}
                    marginTop='-1px'
                    sx={{
                        ':hover': {
                            '.tflp-external-link-icon': {
                                opacity: 1
                            }
                        }
                    }}
                >
                    {/* hacky div for the purposes of adding transition animation to on hover style  */}
                    <Box
                        position='absolute' width='100%' height='100%' top='0' left='0'
                        sx={{
                            transition: TRANSITION_STYLE,
                            background: `linear-gradient(to right, transparent, ${Colors.accentLightLp20} 5%, ${Colors.accentLightLp20} 95%, transparent)`,
                            opacity: 0,
                            '&:hover': { opacity: 1, }
                        }}
                    />
                    <Box display='flex' flexDirection='column' alignItems='start' flex={1} gap={ResponsiveSpacing.space2}>
                        {/* actual content below: */}
                        <Box fontFamily='monospace'>{role?.team}</Box>
                        <LpText variant="subheaderLarge">{role?.jobTitle}</LpText>
                        <LpText variant="bodyLight">{role?.description}</LpText>
                    </Box>
                </Box>
            ))}
        </Box>
    )

}
import { Colors, FontWeights, IconDiscord, IconGithub, IconInstagram, IconLinkedin, IconMedium, IconX, IconYoutube, ResponsiveSpacing, Spaces, TrufoLink } from "@/components"
import { Box } from "@mui/material"
import trufo_white from '@assets/trufo_white.png';
import { SectionWrapper } from "./section-wrapper";
import { BLOG_C2PA_LINK, BLOG_DIFF_LINK, BLOG_LINK, BLOG_WATERMARK_LINK, CAREERS_EMAIL, CONTACT_US_EMAIL, CPCPS_ROUTE, DISCORD_LINK, DOCUMENTATION_ROUTE, INSTAGRAM_LINK, PEOPLE_ROUTE, PRESS_EMAIL, PRIVACY_POLICY_ROUTE, RESEARCH_ROUTE, TERMS_OF_SERVICE_ROUTE, VISION_LINK, WHITEPAPER_ROUTE, X_LINK, YOUTUBE_LINK } from "@/app-routes";
import { SectionProps } from "../types-and-constants";

export type LpFooterProps = SectionProps;

export const LpFooter = (props: LpFooterProps) => {
    const compact = props?.pageWidthPx < 800;
    const extraCompact = props?.pageWidthPx < 460;

    const getLinkSection = (header: string, links: Record<string, string>) => (
        <Box display='flex' flexDirection='column' gap='20px' alignItems='start' width='120px'>
            <Box fontWeight={FontWeights.bold}>{header}</Box>
            <Box display='flex' flexDirection='column' gap='2px' alignItems='start'>
                {Object.keys(links).map(linkName => (
                    <TrufoLink
                        href={links[linkName]} trufoVariant="white"
                        noUnderline newtab
                        colorOnHover={Colors.accentLightLp}
                    >
                        {linkName}
                    </TrufoLink>
                ))}
            </Box>
        </Box>
    )

    const iconStyle = {
        clickable: true,
    }

    const linksRow1 = (<>
        {getLinkSection('COMPANY', {
            'About Us': VISION_LINK,
            'Contact Us': CONTACT_US_EMAIL,
            'Careers': CAREERS_EMAIL,
            'People': PEOPLE_ROUTE,
            'Press': PRESS_EMAIL // TODO: update to PRESS_ROUTE
        })}
        {getLinkSection('TECHNOLOGY', {
            'C2PA': BLOG_C2PA_LINK,
            'Watermark': BLOG_WATERMARK_LINK,
            'DiffDetect': BLOG_DIFF_LINK,
        })}
    </>);
    const linksRow2 = (<>
        {getLinkSection('RESOURCES', {
            'Blog': BLOG_LINK,
            'Documentation': DOCUMENTATION_ROUTE,
            'Discord': DISCORD_LINK,
            'Whitepaper': WHITEPAPER_ROUTE,
            'Research': RESEARCH_ROUTE
            //'Publications': PUBLICATIONS_ROUTE
        })}
        {getLinkSection('LEGAL', {
            'Terms of Service': TERMS_OF_SERVICE_ROUTE,
            'Privacy Policy': PRIVACY_POLICY_ROUTE,
            'CP/CPS': CPCPS_ROUTE
        })}
    </>);

    return (
        <SectionWrapper
            {...props} colorVariant="dark"
            justifyContent='space-between'
            flexDirection={!extraCompact ? 'row' : 'column-reverse'}
            gap={!extraCompact ? '0px' : '30px'}
            outerBoxProps={{ borderTop: `1px solid ${Colors.white}` }}
        >
            <Box
                display='flex' flexDirection='column'
                alignItems={!extraCompact ? 'start' : 'center'}
                gap='10px'
            >
                <Box component='img' src={trufo_white} height='50px' alt='trufo' />
                <Box display='flex' alignItems='center' gap='6px' marginTop='-3px'>
                    <IconDiscord {...iconStyle} href={DISCORD_LINK} sx={{ marginRight: '-6px', marginLeft: '-4px' }} />
                    <IconInstagram {...iconStyle} href={INSTAGRAM_LINK} />
                    <IconX {...iconStyle} href={X_LINK} />
                    <IconMedium {...iconStyle} href={BLOG_LINK} sx={{ marginRight: '-4px' }} />
                    {/* <IconLinkedin {...iconStyle} sx={{ marginRight: '-4px' }} />
                    <IconGithub {...iconStyle} sx={{ marginRight: '-2px' }} /> */}
                    <IconYoutube {...iconStyle} href={YOUTUBE_LINK} />
                </Box>
                <Box> © 2024 Trufo Inc.</Box>
            </Box>
            <Box display='flex' gap={Spaces.space1}>
                {!compact ?
                    <>{linksRow1}{linksRow2}</> :
                    <Box display='flex' flexDirection='column' gap={Spaces.space2}>
                        <Box display='flex' gap={Spaces.space1}>{linksRow1}</Box>
                        <Box display='flex' gap={Spaces.space1}>{linksRow2}</Box>
                    </Box>
                }
            </Box>
        </SectionWrapper >
    )
}
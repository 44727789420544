import { ApiWmContentLabel, ApiContentLicense, CONTENT_LABEL_COPY, ApiDecodingResponse, ENCODING_SETTINGS_COPY } from '@types';
import { ContentDetailBox, contentDetailHeaderStyle, contentDetailValueStyle } from './content-display';
import { Box, ToggleButton } from '@mui/material';
import { Colors, Fonts, ZIndexes, ResponsiveFontSizes, ResponsiveSpacing, TrufoToggleButtonGroup } from '@/components';
import { getContentLicenseTags } from '../utils';
import TagDisplay from './tag-display';

type ContentDisplayDecodeProps = {
    decodingResponse: ApiDecodingResponse,
    showDiff: boolean;
    onShowDiffChange: (showDiff: boolean) => void;
}

export default function ContentDisplayDecode({ decodingResponse: dr, showDiff, onShowDiffChange }: ContentDisplayDecodeProps) {

    const ci = dr?.contentIdentification;
    const imageHasDecodingValue = ci && Object.keys(ci).length > 0;
    const cv = dr?.contentVerification;
    const date = ci?.creationTime;
    const dateStr = date ? new Date(date * 1000).toString().split('(') : [];
    const sourceInfo = ci?.sourceInfo;
    const originationClaim = ci?.contentInfo?.contentLabel && ci?.contentInfo?.contentLabel as ApiWmContentLabel;
    const licenseClaim = ci?.contentInfo?.contentLicense && ci?.contentInfo?.contentLicense as ApiContentLicense;
    const contentname = ci?.contentInfo?.displayName;
    const description = ci?.contentInfo?.description;
    const notChanged = Boolean(cv?.imageMatch || cv?.bytesMatch);
    const showNotChangedBox = cv?.imageMatch !== undefined || cv?.bytesMatch !== undefined;
    const diff = dr?.contentVerification?.diffFrame;
    // const diffBase64: ImageBase64 | undefined = useMemo(() => diff ? {
    //     base64: diff,
    //     type: FileType.PNG,
    // } : undefined, [diff]);

    // useEffect(() => {
    //     onImageFileToDisplayChange(showDiff ? (diffBase64 || uploadedImageFile) : uploadedImageFile, DEFAULT_SHOW_DIFF);
    // }, [showDiff, diffBase64, uploadedImageFile, onImageFileToDisplayChange, DEFAULT_SHOW_DIFF])


    return (!imageHasDecodingValue) ? 'No Data Decoded' : (
        <>

            {sourceInfo &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.user}:</Box>
                    <Box {...contentDetailValueStyle}>{sourceInfo?.displayName || 'anonymous user'}</Box>
                    {sourceInfo?.description &&
                        <Box {...contentDetailValueStyle} fontSize={ResponsiveFontSizes.body2}>
                            {sourceInfo?.description}
                        </Box>
                    }
                    {sourceInfo?.sourceLinks &&
                        <Box {...contentDetailValueStyle} fontSize={ResponsiveFontSizes.body2}>
                            {sourceInfo?.sourceLinks}
                        </Box>
                    }
                </ContentDetailBox>
            }

            {date &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.date}:</Box>
                    {dateStr.length > 0 && dateStr[0] && <Box {...contentDetailValueStyle}>{dateStr[0]}</Box>}
                    {dateStr.length > 1 && dateStr[1] && <Box {...contentDetailValueStyle}>{`(${dateStr[1]}`}</Box>}
                </ContentDetailBox >
            }

            {showNotChangedBox && (
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>
                        <Box>Diff Detect:</Box>
                    </Box>
                    <Box fontFamily={Fonts.monospace}>
                        {notChanged ?
                            <Box color={Colors.green}>Content has not changed from original.</Box> :
                            <Box>Content has changed from original.</Box>
                        }
                    </Box>
                    {diff &&
                        <TrufoToggleButtonGroup
                            colorVariant='accentDark' exclusive sx={{ marginLeft: '10px', paddingBottom: '6px', paddingTop: '10px', zIndex: ZIndexes.clickable }}
                            value={String(showDiff)}
                            onValueChange={(newVal: string) => { if (newVal !== null) onShowDiffChange(newVal === 'true' ? true : false) }}
                        >
                            <ToggleButton key='show-diff-false' value='false'>View Image</ToggleButton>
                            <ToggleButton key='show-diff-true' value='true'>View Diff</ToggleButton>
                        </TrufoToggleButtonGroup>}
                </ContentDetailBox>
            )}

            {contentname &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.name}:</Box>
                    <Box {...contentDetailValueStyle}>{contentname}</Box>
                </ContentDetailBox>
            }

            {description &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.description}:</Box>
                    <Box {...contentDetailValueStyle}>{description}</Box>
                </ContentDetailBox>
            }

            {originationClaim &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.contentLabel}:</Box>
                    <Box {...contentDetailValueStyle}>{CONTENT_LABEL_COPY[originationClaim]}</Box>
                </ContentDetailBox>
            }

            {licenseClaim &&
                <ContentDetailBox>
                    <Box {...contentDetailHeaderStyle}>{ENCODING_SETTINGS_COPY.contentLicense}:</Box>
                    <Box {...contentDetailValueStyle}>{licenseClaim}</Box>
                    <Box marginBottom={ResponsiveSpacing.space1} marginTop={ResponsiveSpacing.space1}>
                        <TagDisplay
                            tags={getContentLicenseTags(licenseClaim)}
                            chipProps={{ sx: { fontSize: ResponsiveFontSizes.body2, fontFamily: Fonts.monospace } }}
                        />
                    </Box>
                </ContentDetailBox>
            }

        </>
    );
}
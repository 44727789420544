import { Box, BoxProps } from "@mui/material";

type IframeEmbedderVariants = 'googleSlides' | 'youtube';
type IframeEmbedderProps = {
    src: string,
    variant: IframeEmbedderVariants
    tabIndex?: number,
    aspectRatio?: number,
    containerProps?: BoxProps,
};

const fullScreenIframeStyle = {
    allowFullScreen: true,
    mozallowfullscreen: "true",
    webkitallowfullscreen: "true",
}
const variantStyles: Record<IframeEmbedderVariants, any> = {
    googleSlides: {
        ...fullScreenIframeStyle,
    },
    youtube: {
        ...fullScreenIframeStyle,
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    }
}

export default function IframeEmbedder({ src, variant, tabIndex, aspectRatio, containerProps }: IframeEmbedderProps) {
    return (
        <Box
            {...containerProps}
        >
            <iframe
                tabIndex={tabIndex}
                title={variant}
                src={src}
                width='100%'
                height='auto'
                style={{ aspectRatio: aspectRatio || 17 / 10, border: 0 }}
                {...variantStyles}
            />
        </Box>
    );
}
import { UiEncodingSettings, MAX_LENGTH_CONTENT_DESCRIPTION, MAX_LENGTH_CONTENT_NAME } from '@types';
import { TrufoCheckbox, TrufoTextField } from '@components';
import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';

export type EncodeCheckboxInputProps = {
    variant: 'name' | 'description',
    encodingSettings: UiEncodingSettings,
    onEncodingSettingsChange: (newVal: UiEncodingSettings) => void,
    selected: boolean,
}

export default function EncodeCheckboxInput({ variant, encodingSettings, onEncodingSettingsChange, selected }: EncodeCheckboxInputProps) {

    const displayText = {
        name: 'Name',
        description: 'Description',
    }

    const maxLength = {
        name: MAX_LENGTH_CONTENT_NAME,
        description: MAX_LENGTH_CONTENT_DESCRIPTION,
    }

    const obj = encodingSettings[variant];
    const val = obj?.val;
    const firstClickInitiated = useRef<boolean>(false);

    const textInputRef = useRef<HTMLInputElement>(null);
    // focuses text box when user selects checkbox but prevent focusing on default values loading
    useEffect(() => {
        if (selected && firstClickInitiated.current) {
            textInputRef.current?.focus();
        }
    }, [selected])

    return (
        <Box display='flex' alignItems='flex-start' justifyContent='center' marginTop='9px' width='100%'>
            <TrufoCheckbox
                checked={selected}
                onClick={() => {
                    firstClickInitiated.current = true;
                    onEncodingSettingsChange({ [variant]: { ...obj, selected: !selected } });
                }}
                colorVariant='accentDark' sx={{ marginTop: '-9px' }}
            />
            <Box display='flex' flexDirection='column' alignItems='flex-start' paddingRight='10px' width='100%'>
                <Box>{displayText[variant]}</Box>
                <TrufoTextField
                    colorVariant='accentDark' trufoVariant='filled'
                    inputProps={{ maxLength: maxLength[variant] }}
                    inputRef={textInputRef}
                    disabled={!selected}
                    value={val}
                    onValueChange={(newVal: string) => { onEncodingSettingsChange({ [variant]: { ...obj, val: newVal } }) }}
                />
            </Box>
        </Box>
    );
}
import { Colors, FontSizes, Spaces, TrufoLink } from "@/components";
import { Box, List, ListItem } from "@mui/material";
import { PRIVACY_CONTACT_EMAIL } from "@/app-routes";
import { LpWrapper, SectionWrapper } from "../components";
import { useSectionProps } from "../types-and-constants";

export default function PrivacyPolicy() {
    const sectionProps = useSectionProps();

    const listStyle = {
        listStyleType: 'disc',
        pl: 4, // or 2
        '& .MuiListItem-root': {
            display: 'list-item',
        },
    }

    const EU_PRIVACY_ADEQUACY_LINK = 'https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en';

    return (
        <LpWrapper hideBanner>
            <SectionWrapper colorVariant='dark' {...sectionProps} sx={{ color: Colors.offWhite80, gap: Spaces.space3 }}>
                <Box fontSize={FontSizes.header2}>[ privacy policy ]</Box>
                <Box
                    textAlign='left'
                    display='flex' flexDirection='column' gap='10px'
                >

                    <Box>This Privacy Policy explains the personal data we process, how we process that data, and why we process that data. Personal data is information that identifies or that is capable of identifying a natural person. Processing is any operation that may be performed on personal data and includes collecting, storing, using, and erasing personal data.</Box>
                    <Box>References to Trufo products in this privacy policy include Trufo’s products, services, website, mobile apps, and other software.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Personal Data We Collect</Box>
                    <Box>We collect personal data from you through your registration for and interaction with our products for the purposes described below. These purposes include providing our products, analyzing your use of our products, improving our products, and developing new products.</Box>
                    <Box>The personal data we collect from you may include:</Box>
                    <List sx={listStyle}>
                        <ListItem>Name and contact information. Personal data you provide us when registering for our products, including first and last name, email address, postal address, phone number, and similar information.</ListItem>
                        <ListItem>Credentials. Usernames and passwords you create to access and use our products.</ListItem>
                        <ListItem>Demographic data. Your country of residence.</ListItem>
                        <ListItem>Device and usage data. Personal data about your use of our products, including the activities you perform on your account, the Trufo webpages you visit, and the devices from which you access our products, including IP address and device identifier. This data may be obtained from your use of our software as integrated in third-party products.</ListItem>
                        <ListItem>Content. Content you provide to us through your use of our products. For example, images you upload to Trufo.</ListItem>
                        <ListItem>Location data. Personal data about your device’s location, based on GPS or your device’s IP address.</ListItem>
                        <ListItem>Other data you provide. Other personal data you provide us through use of our products, including messages, survey responses, and other feedback.</ListItem>
                    </List>
                    <Box>We may obtain personal data about you from third parties. When we do so, we process that data consistent with this policy and subject to any additional restrictions placed on that processing by the third party providing us that data.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Children's Privacy</Box>
                    <Box>Our Policies and Terms do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we can take the necessary action(s).</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Our Retention of Personal Data</Box>
                    <Box>We retain your personal data as necessary to provide the products you have requested and to perform the contracts you have entered with us, to fulfill our legitimate interests, including to exercise our legal rights and to perform our legal obligations. Our need to retain personal data varies based on the type of data and the reasons we collected it, so our retention periods vary as well.</Box>
                    <Box>We use the following criteria to determine how long to retain personal data:</Box>
                    <List sx={listStyle}>
                        <ListItem>Customer expectations. We retain personal data consistent with your expectations concerning the performance of the products we provide you.</ListItem>
                        <ListItem>Legitimate interests. We retain personal data to fulfill our legitimate interests.</ListItem>
                        <ListItem>Legal obligations. We retain personal data as required by data retention laws, to comply with government orders, and as necessary to meet preservation requirements in litigation. We also remove personal data if required to do so by law.</ListItem>
                    </List>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Data Security</Box>
                    <Box>We use data security technology and reasonable data security procedures to protect your personal data from unauthorized access, use, disclosure, and alteration and against accidental loss, destruction or damage. These measures include storing your personal data on access-controlled computer systems and in secure storage facilities. We comply with applicable data protection laws, including applicable security breach notification laws.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Cookies</Box>
                    <Box>We use cookies to store your settings, to enable you to sign in to use our products, to identify you as a returning user, to analyze how our products perform, and to fulfill our other legitimate interests. Enabling cookies is not strictly necessary for the website to work but it will provide you with a better browsing experience. The cookie-related information is not used to identify you personally and is not used for any purpose other than those described here.</Box>
                    <Box>You may control the data collected by cookies using controls in your Internet browser to limit how the websites you visit are able to use cookies and to withdraw your consent by clearing or blocking cookies.  However, if you do this, you may have to manually adjust some preferences every time you visit a site, and some services and functionalities may not work. </Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Personal Data Use</Box>
                    <Box>We use your personal data to provide products, operate our business, comply with our legal and contractual obligations, protect our systems and customers, and fulfill our other legitimate interests.</Box>
                    <List sx={listStyle}>
                        <ListItem>Provide our products. We use your personal data to operate our products and services.</ListItem>
                        <ListItem>Improve our products and develop new products. We use your personal data to monitor and improve performance of our products and to develop new products. This may include our use of error report information to diagnose and correct problems with our products.</ListItem>
                        <ListItem>Provide customer support. We use your personal data to diagnose and correct problems with our products, including by responding to support requests and providing support services, confirmations, technical notices, updates, security alerts, and other information.</ListItem>
                        <ListItem>Provide promotional communications. We use your personal data to provide promotional communications. You can unsubscribe from these communications at any time by clicking the “unsubscribe” link in the footer of those communications.</ListItem>
                        <ListItem>Negotiate and perform contracts. We use your personal data to negotiate contracts with you and to perform contracts we have entered with you.</ListItem>
                        <ListItem>Transact commerce. We use your personal data to process commercial transactions you undertake with us.</ListItem>
                        <ListItem>Analyze business operations. We use personal data to analyze business operations and to engage in other business intelligence activities.</ListItem>
                        <ListItem>Comply with the law. We use your personal data to comply with our legal obligations.</ListItem>
                    </List>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Personal Data Sharing</Box>
                    <Box>All data we collect is stored in a secure manner and shared with third parties only when this is necessary to enable the functionality of our product offerings.</Box>
                    <Box>We may share your personal data with our third-party service providers. We may also share your personal data with third parties to exercise our legal rights and perform our legal obligations, and in other circumstances where you have granted us permission to share your personal data.</Box>
                    <List sx={listStyle}>
                        <ListItem>Third party service providers. We may share your personal data with third party services providers who process data on our behalf in ways that are consistent with this Privacy Policy.</ListItem>
                        <ListItem>Third parties for legal purposes. We may share your personal data as required by law or as necessary to exercise our legal rights. For example, we may share your personal data as required by law enforcement or in connection with legal proceedings, and transfer your data in connection with a sale, merger, transfer, or reorganization of our business or business assets.</ListItem>
                        <ListItem>Permission. We may share your personal data in other circumstances where you have given us permission to do so.</ListItem>
                    </List>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Where We Process Personal Data</Box>
                    <Box>We may process your personal data in the United States and in any other country where we or our service providers maintain facilities. We choose data processing locations to optimize delivery of our products, to create redundancies to protect data, and to continue to provide our products in the event of an outage. Our service providers maintain facilities throughout the world.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Where We Process Personal Data</Box>
                    <Box>We may update this Privacy Policy from time to time, including as necessary to comply with legal requirements and to describe changes to our data processing policies. When we post changes to this Privacy Policy, we will revise the “last updated” legend at the top of the Privacy Policy to indicate that changes have been made. We encourage you to review this privacy policy periodically.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Contact Us</Box>
                    <Box>If you have any questions or concerns about this Privacy Policy, please contact us at <TrufoLink href={PRIVACY_CONTACT_EMAIL} trufoVariant='white'>privacy@trufo.ai</TrufoLink>.</Box>
                    <Box height='60px' />

                    <Box fontWeight='bold' fontSize={FontSizes.header3}>[ Special Notice for European Economic Area, United Kingdom, and Switzerland Residents ]</Box>
                    <Box>Residents of countries in the European Economic Area, United Kingdom, and Switzerland have additional rights with respect to personal data. The following applies to residents of those countries with respect to whom we process personal data:</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Purposes and Legal Bases for Data Processing</Box>
                    <Box>The purposes for our processing of your personal data are described above.</Box>
                    <Box>The legal bases for our processing of your personal data are:</Box>
                    <List sx={listStyle}>
                        <ListItem>With your consent. We process your personal data when you have provided us your consent to do so, consistent with the scope of that consent. Where you have provided us consent to process your personal data, you may withdraw that consent at any time.</ListItem>
                        <ListItem>To fulfill a legitimate interest. We process your personal data to fulfill our legitimate interests.</ListItem>
                        <ListItem>To enter or perform a contract. We process your personal data as necessary to enter into and to perform contracts we have entered into you.</ListItem>
                        <ListItem>To comply with legal requirements. We process personal data as required by law.</ListItem>
                    </List>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Where We Process Personal Data</Box>
                    <Box>We may process your personal data in the United States and in any other country where we or our service providers maintain facilities. We choose data processing locations to optimize delivery of our products, to create redundancies to protect data, and to continue to provide our products in the event of an outage. Our service providers maintain facilities throughout the world.</Box>
                    <Box>We transfer personal data from the European Economic Area and Switzerland to other countries, some of which have been determined by the European Commission not to have an adequate level of data protection. When we do so, we use contracts to ensure your rights and protections travel with your data. To learn more about the European Commission's decisions on the adequacy of the protection of personal data in non-European Union countries, please visit: <TrufoLink href={EU_PRIVACY_ADEQUACY_LINK} trufoVariant='white'>{EU_PRIVACY_ADEQUACY_LINK}</TrufoLink>.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>Rights</Box>
                    <Box>Residents of countries in the European Economic Area, United Kingdom, and Switzerland have the following additional rights with respect to their personal data:</Box>
                    <List sx={listStyle}>
                        <ListItem>Right to information. You have the right to be informed of what personal data about you we are processing and the rationale for that processing.</ListItem>
                        <ListItem>Right of access. You have the right to request access to your personal data that we are processing.</ListItem>
                        <ListItem>Right of rectification. You have the right to request that we correct your personal data that we are processing if you believe that data is not up to date or is not accurate.</ListItem>
                        <ListItem>Right to withdraw consent. You have the right to withdraw your consent to our future processing of your personal data where our processing is based on your consent.</ListItem>
                        <ListItem>Right to object. You have the right to object to our processing of your personal data.</ListItem>
                        <ListItem>Right of erasure. You have the right to request that your personal data be deleted.</ListItem>
                        <ListItem>Right to restrict. You have the right to request that we restrict our processing of your personal data.</ListItem>
                        <ListItem>Right to data portability. You have the right to obtain a copy of your personal data in a structured and commonly used, machine-readable format where our automated processing of your personal data is based on your consent or based on a contract entered between us.</ListItem>
                    </List>
                    <Box>To exercise these rights, or to ask any questions or raise any concerns you may have about this privacy policy, please contact us at <TrufoLink href={PRIVACY_CONTACT_EMAIL} trufoVariant='white'>privacy@trufo.ai</TrufoLink>. We will respond to your request within 30 days. We have the right to request that you verify your identity if you request to exercise these rights. You have the right to lodge a complaint with a supervisory authority. We encourage you to contact us before lodging a complaint so that we can address any concerns you may have directly with you.</Box>
                    <Box>For residents of France, you have the right to send us specific instructions regarding the use of your data after your death. Please contact us at the email address above to provide instructions.</Box>
                    <Box>If you have any questions about our Privacy Policy, please contact us by email at <TrufoLink href={PRIVACY_CONTACT_EMAIL} trufoVariant='white'>privacy@trufo.ai</TrufoLink> or by mail at: 228 Park Ave S PMB 87518, New York, NY 10003-1502 US.</Box>
                    <Box height='20px' />

                </Box>
            </SectionWrapper>
        </LpWrapper>
    );
}